import './css/expreince.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper';

import expreinceImg from './images/expreince.png';
import axios from '../config/index';
import { useEffect, useState } from 'react';

function Expreince(){


    const slides = [
        {
            content: '2013',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },
        {
            content: '2014',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },
        {
            content: '2015',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },
        {
            content: '2016',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },
        {
            content: '2017',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },
        {
            content: '2018',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },

        {
            content: '2019',
            header: 'تم إفتتاح فرع في ..',
            para: 'لوريم ايبسوم بالعربي عربي انجليزي لوريم ايبسوم هو نموذج افتراضي',
        },


    ];
    const middleSlideIndex = Math.floor(slides.length / 2);




    
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "expreince_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData2 = (itemId) => {
        const table = "expreince";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {
        getItemData1(1);
        getItemData2(2);
    }, []);

    return(
        <>
            <section className='expreince'>
                <img src={expreinceImg} alt='expreince back' />
                <div className='container'>
                    <div className='header'>
                        <h1 className='section-headline'> 
                        {itemData1.header}
                        </h1>
                        <p className='section-text'>
                        {itemData1.para}
                        </p>
                    </div>


                    <div className='slider'>
                        <div className='expreince-slider-btns'>
                            <button className='prev-btn'>
                                <i className='las la-angle-left'></i>
                            </button>
                            <button className='next-btn'>
                                <i className='las la-angle-right'></i>
                            </button>
                        </div>

                        <Swiper
                        initialSlide={middleSlideIndex}
                        grabCursor={true}
                        spaceBetween={50}
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        navigation={{
                            nextEl: '.expreince .next-btn',
                            prevEl: '.expreince .prev-btn',
                        }}
                        pagination={false}
                        modules={[Navigation, Pagination]}
                    >
                        {itemData2.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className='exp'>
                                    <div className='circle'>
                                        <h1> {slide.year} </h1>
                                    </div>
                                    <div className='info'>
                                        <h2>{slide.header}</h2>
                                        <h3>{slide.para}</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Expreince;