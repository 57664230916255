import './App.css';

import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef,useEffect } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import AOS from 'aos';
import 'aos/dist/aos.css';
import TempPopUp from './components/TempPopUp.js';

import Navbar from      './components/Navbar.js';
import Landing from     './components/Landing.js';
import About from       './components/About.js';
import MostServices from './components/MostServices.js';
import Team from     './components/Team';
import Numbers from     './components/Numbers.js';
import CoursesExplain from './components/CoursesExplain.js';
import Plans from       './components/Plans.js';
import Rates from       './components/Rates.js';
import ContactAd from   './components/ContactAd.js';
import Footer from      './components/Footer.js';
import SectionHeader from './components/SectionHeader.js';
import AboutPageText from './components/AboutPageText.js';
import TeamMembers from './components/TeamMembers.js';
import OurMessage from './components/OurMessage.js';
import Vesion from './components/Vesion.js';
import Expreince from './components/Expreince.js';
import OurWork from './components/OurWork.js';

import OurServicesPageText from './components/OurServicesPageText.js';
import ServicesSteps from './components/ServicesSteps.js';
import Services from './components/Services.js';
import Faqs from './components/Faqs.js';

import AppliedCourses from './components/AppliedCourses.js';
import HowToUseText from './components/HowToUseText.js';
import CoursesCategories from './components/CoursesCategories.js';
import AllCourses from './components/AllCourses.js';
import ExpLesson from './components/ExpLesson.js';


import PricesText from './components/PricesText.js';
import Prices from './components/Prices.js';
import PricesCalc from './components/PricesCalc.js';
import FingerPrintPrices from './components/FingerPrintPrices.js';


import FingerPrintDevText from './components/FingerPrintDevText.js';
import DevicesBenefits from './components/DevicesBenefits.js';

import AvailableDevices from './components/AvailableDevices.js';
import DevicesBenefitsText from './components/DevicesBenefitsText.js';

import SmsServicesText from './components/SmsServicesText.js';
import WhoGetsOurBenefits from './components/WhoGetsOurBenefits.js';
import SystemSettingsandBenefits from './components/SystemSettingsandBenefits.js';
import ApiFiles from './components/ApiFiles.js';
import FaqsPageHeader from './components/images/pageheader/faqs.png';
import OurClients from './components/OurClients.js';
import Career from './components/Career.js';
import ContactUs from './components/ContactUs.js';
import FaqsPage from './components/FaqsPage.js';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import TermsConditions from './components/TermsConditions.js';
import NotFound from './components/NotFound.js';
import ComingSoon from './components/ComingSoon.js';
import PasswordReset from './components/PasswordReset.js';
import Login from './components/Login.js';
import Signup from './components/Signup.js';
import PasswordResetEmail from './components/PasswordResetEmail.js';
import PasswordResetCode from './components/PasswordResetCode.js';
import Popup from './components/Popup.js';
import BanksPopup from './components/BanksPopup.js';
import PayWaysPopup from './components/PayWaysPopup.js';
import PricesInvoicePopup from './components/PriceInvoicePopup.js';



// en

import TempPopUpEN from './components/en/TempPopUp.js';

import NavbarEN from      './components/en/Navbar.js';
import LandingEN from     './components/en/Landing.js';
import AboutEN from       './components/en/About.js';
import MostServicesEN from './components/en/MostServices.js';
import TeamEN from     './components/en/Team';
import NumbersEN from     './components/en/Numbers.js';
import CoursesExplainEN from './components/en/CoursesExplain.js';
import PlansEN from       './components/en/Plans.js';
import RatesEN from       './components/en/Rates.js';
import ContactAdEN from   './components/en/ContactAd.js';
import FooterEN from      './components/en/Footer.js';
import SectionHeaderEN from './components/en/SectionHeader.js';
import AboutPageTextEN from './components/en/AboutPageText.js';
import TeamMembersEN from './components/en/TeamMembers.js';
import OurMessageEN from './components/en/OurMessage.js';
import VesionEN from './components/en/Vesion.js';
import ExpreinceEN from './components/en/Expreince.js';
import OurWorkEN from './components/en/OurWork.js';

import OurServicesPageTextEN from './components/en/OurServicesPageText.js';
import ServicesStepsEN from './components/en/ServicesSteps.js';
import ServicesEN from './components/en/Services.js';
import FaqsEN from './components/en/Faqs.js';

import AppliedCoursesEN from './components/en/AppliedCourses.js';
import HowToUseTextEN from './components/en/HowToUseText.js';
import CoursesCategoriesEN from './components/en/CoursesCategories.js';
import AllCoursesEN from './components/en/AllCourses.js';
import ExpLessonEN from './components/en/ExpLesson.js';


import PricesTextEN from './components/en/PricesText.js';
import PricesEN from './components/en/Prices.js';
import PricesCalcEN from './components/en/PricesCalc.js';
import FingerPrintPricesEN from './components/en/FingerPrintPrices.js';


import FingerPrintDevTextEN from './components/en/FingerPrintDevText.js';
import DevicesBenefitsEN from './components/en/DevicesBenefits.js';

import AvailableDevicesEN from './components/en/AvailableDevices.js';
import DevicesBenefitsTextEN from './components/en/DevicesBenefitsText.js';

import SmsServicesTextEN from './components/en/SmsServicesText.js';
import WhoGetsOurBenefitsEN from './components/en/WhoGetsOurBenefits.js';
import SystemSettingsandBenefitsEN from './components/en/SystemSettingsandBenefits.js';
import ApiFilesEN from './components/en/ApiFiles.js';
import FaqsPageHeaderEN from './components/images/pageheader/faqs.png';
import OurClientsEN from './components/en/OurClients.js';
import CareerEN from './components/en/Career.js';
import ContactUsEN from './components/en/ContactUs.js';
import FaqsPageEN from './components/en/FaqsPage.js';
import PrivacyPolicyEN from './components/en/PrivacyPolicy.js';
import TermsConditionsEN from './components/en/TermsConditions.js';
import NotFoundEN from './components/en/NotFound.js';
import ComingSoonEN from './components/en/ComingSoon.js';
import PasswordResetEN from './components/en/PasswordReset.js';
import LoginEN from './components/en/Login.js';
import SignupEN from './components/en/Signup.js';
import PasswordResetEmailEN from './components/en/PasswordResetEmail.js';
import PasswordResetCodeEN from './components/en/PasswordResetCode.js';
import PopupEN from './components/en/Popup.js';
import BanksPopupEN from './components/en/BanksPopup.js';
import PayWaysPopupEN from './components/en/PayWaysPopup.js';
import PricesInvoicePopupEN from './components/en/PriceInvoicePopup.js';



// images



import AboutImg from './components/images/pageheader/about.png';
import OurServicesImg from './components/images/pageheader/ourservices.gif';
import HowToUseImg from './components/images/pageheader/howtouse.png';
import PriesImg from './components/images/pageheader/pricespageheader.gif';
import FingerPrintDevImg from './components/images/pageheader/fingerprintdev.png';
import OurClientsPageHeaderImg from './components/images/pageheader/ourclientsimg.png';
import CareersPageHeaderImg from './components/images/pageheader/careers.png';

import ContactUsPageHeader from './components/images/pageheader/contactuspageheader.png';






// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";


import AllSocialPage from "./components/Admin/AllSocialPage";
import EditSocial from "./components/Admin/EditSocial";
import ManageLanding from './components/Admin/ManageLanding.js';
import ManageAbout from './components/Admin/ManageAbout.js';
import ManageServices from './components/Admin/ManageServices.js';

import ManageNumbers from './components/Admin/ManageNumbers.js';
import ManageCoursesExplain from './components/Admin/ManageCoursesExplain.js';
import ManagePricesSms from './components/Admin/ManagePricesSms.js';

import ManagePricesDevices from './components/Admin/ManagePricesDevices.js';

import ManageRates from './components/Admin/ManageRates.js';


import ManageAdContact from './components/Admin/ManageAdContact.js';

import ManageAboutSectionHeader from './components/Admin/ManageAboutSectionHeader.js';

import ManageAboutText from './components/Admin/ManageAboutText.js';

import ManageExprienceHeader from './components/Admin/ManageExprienceHeader.js';

import ManageExprience from './components/Admin/ManageExprience.js';

import ManageTeam from './components/Admin/ManageTeam.js';

import ManageClients from './components/Admin/ManageClients.js';


import ManageServicesSectionHeader from './components/Admin/ManageServicesSectionHeader.js';

import ManageServicesText from './components/Admin/ManageServicesText.js';

import ManageServicesPage from './components/Admin/ManageServicesPage.js';

import ManageFaqs from './components/Admin/ManageFaqs.js';

import ManageHowToUseSectionHeader from './components/Admin/ManageHowToUseSectionHeader.js';
import ManageHowToUseText from './components/Admin/ManageHowToUseText.js';

import ManageExplainCategories from './components/Admin/ManageExplainCategories.js';

import ManageAllCoursesSectionHeader from './components/Admin/ManageAllCoursesSectionHeader.js';

import ManageAllCourses from './components/Admin/ManageAllCourses.js';

import ManageSmsServicesSectionHeader from './components/Admin/ManageSmsServicesSectionHeader.js';

import ManageSmsServicesText from './components/Admin/ManageSmsServicesText.js';


import ManageWhoBenefits from './components/Admin/ManageWhoBenefits.js';

import ManageSmsSettings from './components/Admin/ManageSmsSettings.js';


import ManageApiFiles from './components/Admin/ManageApiFiles.js';

import ManageWebsite from './components/Admin/ManageWebsite.js';

import ManageFingerPrintSectionHeader from './components/Admin/ManageFingerPrintSectionHeader.js';


import ManageFingerPrintText from './components/Admin/ManageFingerPrintText.js';

import ManageFingerPrintDevices from './components/Admin/ManageFingerPrintDevices.js';

import ManageFingerPrintBenefits from './components/Admin/ManageFingerPrintBenefits.js';

import ManagePricesSectionHeader from './components/Admin/ManagePricesSectionHeader.js';


import ManagePricesText from './components/Admin/ManagePricesText.js';

import ManagePricesPackages from './components/Admin/ManagePricesPackages.js';


import ManageCareersSectionHeader from './components/Admin/ManageCareersSectionHeader.js';

import ManageFaqsPageCategories from './components/Admin/ManageFaqsPageCategories.js';


import ManageQuestions from './components/Admin/ManageQuestions.js';

import ManagePrivacy from './components/Admin/ManagePrivacy.js';

import ManageTerms from './components/Admin/ManageTerms.js';

























import axios from './config/index';
import Loader from './components/Loader.js';


function App() {


        
    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"|| 

    location.pathname === "/all-subscribers"||

    location.pathname === "/all-landing"||
    location.pathname === "/edit-landing"||
    
    location.pathname === "/all-social"||
    location.pathname === "/edit-social"||

    location.pathname === "/all-about"||
    location.pathname === "/edit-about"||

    location.pathname === "/all-services"||
    location.pathname === "/add-services"||
    location.pathname === "/edit-services"||

    
    location.pathname === "/all-numbers"||
    location.pathname === "/edit-numbers"||


    location.pathname === "/all-courses-explain"||
    location.pathname === "/edit-courses-explain"||

    location.pathname === "/all-prices-sms"||
    location.pathname === "/edit-prices-sms"||

    location.pathname === "/all-prices-devices"||
    location.pathname === "/edit-prices-devices"||


    location.pathname === "/all-rates"||
    location.pathname === "/add-rates"||
    location.pathname === "/edit-rates"||


    location.pathname === "/all-ad-contact"||
    location.pathname === "/edit-ad-contact"||

    location.pathname === "/all-about-section-header"||
    location.pathname === "/edit-about-section-header"||

    location.pathname === "/all-about-text"||
    location.pathname === "/edit-about-text"||

    location.pathname === "/all-expreince-header"||
    location.pathname === "/edit-expreince-header"||


    location.pathname === "/all-expreince"||
    location.pathname === "/add-expreince"||
    location.pathname === "/edit-expreince"||


    
    location.pathname === "/all-team"||
    location.pathname === "/add-team"||
    location.pathname === "/edit-team"||



    location.pathname === "/all-clients"||
    location.pathname === "/add-clients"||
    location.pathname === "/edit-clients"||



    location.pathname === "/all-services-section-header"||
    location.pathname === "/edit-services-section-header"||


    location.pathname === "/all-services-text"||
    location.pathname === "/edit-services-text"||

    location.pathname === "/all-services-page"||
    location.pathname === "/edit-services-page"||


    location.pathname === "/all-faqs"||
    location.pathname === "/add-faqs"||
    location.pathname === "/edit-faqs"||


    
    location.pathname === "/all-how-to-use-section-header"||
    location.pathname === "/edit-how-to-use-section-header"||

    
    location.pathname === "/all-how-to-use-text"||
    location.pathname === "/edit-how-to-use-text"||


    

    location.pathname === "/all-explain-category"||
    location.pathname === "/add-explain-category"||
    location.pathname === "/edit-explain-category"||



    
    location.pathname === "/all-all-courses-section-header"||
    location.pathname === "/edit-all-courses-section-header"||



    

    location.pathname === "/all-all-courses"||
    location.pathname === "/add-all-courses"||
    location.pathname === "/edit-all-courses"||

    
    

    location.pathname === "/all-sms-services-section-header"||
    location.pathname === "/edit-sms-services-section-header"||


    location.pathname === "/all-sms-services-text"||
    location.pathname === "/edit-sms-services-text"||

    location.pathname === "/all-who-benefits"||
    location.pathname === "/edit-who-benefits"||

    
    location.pathname === "/all-sms-settings"||
    location.pathname === "/edit-sms-settings"||
    location.pathname === "/add-sms-settings"||

        

    location.pathname === "/all-api-files"||
    location.pathname === "/add-api-files"||
    location.pathname === "/edit-api-files"||

    
    location.pathname === "/all-website"||
    location.pathname === "/edit-website"||
    

    location.pathname === "/all-finger-print-section-header"||
    location.pathname === "/edit-finger-print-section-header"||



    
    location.pathname === "/all-finger-print-text"||
    location.pathname === "/edit-finger-print-text"||


    location.pathname === "/all-finger-print-devices"||
    location.pathname === "/edit-finger-print-devices"||
    location.pathname === "/add-finger-print-devices"||


    location.pathname === "/all-finger-print-benefits"||
    location.pathname === "/add-finger-print-benefits"||
    location.pathname === "/edit-finger-print-benefits"||

    location.pathname === "/all-prices-section-header"||
    location.pathname === "/edit-prices-section-header"||

    

    location.pathname === "/all-prices-text"||
    location.pathname === "/edit-prices-text"||


    location.pathname === "/all-prices-packages"||
    location.pathname === "/edit-prices-packages"||


    location.pathname === "/all-careers-section-header"||
    location.pathname === "/edit-careers-section-header"||



    location.pathname === "/all-faqs-page-categories"||
    location.pathname === "/add-faqs-page-categories"||
    location.pathname === "/edit-faqs-page-categories"||

    location.pathname === "/all-questions"||
    location.pathname === "/add-questions"||
    location.pathname === "/edit-questions"||


        

    location.pathname === "/all-privacy"||
    location.pathname === "/edit-privacy"||



        

    location.pathname === "/all-terms"||
    location.pathname === "/edit-terms"||


    location.pathname === "/edit-social";



    
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);
    const [itemData3, setitemData3] = useState([]);

    const [itemData4, setitemData4] = useState([]);
    const [itemData5, setitemData5] = useState([]);
    const [itemData6, setitemData6] = useState([]);


    
    const [itemData7, setitemData7] = useState([]);
    const [itemData8, setitemData8] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "about_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };
    const getItemData2 = (itemId) => {
        const table = "services_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData3 = (itemId) => {
        const table = "how_to_use_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData3(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData4 = (itemId) => {
        const table = "all_courses_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData4(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData5 = (itemId) => {
        const table = "sms_services_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData5(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData6 = (itemId) => {
        const table = "finger_print_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData6(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData7 = (itemId) => {
        const table = "prices_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData7(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData8 = (itemId) => {
        const table = "careers_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData8(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);
    useEffect(() => {
        getItemData2(1);
    }, []);
    useEffect(() => {
        getItemData3(1);
    }, []);
    useEffect(() => {
        getItemData4(1);
    }, []);
    useEffect(() => {
        getItemData5(1);
    }, []);
    useEffect(() => {
        getItemData6(1);
    }, []);
    useEffect(() => {
        getItemData7(1);
    }, []);
    useEffect(() => {
        getItemData8(1);
    }, []);

return (
    <div className="App">

            <Routes>

            
            <Route path="/" element={
                    <>
                        <Loader />
                        <TempPopUp />
                        
                        <Navbar />
                        <Landing />
                        <About />
                        <MostServices />
                        <Numbers />

                        <CoursesExplain />
                        <Plans />
                        <Rates />

                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />


            
            <Route path="/about-us" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={AboutImg} link1="about-us" link1text="ماذا عنا" header={itemData1.header} para={itemData1.para}  />
                        <AboutPageText />
                        <Numbers />
                        <OurMessage />
                        <Vesion />
                        <Expreince />
                        <TeamMembers />
                        <OurWork />
                        
                        <ContactAd />
                        <ContactUs />




                        <Footer />


                    </>
                } />


                <Route path="/our-services" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader className="no-image" img={OurServicesImg} link1="our-services" link1text="خدماتنا" header={itemData2.header} para={itemData2.para}  />
                        <OurServicesPageText />
                        <ServicesSteps />
                        <Services />
                        <Faqs />
                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />


                

            <Route path="/how-to-use" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={HowToUseImg} link1="how-to-use" link1text="كيفية الاستخدام" header={itemData3.header} para={itemData3.para}  />
                        
                        <AppliedCourses />
                        <HowToUseText />
                        <CoursesCategories />

                        <Faqs />
                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />


                
            <Route path="/all-courses" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader   link1="how-to-use"  link1text="كيفية الاستخدام" link2="all-courses" link2text="كل الشروحات"  header={itemData4.header} para={itemData4.para}  />
                        
                        <AllCourses />


                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />


                <Route path="/explain-lessons" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader   link1="how-to-use"  link1text="كيفية الاستخدام" link2="explain-lessons" link2text="استخدام اجهزة البصمة"  header=" مشاهدة الشرح " 
                        para=" "  />
                        
                        <ExpLesson />


                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />



                <Route path="/prices-and-banks" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <PricesInvoicePopup />
                        <PayWaysPopup />
                        <BanksPopup />
                        <Navbar />
                        <SectionHeader img={PriesImg}  link1="how-to-use"  link1text="كيفية الاستخدام" link2="prices-and-banks" link2text="الاسعار" header={itemData7.header} para={itemData7.para}   />
                        
                        <PricesText />
                        <Prices />
                        <PricesCalc />
                        <FingerPrintPrices />



                        <Faqs />
                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />



                
                    <Route path="/fingerprint-devices" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={FingerPrintDevImg}  link1="our-services"  link1text="الخدمات" link2="fingerprint-devices" link2text="اجهزة البصمة" header={itemData6.header} para={itemData6.para}   />
                        
                        <FingerPrintDevText />
                        <DevicesBenefits />
                        <AvailableDevices />
                        <DevicesBenefitsText />
                        <Faqs />
                        <ContactAd />
                        <ContactUs />
                        <Footer />


                    </>
                } />


                                    
                        <Route path="/sms-services" element={
                                            <>
                        <Loader />

                                                                    <TempPopUp />

                                                <Navbar />
                                                <SectionHeader  link1="our-services"  link1text="الخدمات" link2="sms-services" link2text="خدمات SMS" header={itemData5.header} para={itemData5.para}  />
                        
                        <SmsServicesText />
                        <WhoGetsOurBenefits />
                        <SystemSettingsandBenefits />
                        <ApiFiles />






                        <ContactAd />
                        <ContactUs />
                        <Footer />

                        


                    </>
                } />



                                
                    <Route path="/our-clients" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={OurClientsPageHeaderImg}  link1="our-clients"  link1text="عملائنا" header=" عملاؤنا الأوفياء و شركاء النجاح " 
                                        para=""/>
                        <OurClients />

                        <ContactAd />
                        <ContactUs />
                        <Footer />
                        </>
                    } />



                <Route path="/careers" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={CareersPageHeaderImg}  link1="careers"  link1text="التوظيف" header={itemData8.header} para={itemData8.para}  />

                        <Career />
                        
                        <Footer />
                        </>
                    } />




                <Route path="/contact-us" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={ContactUsPageHeader}  link1="contact-us"  link1text="تواصل معنا" header=" نحن في خدمتك طوال الاسبوع 7/24 " 
                                        para=" "  />
                        
                        <ContactUs />


                        <Footer />
                        </>
                    } />


            <Route path="/faqs" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={FaqsPageHeader}  link1="faqs"  link1text="الأسئلة الشائعة" header=" كل ما تريد معرفته 
في دليل الأسئلة الشائعة" 
                                        para="  "  />
                        <FaqsPage />

                        <ContactAd />
                        <ContactUs />
                        <Footer />
                        </>
                    } />



                <Route path="/policy-privacy" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={FaqsPageHeader}  link1="policy-privacy"  link1text="السياسات والخصوصية" header=" كل ما تريد معرفته 
في دليل الأسئلة الشائعة" 
                                        para="  "  />
                        <PrivacyPolicy />

                        <ContactAd />
                        <ContactUs />
                        <Footer />
                        </>
                    } />


            <Route path="/terms-conditions" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <SectionHeader img={FaqsPageHeader}  link1="terms-conditions"  link1text="الاحكام و الشروط" header=" كل ما تريد معرفته 
في دليل الأسئلة الشائعة" 
                                        para="  "  />

                        <TermsConditions />
                        <ContactAd />
                        <ContactUs />
                        <Footer />
                        </>
                    } />


                <Route path="/404" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <NotFound />
                        </>
                    } />

                <Route path="/coming-soon" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Navbar />
                        <ComingSoon />
                        </>
                    } />

                    
                <Route path="/new-password" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <PasswordReset />
                        </>
                    } />


            <Route path="/login" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Login />
                        </>
                    } />


                    
            <Route path="/sign-up" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <Signup />
                        </>
                    } />


            <Route path="/reset-password-email" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <PasswordResetEmail />
                        </>
                    } />


            <Route path="/verfication-code" element={
                    <>
                        <Loader />

                                            <TempPopUp />

                        <PasswordResetCode />
                        </>
                    } />






<Route path="/en/" element={
                    <>
                        <Loader />
                        <TempPopUpEN />
                        
                        <NavbarEN />
                        <LandingEN />
                        <AboutEN />
                        <MostServicesEN />
                        <NumbersEN />

                        <CoursesExplainEN />
                        <PlansEN />
                        <RatesEN />

                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />


            
            <Route path="/en/about-us" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={AboutImg} link1="en/about-us" link1text="What about us" header={itemData1.header_en} para={itemData1.para_en}  />
                        <AboutPageTextEN />
                        <NumbersEN />
                        <OurMessageEN />
                        <VesionEN />
                        <ExpreinceEN />
                        <TeamMembersEN />
                        <OurWorkEN />
                        
                        <ContactAdEN />
                        <ContactUsEN />




                        <FooterEN />


                    </>
                } />


                <Route path="/en/our-services" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN className="no-image" img={OurServicesImg} link1="en/our-services" link1text="Our services" header={itemData2.header_en} para={itemData2.para_en}  />
                        <OurServicesPageTextEN />
                        <ServicesStepsEN />
                        <ServicesEN />
                        <FaqsEN />
                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />


                

            <Route path="/en/how-to-use" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={HowToUseImg} link1="en/how-to-use" link1text="How to use" header={itemData3.header_en} para={itemData3.para_en}  />
                        
                        <AppliedCoursesEN />
                        <HowToUseTextEN />
                        <CoursesCategoriesEN />

                        <FaqsEN />
                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />


                
            <Route path="/en/all-courses" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN   link1="en/how-to-use"  link1text="How to use" link2="en/all-courses" link2text="All Courses"  header={itemData4.header_en} para={itemData4.para_en}  />
                        
                        <AllCoursesEN />


                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />


                <Route path="/en/explain-lessons" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN   link1="en/how-to-use"  link1text="How to use" link2="en/explain-lessons" link2text="Finger Print Devices Usage"  header=" View Course " 
                        para=" "  />
                        
                        <ExpLessonEN />


                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />



                <Route path="/en/prices-and-banks" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <PricesInvoicePopupEN />
                        <PayWaysPopupEN />
                        <BanksPopupEN />
                        <NavbarEN />
                        <SectionHeaderEN img={PriesImg}  link1="en/how-to-use"  link1text="How to use" link2="en/prices-and-banks" link2text="Prices" header={itemData7.header_en} para={itemData7.para_en}   />
                        
                        <PricesTextEN />
                        <PricesEN />
                        <PricesCalcEN />
                        <FingerPrintPricesEN />



                        <FaqsEN />
                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />



                
                    <Route path="/en/fingerprint-devices" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={FingerPrintDevImg}  link1="en/our-services"  link1text="Our Services" link2="en/fingerprint-devices" link2text="Finger print devices" header={itemData6.header_en} para={itemData6.para_en}   />
                        
                        <FingerPrintDevTextEN />
                        <AvailableDevicesEN />
                        <DevicesBenefitsTextEN />
                        <FaqsEN />
                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />


                    </>
                } />


                                    
                        <Route path="/en/sms-services" element={
                                            <>
                        <Loader />

                                                                    <TempPopUpEN />

                                                <NavbarEN />
                                                <SectionHeaderEN  link1="en/our-services"  link1text="Services" link2="en/sms-services" link2text="SMS Services " header={itemData5.header_en} para={itemData5.para_en}  />
                        
                        <SmsServicesTextEN />
                        <WhoGetsOurBenefitsEN />
                        <SystemSettingsandBenefitsEN />
                        <ApiFilesEN />






                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />

                        


                    </>
                } />



                                
                    <Route path="/en/our-clients" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={OurClientsPageHeaderImg}  link1="en/our-clients"  link1text="Our Clients" header=" Our loyal customers and partners in succes" 
                                        para=""/>
                        <OurClientsEN />

                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />
                        </>
                    } />



                <Route path="/en/careers" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={CareersPageHeaderImg}  link1="en/careers"  link1text="Careers" header={itemData8.header_en} para={itemData8.para_en}  />

                        <CareerEN />
                        
                        <FooterEN />
                        </>
                    } />




                <Route path="/en/contact-us" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={ContactUsPageHeader}  link1="en/contact-us"  link1text="Contact us" header="We are at your service 24/7 throughout the week" 
                                        para=" "  />
                        
                        <ContactUsEN />


                        <FooterEN />
                        </>
                    } />


            <Route path="/en/faqs" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={FaqsPageHeader}  link1="en/faqs"  link1text="FAQ guide" header=" Everything you want to know
in the FAQ guide" 
                                        para="  "  />
                        <FaqsPageEN />

                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />
                        </>
                    } />



                <Route path="/en/policy-privacy" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={FaqsPageHeader}  link1="en/policy-privacy"  link1text="Privacy Policy" header=" Everything you want to know
in the FAQ guide" 
                                        para="  "  />
                        <PrivacyPolicyEN />

                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />
                        </>
                    } />


            <Route path="/en/terms-conditions" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <SectionHeaderEN img={FaqsPageHeader}  link1="en/terms-conditions"  link1text="Terms & Conditions" header=" Everything you want to know
in the FAQ guide" 
                                        para="  "  />

                        <TermsConditionsEN />
                        <ContactAdEN />
                        <ContactUsEN />
                        <FooterEN />
                        </>
                    } />


                <Route path="/en/404" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <NotFoundEN />
                        </>
                    } />

                <Route path="/en/coming-soon" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <NavbarEN />
                        <ComingSoonEN />
                        </>
                    } />

                    
                <Route path="/en/new-password" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <PasswordResetEN />
                        </>
                    } />


            <Route path="/en/login" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <LoginEN />
                        </>
                    } />


                    
            <Route path="/en/sign-up" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <SignupEN />
                        </>
                    } />


            <Route path="/en/reset-password-email" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <PasswordResetEmailEN />
                        </>
                    } />


            <Route path="/en/verfication-code" element={
                    <>
                        <Loader />

                                            <TempPopUpEN />

                        <PasswordResetCodeEN />
                        </>
                    } />





                
<Route path="/admin-login"element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><EditUser /></>} />
            <Route path="/add-user" element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AddUser /></>}/>
            <Route path="/all-users"element={<><SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        /><AllUsersPage /></> }/>



    <Route path="/all-landing"element={<>
        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <ManageLanding />
        
        </> 
    
    }/>

    <Route path="/edit-landing"element={<>
        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <ManageLanding />
        
        </> 
    
    }/>

<Route path="/all-about"element={<>
        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <ManageAbout />
        
        </> 
    
    }/>


    
<Route path="/edit-about"element={<>
        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <ManageAbout />
        
        </> 
    
    }/>




    <Route path="/all-services"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServices />
            
            </> 
        
        }/>

        
    <Route path="/add-services"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServices />
            
            </> 
        
        }/>

        
    <Route path="/edit-services"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServices />
            
            </> 
        
        }/>


        
<Route path="/all-numbers"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageNumbers />
            
            </> 
        
        }/>

        
    <Route path="/edit-numbers"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageNumbers />
            
            </> 
        
        }/>





<Route path="/all-courses-explain"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageCoursesExplain />
            
            </> 
        
        }/>

        
    <Route path="/edit-courses-explain"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageCoursesExplain />
            
            </> 
        
        }/>



<Route path="/all-prices-sms"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesSms />
            
            </> 
        
        }/>

        
    <Route path="/edit-prices-sms"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesSms />
            
            </> 
        
        }/>



<Route path="/all-prices-devices"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesDevices />
            
            </> 
        
        }/>

        
    <Route path="/edit-prices-devices"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesDevices />
            
            </> 
        
        }/>



<Route path="/all-rates"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageRates />
            
            </> 
        
        }/>

<Route path="/add-rates"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageRates />
            
            </> 
        
        }/>

        
    <Route path="/edit-rates"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageRates />
            
            </> 
        
        }/>


<Route path="/all-ad-contact"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAdContact />
            
            </> 
        
        }/>

        
    <Route path="/edit-ad-contact"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAdContact />
            
            </> 
        
        }/>




<Route path="/all-about-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAboutSectionHeader />
            
            </> 
        
        }/>

        
    <Route path="/edit-about-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAboutSectionHeader />
            
            </> 
        
        }/>


<Route path="/all-about-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAboutText />
            
            </> 
        
        }/>

        
    <Route path="/edit-about-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAboutText />
            
            </> 
        
        }/>



<Route path="/all-expreince-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExprienceHeader />
            
            </> 
        
        }/>

        
    <Route path="/edit-expreince-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExprienceHeader />
            
            </> 
        
        }/>





<Route path="/all-expreince"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExprience />
            
            </> 
        
        }/>

        
    <Route path="/edit-expreince"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExprience />
            
            </> 
        
        }/>

<Route path="/add-expreince"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExprience />
            
            </> 
        
        }/>




<Route path="/all-team"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageTeam />
            
            </> 
        
        }/>

        
    <Route path="/edit-team"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageTeam />
            
            </> 
        
        }/>

<Route path="/add-team"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageTeam />
            
            </> 
        
        }/>





<Route path="/all-clients"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageClients />
            
            </> 
        
        }/>

        
    <Route path="/edit-clients"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageClients />
            
            </> 
        
        }/>

<Route path="/add-clients"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageClients />
            
            </> 
        
        }/>








<Route path="/all-services-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesSectionHeader />
            
            </> 
        
        }/>

        
    <Route path="/edit-services-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesSectionHeader />
            
            </> 
        
        }/>




<Route path="/all-services-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesText />
            
            </> 
        
        }/>

        
    <Route path="/edit-services-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesText />
            
            </> 
        
        }/>


<Route path="/all-services-page"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesPage />
            
            </> 
        
        }/>

        
    <Route path="/edit-services-page"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageServicesPage />
            
            </> 
        
        }/>



<Route path="/all-faqs"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqs />
            
            </> 
        
        }/>

<Route path="/add-faqs"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqs />
            
            </> 
        
        }/>

        
    <Route path="/edit-faqs"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqs />
            
            </> 
        
        }/>


        
<Route path="/all-how-to-use-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageHowToUseSectionHeader />
            
            </> 
        
        }/>


        
    <Route path="/edit-how-to-use-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageHowToUseSectionHeader />
            
            </> 
        
        }/>




        
<Route path="/all-how-to-use-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageHowToUseText />
            
            </> 
        
        }/>


        
    <Route path="/edit-how-to-use-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageHowToUseText />
            
            </> 
        
        }/>


        
<Route path="/all-explain-category"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExplainCategories />
            
            </> 
        
        }/>

<Route path="/add-explain-category"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExplainCategories />
            
            </> 
        
        }/>

        
    <Route path="/edit-explain-category"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageExplainCategories />
            
            </> 
        
        }/>




        
<Route path="/all-all-courses-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAllCoursesSectionHeader />
            
            </> 
        
        }/>

<Route path="/edit-all-courses-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAllCoursesSectionHeader />
            
            </> 
        
        }/>




       
<Route path="/all-all-courses"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAllCourses />
            
            </> 
        
        }/>

<Route path="/add-all-courses"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAllCourses />
            
            </> 
        
        }/>

        
    <Route path="/edit-all-courses"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageAllCourses />
            
            </> 
        
        }/>



<Route path="/all-sms-services-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsServicesSectionHeader />
            
            </> 
        
        }/>

<Route path="/edit-sms-services-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsServicesSectionHeader />
            
            </> 
        
        }/>




<Route path="/all-sms-services-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsServicesText />
            
            </> 
        
        }/>

<Route path="/edit-sms-services-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsServicesText />
            
            </> 
        
        }/>




        
<Route path="/all-who-benefits"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageWhoBenefits />
            
            </> 
        
        }/>

<Route path="/edit-who-benefits"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageWhoBenefits />
            
            </> 
        
        }/>


        
<Route path="/all-sms-settings"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsSettings />
            
            </> 
        
        }/>

<Route path="/edit-sms-settings"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsSettings />
            
            </> 
        
        }/>

<Route path="/add-sms-settings"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageSmsSettings />
            
            </> 
        
        }/>



        
<Route path="/all-api-files"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageApiFiles />
            
            </> 
        
        }/>

<Route path="/edit-api-files"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageApiFiles />
            
            </> 
        
        }/>


<Route path="/add-api-files"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageApiFiles />
            
            </> 
        
        }/>
        

        <Route path="/all-finger-print-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintSectionHeader />
            
            </> 
        
        }/>

<Route path="/edit-finger-print-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintSectionHeader />
            
            </> 
        
        }/>





<Route path="/all-finger-print-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintText />
            
            </> 
        
        }/>

<Route path="/edit-finger-print-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintText />
            
            </> 
        
        }/>




        
<Route path="/all-finger-print-devices"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintDevices />
            
            </> 
        
        }/>

<Route path="/edit-finger-print-devices"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintDevices />
            
            </> 
        
        }/>


<Route path="/add-finger-print-devices"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintDevices />
            
            </> 
        
        }/>




<Route path="/all-finger-print-benefits"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintBenefits />
            
            </> 
        
        }/>

<Route path="/edit-finger-print-benefits"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintBenefits />
            
            </> 
        
        }/>


<Route path="/add-finger-print-benefits"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFingerPrintBenefits />
            
            </> 
        
        }/>





<Route path="/all-prices-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesSectionHeader />
            
            </> 
        
        }/>

<Route path="/edit-prices-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesSectionHeader />
            
            </> 
        
        }/>



<Route path="/all-prices-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesText />
            
            </> 
        
        }/>

<Route path="/edit-prices-text"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesText />
            
            </> 
        
        }/>




<Route path="/all-prices-packages"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesPackages />
            
            </> 
        
        }/>

<Route path="/edit-prices-packages"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePricesPackages />
            
            </> 
        
        }/>




<Route path="/all-careers-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageCareersSectionHeader />
            
            </> 
        
        }/>

<Route path="/edit-careers-section-header"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageCareersSectionHeader />
            
            </> 
        
        }/>







<Route path="/all-faqs-page-categories"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqsPageCategories />
            
            </> 
        
        }/>

<Route path="/edit-faqs-page-categories"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqsPageCategories />
            
            </> 
        
        }/>


<Route path="/add-faqs-page-categories"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageFaqsPageCategories />
            
            </> 
        
        }/>






<Route path="/all-questions"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageQuestions />
            
            </> 
        
        }/>

<Route path="/edit-questions"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageQuestions />
            
            </> 
        
        }/>


<Route path="/add-questions"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageQuestions />
            
            </> 
        
        }/>




<Route path="/all-privacy"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePrivacy />
            
            </> 
        
        }/>

<Route path="/edit-privacy"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManagePrivacy />
            
            </> 
        
        }/>





<Route path="/all-terms"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageTerms />
            
            </> 
        
        }/>

<Route path="/edit-terms"element={<>
            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>
            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
            
            <ManageTerms />
            
            </> 
        
        }/>
        <Route path="/all-social" element={
            <>

        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <AllSocialPage />
        
        </> }/>


        <Route path="/edit-social" element={<>

            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>

            <EditSocial />

            </> }/>


            <Route path="/all-website" element={
            <>

        <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

        <SearchBar className={hideHeaderFooter ? "" : "hide"}/>
        
        <ManageWebsite />
        
        </> }/>


        <Route path="/edit-website" element={<>

            <SideNav className={hideHeaderFooter ? "" : "hide"} sessionExists={sessionExists} setSessionExists={setSessionExists}/>

            <SearchBar className={hideHeaderFooter ? "" : "hide"}/>

            <ManageWebsite />

            </> }/>




        </Routes>







        
    </div>
);
}

export default App;
