import '../css/paywayspopup.css';
import React, { useEffect } from 'react';
import '../css/popup.css';
import $ from 'jquery';

import downLayImg from '../images/popup/downlayimage.png';
import SaudiArabiaFlag from '../images/popup/flag.png';

import { Link as RouterLink } from 'react-router-dom';

import PayWayImg1 from '../images/popup/2.gif';
import PayWayImg2 from '../images/popup/1.gif';

function PayWaysPopup() {
    useEffect(() => {
        // Close popup when clicking outside of pop-content
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.pop-content').length && !$(event.target).closest('.open-pay-ways-popup').length) {
                closePopup();
            }
        });

        // Close popup when clicking the close-pop button
        $('.close-pop').on('click', function() {
            closePopup();
        });

        // Copy text functionality
        $('.copy-next-text').on('click', function() {
            const textToCopy = $(this).siblings('span').text();
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        });

        // Close popup function with animation
        function closePopup() {
            $('.pay-ways-pop-up').fadeOut(500); // Adjust the animation duration as needed
        }

        // Cleanup event listeners on component unmount
        return () => {
            $(document).off('click');
            $('.close-pop').off('click');
            $('.copy-next-text').off('click');
        };
    }, []);

    return (
        <>
            <section className='en pay-ways-pop-up'>
                <div className='pop-content'>
                    <div className='header'>
                        <button className='close-pop'> 
                            <i className="las la-times"></i>
                        </button>
                        <div>
                            <h1 className='section-headline'>Purchase Membership</h1>
                            <p>
                                Choose one of the following methods to subscribe to the membership, 
                            </p>
                            <p>
                                Either by paying directly to our bank accounts or by contacting us directly
                            </p>
                        </div>
                    </div>

                    <div className='pay-ways'>
                        <div className='way'>
                            <img src={PayWayImg1} alt='pay way image'/>
                            <h2> 
                                Technical Support
                            </h2>
                            <RouterLink to="/en/contact-us" className='link-btn-color'> Contact Us </RouterLink>
                        </div>

                        <div className='way'>
                            <img src={PayWayImg2} alt='pay way image'/>
                            <h2> 
                                Bank Accounts
                            </h2>
                            <button className='open-bank-popup link-btn-color'>View Bank Accounts</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PayWaysPopup;
