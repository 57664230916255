import './css/systemsettingsandbenefits.css';
import React, { useEffect ,useState} from 'react';


import  ObjectImage from './images/devices/objects.png';
import $, { ready } from 'jquery'; // Import jQuery



import axios from '../config/index';


function SystemSettingsandBenefits(){

    const [change, setChange] = useState(false);
    const [itemData2, setitemData2] = useState([]);

    useEffect(() => {
        setChange(true);

        // jQuery script to handle toggle functionality for .question .header
        $('.question .header').on('click', function () {
            $(this).find('button').toggleClass('active');
            $(this).find('button').find('i').toggleClass('la-plus la-minus');
            $(this).next('.content').toggleClass('active');
        });

        // Cleanup event listeners on unmount
        return () => {
            $('.slide-toggle').off('click');
            $('.question .header').off('click');
        };
    }, [itemData2]); // Empty dependency array to ensure it runs only once


    




    const getItemData2 = () => {

        const table = "sms_settings";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }else{
                console.log(res.data)

            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {

        getItemData2();
    }, []);


    return(
        <>
            <section className='system-settings-and-benefits'>
                <img src={ObjectImage} alt='object image' />
                <div className='container'>
                    <div className='row'>

                        
                        <div className='col-12'>
                            <div className='header'>
                                <h1 className='section-headline'>
                                إعدادات و مميزات النظام
                                </h1>
                            </div>
                        </div>


                        {
                            itemData2.length > 0 ? (
                                <>
                                    {itemData2.map((setting, index) => {
                                        let settings = setting.para.split(",");
                                        return (
                                        
                                    <div key={index} className='col-12'>
                                        <div className='question'>
                                            <div className='header'>
                                                <button className='slide-toggle'>
                                                    <i class="las la-minus"></i>
                                                </button>

                                                <h2 className='section-sec-headline'>
                                                {setting.header}

                                                </h2>
                                            </div>

                                            <div className='active content'>
                                                <ul>
                                                    {settings.map((oneele)=>{
                                                        return(
                                                            <>
                                                            <li>
                                                                {oneele}
                                                            </li>
                                                            </>
                                                        )
                                                    })}
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                        );
                                    })}
                                </>
                            ) : null
                        }




                    </div>
                </div>
            </section>
        </>
    )
}

export default SystemSettingsandBenefits;
