import '../css/ourmessage.css';
import OurMessageImg from '../images/ourmessage.gif';
import OurMessageEC from '../images/messageec.png';

import axios from '../../config/index';
import { useEffect, useState } from 'react';

function OurMessage(){

    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "about";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData1(1);
    }, []);



    return(
        <>
            <section className='en our-message'>
                <img src={OurMessageEC} alt='eclipse' />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'> 
                                {itemData1.message_en}
                                </h1>
                                <p className='section-text'>
                                {itemData1.message_para_en}
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img src={OurMessageImg} alt='section image' />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurMessage;