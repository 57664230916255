import './css/privacypolicy.css';
import { Link as RouterLink } from 'react-router-dom';
import { useEffect,useState } from 'react';

import axios from '../config/index';


function PrivacyPolicy(){


    
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "privacy";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);



    return(
        <>
            <section className='policy-privacy'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='page-side-nav'>
                                <ul>
                                <li>
                                        <RouterLink  to="/faqs">
                                        الأسئلة الشائعة
                                        </RouterLink>
                                    </li>


                                    <li>
                                        <RouterLink className='active' to="/policy-privacy">
                                        السياسات والخصوصية
                                        </RouterLink>
                                    </li>


                                    <li>
                                        <RouterLink  to="/terms-conditions">
                                        الأحكام والشروط
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                            <div className='text-content'>
                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_1}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_1}
                                                                        </p>
                                </div>


                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_2}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_2}
                                                                        </p>
                                </div>



                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_3}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_3}
                                                                        </p>
                                </div>





                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_4}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_4}
                                                                        </p>
                                </div>


                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_5}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_5}
                                                                        </p>
                                </div>

                                <div>
                                    <h2 className='section-sec-headline'> 
                                    {itemData1.header_6}

                                    </h2>

                                    <p className='section-text'>
                                    {itemData1.para_6}
                                                                        </p>
                                </div>




                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy;