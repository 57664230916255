import '../css/footer.css';
import { Link as RouterLink } from 'react-router-dom';

import Logo from '../images/logo.svg';

import FacebookIcon from '../images/social/facebook.svg';
import TwitterIcon from '../images/social/twitter.svg';
import InstaIcon from '../images/social/insta.svg';
import YoutubeIcon from '../images/social/youtube.svg';
import TelegramIcon from '../images/social/telegram.svg';
import LiknedinIcon from '../images/social/linkedin.svg';

import axios from '../../config/index';
import React, { useState, useEffect } from 'react';


function Footer(){

        const [socails, setsocails] = useState([]);

    useEffect(() => {
        const getAllSocials = (itemId) => {
            const table = "socials";
            axios.post('/get-item', { itemId, table }).then((res) => {
                if (!res.data.message) {
                    setsocails(res.data);
                }
            }).catch((err) => console.log(err));
        };
        getAllSocials(1);
    }, []);

    return(
        <>
            <footer className='en'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> Subscribe to the newsletter </h2>

                                <form>
                                    <input type='email' required placeholder='Email' />
                                    <button className='link-btn-color' type='submit'> Send </button>
                                </form>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> Other Links  </h2>

                                <ul>
                                    <li>
                                        <RouterLink to="/en/faqs">
                                        Frequently Asked Questions
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/careers">
                                        Employment
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/terms-conditions">
                                        Terms and Conditions
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/404">
                                         404 Error
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/policy-privacy">
                                        Policies and Privacy
                                        </RouterLink>
                                    </li>


                                    <li>
                                        <RouterLink to="/en/coming-soon">
                                        Coming Soon
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>




                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> About Us </h2>

                                <ul>
                                    <li>
                                        <RouterLink to="/en/">
                                        Home Page
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/how-to-use">
                                        How To Use
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/about-us">
                                        Who us ?
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/our-clients">
                                        Our Clients
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/en/our-services">
                                        Our Services
                                        </RouterLink>
                                    </li>


                                    <li>
                                        <RouterLink to="/en/prices-and-banks">
                                        Prices
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img  src={Logo}  alt='logo'/>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </footer>


            <div className='copy-right en'>
                <div className='container'>
                    <div className='copy-right-content'>
                        <div className='left'>
                            <h3>© 2023 Al Madar Al Taqani Foundation. All rights reserved. </h3>

                            <div className='socials'>
                                <ul>
                                    <li>
                                        <a href={socails.facebook} target='_blank' aria-label='social link'>
                                            <img src={FacebookIcon}  alt='social icon'/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={socails.twitter} target='_blank' aria-label='social link'>
                                        <img src={TwitterIcon}  alt='social icon'/>

                                        </a>
                                    </li>

                                    <li>
                                        <a href={socails.instagram} target='_blank' aria-label='social link'>
                                        <img src={InstaIcon}  alt='social icon'/>

                                        </a>
                                    </li>


                                    <li>
                                        <a href={socails.youtube} target='_blank' aria-label='social link'>
                                        <img src={YoutubeIcon}  alt='social icon'/>

                                        </a>
                                    </li>


                                    <li>
                                        <a href={socails.linkedin} target='_blank' aria-label='social link'>
                                        <img src={LiknedinIcon}  alt='social icon'/>

                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;