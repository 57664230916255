import './css/appliedcourses.css';
import NoResultImg from './images/noresult.gif';
import { Link as RouterLink } from 'react-router-dom';


function AppliedCourses(){
    return(
        <>
            <section className='applied-courses'>
                <div className='container'>
                    <div className='screens-btns'>
                        {/* <button className='screen-btn active'>
                            خدمة SMS
                        </button>

                        <button className='screen-btn'>
                            استخدام نظام نور    
                        </button>

                        <button className='screen-btn'>
                            استخدام اجهزة البصمة    
                        </button> */}
                    </div>

                    <div className='screens'>
                        <div className='screen active'>
                            <RouterLink to="/all-courses">   
                                تريد البدء؟
                            </RouterLink>

                            <h2> 
                            لم تسجل في اي من الشروحات حتى الان ؟.. 
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                        <div className='screen'>
                            <RouterLink to="">   
                                تريد البدء؟
                            </RouterLink>

                            <h2> 
                            لم تسجل في اي من الشروحات حتى الان .. 
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                        <div className='screen'>
                            <RouterLink to="">   
                                تريد البدء؟
                            </RouterLink>

                            <h2> 
                            لم تسجل في اي من الشروحات حتى الان .. 
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default AppliedCourses;