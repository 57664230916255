import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import '../css/contactus.css';
import CallIcon from '../images/contact/call.svg';
import EmailIcon from '../images/contact/email.svg';
import LocationIcon from '../images/contact/location.svg';
import youtubeIcon from '../images/contact/youtube.svg';
import instagramIcon from '../images/contact/instagram.svg';
import linkedinIcon from '../images/contact/linkedin.svg';
import twitterIcon from '../images/contact/twitter.svg';
import facebookIcon from '../images/contact/facebook.svg';
import LinesImage from '../images/contact/lines.png';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import axios from '../../config/index';

function ContactUs() {
    const [socails, setsocails] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        subject: '',
        message: ''
    });
    const [captchaToken, setCaptchaToken] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSelectedCountry({
                value: "SA",
                label: "Saudi Arabia (+966)",
                countryCode: "SA"
            });
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const countries = getCountries().map((countryCode) => ({
        value: countryCode,
        label: `${en[countryCode]} (+${getCountryCallingCode(countryCode)})`,
        countryCode: countryCode,
    }));

    const customSingleValue = ({ data }) => (
        <div className="custom-single-value">
            <ReactCountryFlag countryCode={data.countryCode} svg style={{ marginRight: 10 }} />
            {data.label}
        </div>
    );

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!captchaToken) {
            alert('Please complete the CAPTCHA');
            return;
        }
        const data = { ...formData, phoneNumber, captchaToken };
        axios.post('/send-email', data)
            .then(response => {
                alert('Email sent successfully');
            })
            .catch(error => {
                console.error('Error sending email:', error);
            });
    };
    
    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };


    useEffect(() => {
        const getAllSocials = (itemId) => {
            const table = "socials";
            axios.post('/get-item', { itemId, table }).then((res) => {
                if (!res.data.message) {
                    setsocails(res.data);
                }
            }).catch((err) => console.log(err));
        };
        getAllSocials(1);
    }, []);

    return (
        <section className='en contact-us'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        <div className='contact-info'>
                            <img src={LinesImage} alt='lines image' />
                            <h1>Contact information</h1>
                            <p>
                            Enter your information correctly, and our team will get back to you within 24 hours of receiving your contact request.
                            </p>
                            <span className='line'></span>
                            <ul>
                                <li>
                                    <a aria-label="external link" href={`tel:${socails.phone}`} target='_blank'>
                                        <img src={CallIcon} alt='call icon' />
                                        {socails.phone}
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="external link" href={`mailto:${socails.email}`} target='_blank'>
                                        <img src={EmailIcon} alt='email icon' />
                                        {socails.email}
                                    </a>
                                </li>
                                <li>
                                    <a aria-label="external link" href={socails.location} target='_blank'>
                                        <img src={LocationIcon} alt='location icon' />
                                        {socails.address}
                                    </a>
                                </li>
                            </ul>
                            <div className='socials'>
                                <a href={socails.youtube} target='_blank' aria-label="external link">
                                    <img src={youtubeIcon} alt='icon' />
                                </a>
                                <a href={socails.instagram} target='_blank' aria-label="external link">
                                    <img src={instagramIcon} alt='icon' />
                                </a>
                                <a href={socails.linkedin} target='_blank' aria-label="external link">
                                    <img src={linkedinIcon} alt='icon' />
                                </a>
                                <a href={socails.twitter} target='_blank' aria-label="external link">
                                    <img src={twitterIcon} alt='icon' />
                                </a>
                                <a href={socails.facebook} target='_blank' aria-label="external link">
                                    <img src={facebookIcon} alt='icon' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='form-element'>
                            <form onSubmit={handleSubmit}>
                                <div className='inputs'>
                                    <div className='input'>
                                        <label>Email address</label>
                                        <input type='email' name='email' required placeholder='example@gmail.com' onChange={handleChange} />
                                    </div>
                                    <div className='input'>
                                        <label>Write your name</label>
                                        <input type='text' name='name' required placeholder='Islam Ayman Mohamed' onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='inputs'>
                                    <div className='input'>
                                        <label>Mobile number</label>
                                        <div className='splitter'>
                                            <Select
                                                value={selectedCountry}
                                                onChange={setSelectedCountry}
                                                options={countries}
                                                getOptionLabel={(option) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <ReactCountryFlag countryCode={option.countryCode} svg style={{ marginRight: 10 }} />
                                                        {option.label}
                                                    </div>
                                                )}
                                                getOptionValue={(option) => option.value}
                                                styles={{ container: (provided) => ({ ...provided, flex: 1 }) }}
                                                components={{ SingleValue: customSingleValue }}
                                            />
                                            <PhoneInput
                                                value={phoneNumber}
                                                onChange={setPhoneNumber}
                                                country={selectedCountry ? selectedCountry.value : undefined}
                                                placeholder='.... .... ...'
                                                required
                                                name='phonenumber'
                                                style={{ flex: 2, marginLeft: '10px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='inputs'>
                                    <div className='input'>
                                        <label>Subject of the message</label>
                                        <input type='text' name='subject' required placeholder='Write the subject of the message here' onChange={handleChange} />
                                    </div>
                                </div>
                                <div className='inputs'>
                                    <div className='input'>
                                        <label>Your message</label>
                                        <textarea name='message' required placeholder='Write your message here' onChange={handleChange}></textarea>
                                    </div>
                                </div>

                                <div className='input'>
                                    <ReCAPTCHA
                                        sitekey="6LcpkxYqAAAAAMYD41V-76-mSVA6ov3biaB87ILO"
                                        onChange={handleCaptchaChange}
                                    />
                                </div>
                                
                                <button type='submit' className='link-btn-color'>Send</button>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='map'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14946560.852907427!2d55.722546187363!3d23.851906896666616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15e7b33fe7952a41%3A0x5960504bc21ab69b!2z2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2seg!4v1719067175619!5m2!1sar!2seg" width="100%" height="500" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;
