import '../css/pricescalc.css';
import FrameDots from '../images/prices/framedots.png';

import { useState,useEffect } from 'react';
import Ecp from '../images/messageec.png';
import $ from 'jquery';


import '../css/priceinvoicepopup.css';
import '../css/popup.css';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import Logo from '../images/logo.svg';
import InvoiceImg from '../images/popup/invocie.png';
import QRcodeImg from '../images/popup/qr-code.svg';
import EcImg from '../images/landing.png';
import LocationIcon from '../images/icons/location.svg';
import EmailIocn from '../images/icons/email.svg';
import CallIocn from '../images/icons/call.svg';

import axios from '../../config/index';

function PricesCalc() {
    const [itemData, setItemData] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedMessages, setSelectedMessages] = useState(0);
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const [enteredPrice, setEnteredPrice] = useState('');
    const [enteredName, setEnteredName] = useState('');
    const [oneMessagePrice, setOneMessagePrice] = useState(0);
    const [vatPrice, setVatPrice] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const results = await Promise.all(
                    [1, 2, 3, 4, 5].map(itemId => axios.post('/get-item', { itemId, table: "prices_packages" }))
                );
                const data = results.map(res => res.data);
                setItemData(data);

                const planData = data.map(item => ({
                    id: item.id,
                    name: item.header,
                    oneMsgPrice: item.price_for_msg,
                    msgsForPlan: item.quantity,
                    giftMsgs: item.gift
                }));
                setPlans(planData);
            } catch (err) {
                console.error(err);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        setCurrentDate(formattedDate);
    }, []);

    useEffect(() => {
        const openPopUp = () => $('.price-invoice-popup').fadeIn(500);

        $('.open-price-invoice-popup').on('click', openPopUp);

        return () => {
            $('.open-price-invoice-popup').off('click', openPopUp);
        };
    }, []);

    useEffect(() => {
        if (plans.length > 0) {
            handlePlanChange({ target: { value: plans[0].id } });
        }
    }, [plans]);

    const handlePlanChange = (e) => {
        const selectedPlanId = parseInt(e.target.value);
        const plan = plans.find(p => p.id === selectedPlanId);
        setSelectedPlan(plan);
        resetForm();
    };

    const resetForm = () => {
        setCalculatedPrice(0);
        setSelectedMessages(0);
        setEnteredPrice('');
    };

    const adjustMessagesCount = (count) => {
        const regex = /^(9+)(0*)$/;
        const specificCases = [2999, 4999, 999, 9999, 19999, 49999, 99999, 499999];
        if (regex.test(count.toString()) || specificCases.includes(count)) {
            return count + 1;
        }
        return count;
    };

    const handleMessagesChange = (e) => {
        let messagesCount = parseInt(e.target.dataset.value);
        let pricePerMessage;
        messagesCount = adjustMessagesCount(messagesCount);

        if (messagesCount <= 2999) {
            pricePerMessage = 0.095;
        } else if (messagesCount <= 4999) {
            pricePerMessage = 0.09;
        } else if (messagesCount <= 9999) {
            pricePerMessage = 0.085;
        } else if (messagesCount <= 19999) {
            pricePerMessage = 0.075;
        } else if (messagesCount <= 49999) {
            pricePerMessage = 0.07;
        } else if (messagesCount <= 99999) {
            pricePerMessage = 0.065;
        } else if (messagesCount <= 499999) {
            pricePerMessage = 0.06;
        } else if (messagesCount <= 999999) {
            pricePerMessage = 0.058;
        } else if (messagesCount >= 1000000) {
            pricePerMessage = 0.056;
        }

        const price = (messagesCount * pricePerMessage) + ((messagesCount * pricePerMessage) * 0.15);
        let vat = (messagesCount * pricePerMessage) * 0.15;


        setSelectedMessages(messagesCount);
        setCalculatedPrice(price.toFixed(2));
        setVatPrice(vat);
        setOneMessagePrice(pricePerMessage);
    };

    const handlePriceInputChange = (e) => {
        let price = parseFloat(e.target.value);
        setEnteredPrice(price);
        setVatPrice((price) * 0.13);

        let calcPrice = price - (price * 0.13);


        let pricePerMessage = 0;
        let messagesCount = 0;

        if (calcPrice) {
            if (calcPrice <= 2999 * 0.095) {
                pricePerMessage = 0.095;
            } else if (calcPrice <= 4999 * 0.085) {
                pricePerMessage = 0.09;
            } else if (calcPrice <= 9999 * 0.085) {
                pricePerMessage = 0.085;
            } else if (calcPrice <= 19999 * 0.07) {
                pricePerMessage = 0.075;
            } else if (calcPrice <= 49999 * 0.07) {
                pricePerMessage = 0.07;
            } else if (calcPrice <= 99999 * 0.06) {
                pricePerMessage = 0.065;
            } else if (calcPrice <= 499999 * 0.06) {
                pricePerMessage = 0.06;
            } else if (calcPrice <= 999999 * 0.056) {
                pricePerMessage = 0.058;
            } else if (calcPrice > 999999 * 0.056) {
                pricePerMessage = 0.056;
            }

            messagesCount = parseInt(calcPrice / pricePerMessage);
            messagesCount = adjustMessagesCount(messagesCount);
        }




        setSelectedMessages(messagesCount);
        setCalculatedPrice(price.toFixed(2));
        setOneMessagePrice(pricePerMessage);
    };

    const handleNameInputChange = (e) => {
        setEnteredName(e.target.value);
    };

    useEffect(() => {
        const closePopup = () => $('.price-invoice-popup').fadeOut(500);

        const handleClickOutside = (event) => {
            if (!$(event.target).closest('.pop-content').length && !$(event.target).closest('.open-price-invoice-popup').length) {
                closePopup();
            }
        };

        $(document).on('click', handleClickOutside);
        $(document).on('click', '.close-pop', closePopup);

        $(document).on('click', '.copy-next-text', function () {
            const textToCopy = $(this).siblings('span').text();
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        });

        return () => {
            $(document).off('click', handleClickOutside);
            $(document).off('click', '.close-pop', closePopup);
            $(document).off('click', '.copy-next-text');
        };
    }, []);

    const downloadAsPDF = async () => {
        const input = document.getElementById('to-pdf-download-content');
        const inputParent = document.getElementById('parent-to-pdf-download-content');

        const originalStyle = input.style.cssText;
        const originalStyle2 = inputParent.style.cssText;

        const btns = input.querySelector('.btns');
        if (btns) btns.remove();

        inputParent.style.position = 'relative';
        inputParent.style.display = 'flex';

        input.style.scale = '1';
        input.style.overflow = 'scroll';
        input.style.height = '100%';
        input.style.top = '0';
        input.style.inset = '0';
        input.style.transform = 'none';
        input.style.position = 'relative';

        html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: true,
            width: 550,
            height: 1500,
            windowWidth: 550,
            windowHeight: 1500
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF({
                unit: 'px',
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width, canvas.height);
            pdf.save("Madar-invoice.pdf");
            input.style.cssText = originalStyle;
            inputParent.style.cssText = originalStyle2;
            if (btns) input.append(btns);
        });
    };


    return (
        <>
            <section className='en prices-calc'>
                <img className='ecp-image' src={Ecp} alt='prices eclepse' />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <div className='calculater'>
                                <div className='header'>
                                    <img src={FrameDots} alt='frame dots'/>
                                    <h3> Total price </h3>
                                    <h1> <span> Riyal </span> {calculatedPrice}</h1>
                                </div>

                                <div className='calc-body'>
                                    <div>
                                        <span className={selectedMessages ? 'selected' : 'not-selected'}>
                                        Message {selectedMessages}
                                        </span>
                                        <span> It will be shipped: </span>
                                    </div>

                                    <div>
                                        <span className={selectedPlan ? 'selected' : 'not-selected'}>
                                            {selectedPlan ? selectedPlan.name : 'Membership not specified'}
                                        </span>
                                        <span> Type of membership: </span>
                                    </div>
                                </div>

                                <button className='link-btn-color open-price-invoice-popup'> Print the price quote </button>
                            </div>
                        </div>

                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='info'>
                                <h1 className='section-headline'> 
                                Price calculator
                                </h1>
                                <p className='section-text'>
                                To calculate the messages you will receive for the amount, use the price calculator
                                (and you can print an official price quote)
                                </p>

                                <div className='inputs'>
                                    <select defaultValue={1} className='hide choose-plan' onChange={handlePlanChange}>
                                        <option value="1"> Bronze membership </option>
                                        <option value="2"> Silver membership </option>
                                        <option value="3"> Gold membership </option>
                                        <option value="4"> Diamond membership </option>
                                    </select>

                                    <input 
                                        placeholder='The amount here ...' 
                                        type='number' 
                                        required 
                                        className='choose-price-to-pay' 
                                        value={enteredPrice}
                                        onChange={handlePriceInputChange}
                                    />
                                </div>

                                <div className='inputs another'>
                                

                                    <input 
                                        placeholder='Please enter the name of the invoice holder ...' 
                                        type='text' 
                                        required 
                                        className='client-name' 
                                        value={enteredName}
                                        onChange={handleNameInputChange}
                                    />
                                </div>


                                <h4> You can specify the quantities here to know the price: </h4>

                            
                                <div className='messages-number-options'>
                                    {[
                                        { label: "from 1 to 2999", value: "2999" },
                                        { label: "from 3000 to 4999", value: "4999" },
                                        { label: "from 5000 to 9999", value: "9999" },
                                        { label: "from 10000 to 19999", value: "19999" },
                                        { label: "from 20000 to 49999", value: "49999" },
                                        { label: "from 50000 to 99999", value: "99999" },
                                        { label: "from 100000 to 499999", value: "499999" },
                                    ].map((option) => (
                                        <button
                                            key={option.value}
                                            className={`option ${selectedMessages === parseInt(option.value) ? 'active' : ''}`}
                                            data-value={option.value}
                                            onClick={handleMessagesChange}
                                        >
                                            {option.label}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>










            <section id="parent-to-pdf-download-content" className='price-invoice-popup en'>
                <div id="to-pdf-download-content" className='pop-content en'>
                    <div className='btns'>
                        <button className='close-pop'>
                            <i className="las la-times"></i>
                        </button>

                        <button className='download-as-pdf' onClick={downloadAsPDF}>
                            <i className="las la-download"></i>
                        </button>
                    </div>

                    <img src={EcImg} alt='ecp image' />
                    
                    <div className='invoice-header'>
                        <div>
                            <h2> 
                            Invoice NO : 1
                            </h2>
                            <h3> DATE:  <span className='date-here'>{currentDate}  </span> </h3>
                        </div>

                        <img  src={Logo} alt='logo'/>
                    </div>

                    <span className='line'> </span>

                    <div className='invoice-info'>
                        <h4> <span> {enteredName} </span> The invoice to: </h4>
                        <p>
                        Peace, mercy, and blessings of Allah be upon you .. After that, we are pleased to present the following mobile messaging offer
                        </p>

                    <table className='table-1'>
                        <tr>
                            <th><h2>Quantity</h2></th>
                            <th><h2> Value of the message </h2></th>
                            <th><h2> Price of the quantity </h2></th>
                            <th><h2> Tax 15% </h2></th>
                            <th><h2> Total </h2></th>
                        </tr>
                        <tr>
                            <td><h3> {selectedMessages ? selectedMessages : 0} </h3></td>
                            <td><h3> {oneMessagePrice ? oneMessagePrice : 0} Riyal </h3></td>
                            <td><h3> {calculatedPrice ? calculatedPrice : 0} Riyal </h3></td>
                            <td><h3>{vatPrice} Riyal</h3></td>
                            <td><h3>  {(parseFloat(calculatedPrice).toFixed(2))} Reyal </h3></td>
                        </tr>
                        <tr>
                            <th colSpan={5}><h2>The offer is valid for five days from its date.</h2></th>
                        </tr>
                    </table>

                    <p> Best regards </p>
                </div>

                    <span className='line'> </span>

                    <div className='company-info'>
                        <div>
                            <h1> T / 920006900 </h1>
                        </div>
                        <img  src={InvoiceImg} alt='invoice image madar'/>
                    </div>

                    <span className='line'> </span>

                    <table className='table-2'>
                        <tr>
                            <th><h2> Bank name </h2></th>
                            <th><h2> Account number </h2></th>
                            <th><h2> IBAN </h2></th>
                        </tr>
                        <tr>
                            <td><h3> Al Rajhi Bank </h3></td>
                            <td>
                                <h3>
                                    <span> 552608010043349 </span>
                                    <button className='copy-next-text'><i className="las la-copy"></i></button>
                                </h3>
                            </td>
                            <td>
                                <h3>
                                    <span> SA3380000552608010043349 </span>
                                    <button className='copy-next-text'><i className="las la-copy"></i></button>
                                </h3>
                            </td>
                        </tr>
                        
                    </table>

                <div className='contact-info'>
                    <div className='qr-code'>
                        <h5> For payment </h5>
                        <img src={QRcodeImg} alt='qr code'/>
                    </div>

                    <div className='info'>
                        <h1> Contact Information </h1>
                        <ul>
                            <li>
                                <img src={LocationIcon} alt='location icon' />
                                Medina, King Fahd District - P.O. Box 40629, Postal Code 41511                            </li>
                            <li>
                                <img src={CallIocn} alt='call icon' />
                                T / 920006900
                            </li>
                            <li>
                                <img src={EmailIocn} alt='email icon' />
                                info@ot.com.sa
                            </li>
                        </ul>
                    </div>
                </div>
                    
                </div>
            </section>



        </>
    );
}

export default PricesCalc;
