import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import './css/numbers.css';
import ParallexImg1 from './images/paralex/1.png';
import axios from '../config/index';
import { useEffect } from 'react';

function Numbers() {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.3, // Adjust this value as needed
    });


    
    
    const [itemData, setitemData] = useState([]);

    const getItemData = (itemId) => {
        const table = "numbers";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData(1);
    }, []);


    return (
        <>
            <Parallax
                bgImage={ParallexImg1}
                strength={500}
                bgImageStyle={{ minHeight: '100vh' }}
            >
                <div className='parallex-element'>
                    <div className='overlay'></div>
                    <h2> {itemData.sec_header} </h2>
                    <h1> {itemData.header} </h1>
                    <p>
                    {itemData.para}
                    </p>
                </div>
            </Parallax>
            <section className='numbers' ref={ref}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='number'>
                                <h1>
                                    {inView && <CountUp end={parseInt(itemData.num_1)} duration={3} suffix="/7" />}
                                </h1>
                                <p> {itemData.num_1_para}  </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='number'>
                                <h1>
                                    {inView && <CountUp end={parseInt(itemData.num_2)} duration={3} suffix="+" />}
                                </h1>
                                <p> {itemData.num_2_para}</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                            <div className='number'>
                                <h1>
                                    {inView && <CountUp end={parseInt(itemData.num_3)} duration={3} suffix="+" />}
                                </h1>
                                <p> {itemData.num_3_para}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Numbers;
