import React, { useState } from 'react';
import './css/plans.css';
import { Link as RouterLink } from 'react-router-dom';

import PlanImg1 from './images/plans/1.svg';
import PlanImg2 from './images/plans/2.svg';
import PlanImg3 from './images/plans/3.svg';
import PlanImg4 from './images/plans/4.svg';


import axios from '../config/index';
import { useEffect } from 'react';
const BoldFirstThreeWords = ({ text }) => {
    // Check if text exists and has at least three words
    const words = text ? text.split(' ') : [];

    // Extract the first three words and the rest of the text
    const firstThreeWords = words.slice(0, 3).join(' ');
    const restOfTheText = words.slice(3).join(' ');

    return (
    <p>
        <strong>{firstThreeWords}</strong> {restOfTheText}
    </p>
    );
};
function Plans() {
    const [activeTab, setActiveTab] = useState('sms'); // 'sms' or 'fingerprint'

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    
    
    const [itemData, setitemData] = useState([]);

    const getItemData = () => {
        const table = "prices_sms";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData();
    }, []);


    
    
    const [itemData2, setitemData2] = useState([]);

    const getItemData2 = () => {
        const table = "prices_devices";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData2();
    }, []);


    return (
        <>
            <section className='plans'>
                <div className='container'>
                    <div className='plans-header'>
                        <div>
                            <button
                                className={activeTab === 'fingerprint' ? 'link-btn-color' : 'link-btn-midcolor'}
                                onClick={() => handleTabClick('fingerprint')}
                            >
                                اجهزة البصمة
                            </button>
                            <button
                                className={activeTab === 'sms' ? 'link-btn-color' : 'link-btn-midcolor'}
                                onClick={() => handleTabClick('sms')}
                            >
                                خدمة SMS
                            </button>
                        </div>
                        <h1 className={activeTab === 'sms' ? 'section-headline' : 'hide section-headline'}>
                            عضويات خدمات SMS
                        </h1>
                        <h1 className={activeTab === 'fingerprint' ? 'section-headline' : 'hide section-headline'}>
                            باقات أجهزة البصمة
                        </h1>
                    </div>

                    <div className={activeTab === 'sms' ? 'all-plans' : 'hide all-plans'}>
                    {itemData.length > 0 ? (
                    <>
                        {itemData.map((plan) => (
                        <div className='plan' key={plan.id}>
                            <img src={`./uploads/${plan.img_1}`} alt={`Image of ${plan.header}`} />
                            <h2>{plan.header}</h2>
                            <h1><span>رسالة</span> {plan.number}</h1>
                            <BoldFirstThreeWords text={plan.para} />
                            </div>
                        ))}
                    </>
                    ) : null}
                        

                    
                    </div>

                    <div className={`finger-print ${activeTab === 'fingerprint' ? '' : 'hide'}`}>
                        <div className='row'>


                        {itemData2.length > 0 ? (
                            <>
                                {itemData2.map((plan) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                        <div className='plan'>
                                            <div className='top'>
                                                <img src={`./uploads/${plan.img_1}`} alt='plan image' />
                                                <h1> {plan.header} <span> باقة </span> </h1>
                                                <h2> <span> / للفصل الدراسي </span> {plan.price_season} ريال </h2>
                                                <h2> <span> / للسنة </span> {plan.price_year} ريال </h2>
                                                <h3> {plan.devices_num} </h3>
                                            </div>
                                            
                                        </div>
                                    </div>
                                ))}
                            </>
                    ) : null}


                        </div>
                    </div>
                </div>
                <RouterLink to="/prices-and-banks" className='link-btn-color'> عرض كل الأسعار </RouterLink>
            </section>
        </>
    );
}

export default Plans;
