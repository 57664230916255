import '../css/bankspopup.css';
import React, { useEffect } from 'react';
import '../css/popup.css';
import $ from 'jquery';

import downLayImg from '../images/popup/downlayimage.png';
import SaudiArabiaFlag from '../images/popup/flag.png';

function BanksPopup() {
    useEffect(() => {
        // Close popup when clicking outside of pop-content
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.pop-content').length && !$(event.target).closest('.open-bank-popup').length) {
                closePopup();
            }
        });

        // Close popup when clicking the close-pop button
        $('.close-pop').on('click', function() {
            closePopup();
        });

        // Copy text functionality
        $('.copy-next-text').on('click', function() {
            const textToCopy = $(this).siblings('span').text();
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        });

        // Close popup function with animation
        function closePopup() {
            $('.banks-pop-up').fadeOut(500); // Adjust the animation duration as needed
        }

        // Cleanup event listeners on component unmount
        return () => {
            $(document).off('click');
            $('.close-pop').off('click');
            $('.copy-next-text').off('click');
        };
    }, []);

    return (
        <>
            <section className='en banks-pop-up'>
                <div className='pop-content'>
                    <div className='header'>
                        <button className='close-pop'> 
                            <i className="las la-times"></i>
                        </button>
                        <h1 className='section-headline'> Our bank accounts </h1>
                    </div>

                    <div className='table-scroller'>
                    <table>
                        <tr>
                            <th><h2> The name of the bank </h2></th>
                            <th><h2> Account number </h2></th>
                            <th><h2> IBAN </h2></th>
                        </tr>
                        <tr>
                            <td><h3> Al Rajhi Bank </h3></td>
                            <td>
                                <h3>
                                    <span> 552608010043349 </span>
                                    <button className='copy-next-text'><i className="las la-copy"></i></button>
                                </h3>
                            </td>
                            <td>
                                <h3>
                                    <span> SA3380000552608010043349 </span>
                                    <button className='copy-next-text'><i className="las la-copy"></i></button>
                                </h3>
                            </td>
                            
                            
                        </tr>
                    
                    </table>
                    </div>

                    <div className='down-lay'>
                        <img src={downLayImg} alt='down-lay-image' />
                        <div className='overlay'></div>
                        <div className='text'>
                            <div>
                            <h3>We accept deposits through money transfers to the above bank accounts in the name of:</h3>
                            <h1>Al Madar Al Taqani for Information Technology</h1>

                            </div>
                            <img src={SaudiArabiaFlag} alt='saudi-arabia flag' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BanksPopup;
