import '../css/appliedcourses.css';
import NoResultImg from '../images/noresult.gif';
import { Link as RouterLink } from 'react-router-dom';


function AppliedCourses(){
    return(
        <>
            <section className='en applied-courses'>
                <div className='container'>
                    <div className='screens-btns'>
                        {/* <button className='screen-btn active'>
                            خدمة SMS
                        </button>

                        <button className='screen-btn'>
                            استخدام نظام نور    
                        </button>

                        <button className='screen-btn'>
                            استخدام اجهزة البصمة    
                        </button> */}
                    </div>

                    <div className='screens'>
                        <div className='screen active'>
                            <RouterLink to="/en/all-courses">   
                                Do You want to start ? 
                            </RouterLink>

                            <h2> 
                            You have not registered in any of the tutorials yet?
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                        <div className='screen'>
                        <RouterLink to="/en/all-courses">   
                                Do You want to start ? 
                            </RouterLink>

                            <h2> 
                            You have not registered in any of the tutorials yet?
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                        <div className='screen'>
                        <RouterLink to="/en/all-courses">   
                                Do You want to start ? 
                            </RouterLink>

                            <h2> 
                            You have not registered in any of the tutorials yet?
                            </h2>
                            <img src={NoResultImg} alt='search icon'/>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default AppliedCourses;