import './css/footer.css';
import { Link as RouterLink } from 'react-router-dom';

import Logo from './images/logo.svg';

import FacebookIcon from './images/social/facebook.svg';
import TwitterIcon from './images/social/twitter.svg';
import InstaIcon from './images/social/insta.svg';
import YoutubeIcon from './images/social/youtube.svg';
import TelegramIcon from './images/social/telegram.svg';
import LiknedinIcon from './images/social/linkedin.svg';

import axios from '../config/index';
import React, { useState, useEffect } from 'react';


function Footer(){
    const [socails, setsocails] = useState([]);

    useEffect(() => {
        const getAllSocials = (itemId) => {
            const table = "socials";
            axios.post('/get-item', { itemId, table }).then((res) => {
                if (!res.data.message) {
                    setsocails(res.data);
                }
            }).catch((err) => console.log(err));
        };
        getAllSocials(1);
    }, []);

    return(
        <>
            <footer>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> اشترك في النشرة الاخبارية </h2>

                                <form>
                                    <input type='email' required placeholder='البريد الإلكتروني' />
                                    <button className='link-btn-color' type='submit'> إرسال </button>
                                </form>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> روابط اخرى  </h2>

                                <ul>
                                    <li>
                                        <RouterLink to="/faqs">
                                        الاسئلة الشائعة
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/careers">
                                        التوظيف
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/terms-conditions">
                                        الشروط و الاحكام
                                        </RouterLink>
                                    </li>

                                    

                                    <li>
                                        <RouterLink to="/policy-privacy">
                                        السياسات و الخصوصية
                                        </RouterLink>
                                    </li>


                                    
                                </ul>
                            </div>
                        </div>




                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <h2 className='section-sec-headline'> عن الموقع </h2>

                                <ul>
                                    <li>
                                        <RouterLink to="/">
                                        الرئيسية
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/how-to-use">
                                        كيفية الاستخدام
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/about-us">
                                        من نحن
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/our-clients">
                                        عملائنا
                                        </RouterLink>
                                    </li>

                                    <li>
                                        <RouterLink to="/our-services">
                                        خدماتُنا
                                        </RouterLink>
                                    </li>


                                    <li>
                                        <RouterLink to="/prices-and-banks">
                                        الاسعار
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>



                        
                        <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                            <div className='footer-content'>
                                <div className='logo'>
                                    <img  src={Logo}  alt='logo'/>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </footer>


            <div className='copy-right'>
                <div className='container'>
                    <div className='copy-right-content'>
                        <div className='left'>
                            <h3>جميع الحقوق محفوظة 2023 © مؤسسة المدار   </h3>

                            <div className='socials'>
                                <ul>
                                    <li>
                                        <a href={socails.facebook} target='_blank' aria-label='social link'>
                                            <img src={FacebookIcon}  alt='social icon'/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={socails.twitter} target='_blank' aria-label='social link'>
                                        <img src={TwitterIcon}  alt='social icon'/>

                                        </a>
                                    </li>

                                    <li>
                                        <a href={socails.instagram} target='_blank' aria-label='social link'>
                                        <img src={InstaIcon}  alt='social icon'/>

                                        </a>
                                    </li>


                                    <li>
                                        <a href={socails.youtube} target='_blank' aria-label='social link'>
                                        <img src={YoutubeIcon}  alt='social icon'/>

                                        </a>
                                    </li>


                                    <li>
                                        <a href={socails.linkedin} target='_blank' aria-label='social link'>
                                        <img src={LiknedinIcon}  alt='social icon'/>

                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;