import './css/userloginpages.css';
import ObjectsImage from './images/devices/objects.png';
import Logo from './images/logosm.svg';
import { useEffect } from 'react';

import { Router, Link as RouterLink } from 'react-router-dom';

import EyeIcon from './images/eye.svg';

import { useState  } from 'react';

import GoogleIcon from './images/icons/google.svg';
import $ from 'jquery';


function PasswordResetEmail(){

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };


    useEffect(() => {
        $(document).on("click", ".change-to-darkmode", function() {
            $("section, nav, footer,body,div,h1,p,h2").addClass("dark-mode");
            $(this).removeClass("change-to-darkmode");
            $(this).addClass("change-to-lightmode");
        });

        $(document).on("click", ".change-to-lightmode", function() {
            $("section, nav, footer,body,div,h1,p,h2,h3").removeClass("dark-mode");
            $(this).removeClass("change-to-lightmode");
            $(this).addClass("change-to-darkmode");
        });
        if($("nav .left>a:nth-child(2)").hasClass("change-to-lightmode") || $("body").hasClass("dark-mode")){
            $("nav .left>a:nth-child(2)").removeClass("change-to-darkmode");
            $("nav .left>a:nth-child(2)").addClass("change-to-lightmode");

            $("section, nav, footer,body,div,h1,p,h2,h3").addClass("dark-mode");
        }
    }, []);


    return(
        <>
            <section className='user-login-pages'>
                <div className='left'>
                    <img src={ObjectsImage} alt='objects'/>
                    <div className='container'>
                        <div className='user-side-page'>
                            <div className='content'>
                                <img src={Logo} alt='logo'/>
                                <h2 className='section-sec-headline'> مرحباً بكم في نظام المــــدار التقني  </h2>

                                <h1 className='section-headline'>  التقنية أسرع من الضوء </h1>


                                 <div>
                                    <RouterLink target='_blank' to="/contact-us">
                                    إذا واجهت اي مشكلة تواصل معنا
                                    </RouterLink>

                                    <RouterLink to="/" className='link-btn-midcolor'>
                                    الرجوع إلى الرئيسية
                                    </RouterLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='right'>
                    <div className='container'>
                        <div className='content'>
                            <div className='form-element'>
                                <div className='header'>
                                        <a></a>
                                    <div> <h1> إعادة تعيين كلمة المرور </h1>
                                    <h4> <RouterLink to="/login"> تسجيل الدخول ؟</RouterLink> الرجوع ل</h4></div>
                                </div>


                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                            البريد الالكتروني
                                            </label>

                                        
                                            <input placeholder='example@gmail.com' type="email" required name='email' />
                                        </div>
                                    </div>

                                    <button className='link-btn-color'>  تأكيد </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PasswordResetEmail;