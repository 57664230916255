import './css/landing.css';

import landingImg from './images/landing.png';
import { Link as RouterLink } from 'react-router-dom';

import ShareIcon from './images/icons/share.svg';
import MessageIcon from './images/icons/messagecolored.svg';
import SendIcon from './images/icons/sendcolored.svg';
import MobMessageIcon from './images/icons/mobmessagecolored.svg';

import axios from '../config/index';
import { useState, useEffect } from 'react';



function Landing(){

    const [landing, setLanding] = useState([]);

    const getLanding = (itemId) => {
        const table = "landing";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setLanding(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getLanding(1);
    }, []);


    return(
        <>
            <section className='landing'>
                <img src={landingImg} alt='background image' />
                <div className='container'>
                    <div className='landing-text'>

                        <img src={MessageIcon}alt=' message icon' />
                        <img src={SendIcon}alt=' Send icon' />
                        <img src={MobMessageIcon}alt=' MobMessage icon' />

                        <h1>
                        <span>    {landing.header_color}  </span> {landing.header}
                        </h1>

                        <div className='links'>

                            <a className='link-btn-nocolor' target='_blank' href='https://invite.mobile.sa/filemessagetoken/eFZqYUhWMTl6QUdEY1JZd0Jsd2E3bm5pSEdjaVdIRWFRdU5aZnJhVHYvUVlzZlVJTlR4UmJQY0Q0NU1FK2FFVVlIZEJsQkhZOEI3QmwyY3ZXL2x1UkQySm5TWT0%3D'>
                                <img src={ShareIcon} alt='share icon' />
                                ضوابط الهيئة للرسائل
                            </a>

                            <RouterLink className='link-btn-color' to='/contact-us'>
                                لديك استفسار ؟
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Landing;