import '../css/pricestext.css';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';

import PricesTextImg from '../images/prices/1.svg';
import Dots from '../images/prices/dots.svg';
import $ from 'jquery';

import axios from '../../config/index';
import { useState } from 'react';


function PricesText(){

    
    
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "prices_text";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);





    useEffect(() => {

        // Close popup when clicking the close-pop button
        $('.open-bank-popup').on('click', function() {
            openPopUp();
        });

        function openPopUp() {
            $('.banks-pop-up').fadeIn(500); // Adjust the animation duration as needed
        }


        // Cleanup event listeners on component unmount
        return () => {
            $('.open-bank-popup').off('click');
        };
    }, []);


    return(
        <>
            <section className='en prices-text'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'>
                                {itemData1.header_en}
                                </h1>

                                <p className='section-text'>
                                {itemData1.para_en}
                                </p>

                                <ul>
                                    <li>
                                        1.
                                        Convert the value of the messages you want to purchase to one of 
                                        <button className="open-bank-popup">
                                        Bank accounts
                                        </button>
                                    </li>

                                    <li>
                                        2.
                                    Log in to your account on our website, click on the "Request Shipping" button, and fill out the form, or contact technical support for assistance .
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img className='section-image' src={`../uploads/${itemData1.img_1}`} alt="prices text image"/>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}


export default PricesText;