import './css/aboutpagetext.css';
import AboutVideo from './videos/about.mp4';
import AboutPageImg from './images/aboutpage.png';

import PlayIcon from './images/icons/Play.svg';
import axios from '../config/index';
import { useEffect, useState } from 'react';

function AboutPageText(){

    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "about_section_header";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData2 = (itemId) => {
        const table = "about_text";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {
        getItemData1(1);
        getItemData2(1);
    }, []);

    return(
        <>
            <section className='about-page-text'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='about-video'>
                            <img src={`./uploads/${itemData1.img_1}`} alt='about page image' />

                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img src={AboutPageImg} alt='about page image' />
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'>
                                {itemData2.header}
                                </h1>

                                <p className='section-text'>
                                    {itemData2.para}
                                    
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}


export default AboutPageText;