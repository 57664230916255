import '../css/availabledevices.css';
import {Swiper,SwiperSlide} from 'swiper/react';
import React, { useEffect } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';
import $ from 'jquery';

import PlanImg1 from '../images/prices/memberships-1.svg';
import PlanImg2 from '../images/prices/memberships-2.svg';
import PlanImg3 from '../images/prices/memberships-3.svg';


import DeviceImg1 from '../images/devices/1.png';
import DeviceImg2 from '../images/devices/2.png';
import DeviceImg3 from '../images/devices/3.png';
import DeviceImg4 from '../images/devices/4.png';
import DeviceImg5 from '../images/devices/5.png';

import ObjectsDots from '../images/devices/objects.png';
import DevicePopup from '../DevicePopup';

import axios from '../../config/index';
import { useState } from 'react';


function AvailableDevices(){
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);
    const [itemData3, setitemData3] = useState([]);
    const [itemData4, setitemData4] = useState([]);
    const [itemData5, setitemData5] = useState([]);
    const [itemData6, setitemData6] = useState([]);

    const [desc1, setDesc1] = useState([]);
    const [desc2, setDesc2] = useState([]);
    const [desc3, setDesc3] = useState([]);
    const [desc4, setDesc4] = useState([]);
    const [desc5, setDesc5] = useState([]);
    const [desc6, setDesc6] = useState([]);

    
    useEffect(() => {

        // Close popup when clicking the close-pop button
        $('.show-price').on('click', function() {
            $(this).parent().find(".device-pop-up").fadeIn(500);
        });


        // Cleanup event listeners on component unmount
        return () => {
            $('.show-price').off('click');
        };
    }, [itemData1,itemData2,itemData3,itemData4,itemData5,itemData6]);



    
    


    const getItemData1 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc1(descarray);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData2 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc2(descarray);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData3 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData3(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc3(descarray);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData4 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData4(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc4(descarray);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData5 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData5(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc5(descarray);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData6 = (itemId) => {
        const table = "finger_print_devices";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData6(res.data);
                let descarray = res.data.description_en.split(",");
                setDesc6(descarray);
            }
        }).catch((err) => console.log(err));
    };







    useEffect(() => {
        getItemData1(1);
        getItemData2(2);
        getItemData3(3);
        getItemData4(4);
        getItemData5(5);
        getItemData6(6);

    }, []);



    return(
        <>
            <section className='en available-devices'>
                <div className='container'>
                    <div className='header'>
                        <h1 className='section-headline'>The available devices available</h1>
                    </div>

                    <div className='devices-slider'>
                        <div className='back-slider'>
                            <img src={ObjectsDots} alt='objects' />
                        </div>
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                            nextEl: '.devices-slider .next-btn',
                            prevEl: '.devices-slider .prev-btn',
                            }} pagination={true}
                            
                                modules={[Navigation,Pagination]}
                        >
                                                        {itemData1.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                        <img src={`../uploads/${itemData1.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                        <h2 className='section-sec-headline'>
                                        {itemData1.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc1.length > 0 ? (
                                                desc1.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>

                                        <DevicePopup name={itemData1.header_en} price={itemData1.price_en} beforeprice="" 
                                        check1={itemData1.finger == "نعم" ? "1" : "0"} check2={itemData1.wifi == "لا" ? "1" : "0"} check3={itemData1.battery == "لا" ? "1" : "0"} check4={itemData1.addfee == "لا" ? "1" : "0"} />

                                        <button className='show-price link-btn-color' >
                                        The device's price quote
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :null}




                            
                            {itemData6.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                    <img src={`./uploads/${itemData6.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                    <h2 className='section-sec-headline'>
                                        {itemData6.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc6.length > 0 ? (
                                                desc6.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>


                                    
                                        <DevicePopup name={itemData6.header_en} price={itemData6.price} beforeprice="" 
                                        check1={itemData6.finger == "نعم" ? "1" : "0"} check2={itemData6.wifi == "لا" ? "1" : "0"} check3={itemData6.battery == "لا" ? "1" : "0"} check4={itemData6.addfee == "لا" ? "1" : "0"} />



                                        <button className='show-price link-btn-color' >
                                        The device's price quote
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>

                            :null}




{itemData2.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                    <img src={`../uploads/${itemData2.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                    <h2 className='section-sec-headline'>
                                        {itemData2.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc2.length > 0 ? (
                                                desc2.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>


                                    
                                        <DevicePopup name={itemData2.header_en} price={itemData2.price_en} beforeprice="" 
                                        check1={itemData2.finger == "نعم" ? "1" : "0"} check2={itemData2.wifi == "لا" ? "1" : "0"} check3={itemData2.battery == "لا" ? "1" : "0"} check4={itemData2.addfee == "لا" ? "1" : "0"} />



                                        <button className='show-price link-btn-color' >
                                        The device's price quote
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :null}



{itemData3.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                    <img src={`../uploads/${itemData3.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                    <h2 className='section-sec-headline'>
                                        {itemData3.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc3.length > 0 ? (
                                                desc3.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>

                                        <DevicePopup name={itemData3.header_en} price={itemData3.price_en} beforeprice="" 
                                        check1={itemData3.finger == "نعم" ? "1" : "0"} check2={itemData3.wifi == "لا" ? "1" : "0"} check3={itemData3.battery == "لا" ? "1" : "0"} check4={itemData3.addfee == "لا" ? "1" : "0"} />


                                        <button className='show-price link-btn-color' >
                                        The device's price quote
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :null}



{itemData4.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                    <img src={`../uploads/${itemData4.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                    <h2 className='section-sec-headline'>
                                        {itemData4.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc4.length > 0 ? (
                                                desc4.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>

                                        <span className='line'> </span>

                                        <h3> The card price does not include shipping  {itemData4.price_en} Reyal </h3>
                                        <h4> shipping:  {itemData4.shipping_en} Reyal </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :null}


                            
{itemData5.status == "active" ? 

                            <SwiperSlide>
                                <div className='device'>
                                    <div className='image'>
                                    <img src={`../uploads/${itemData5.img_1}`} alt='device image'/>
                                    </div>
                                    
                                    <div className='text'>
                                    <h2 className='section-sec-headline'>
                                        {itemData5.header_en}
                                        </h2>

                                        <ul className='side-side'>
                                            {desc5.length > 0 ? (
                                                desc5.map((desc)=>{
                                                    return(
                                                        <>
                                                        <li>
                                                            {desc}
                                                        </li>
                                                        </>
                                                    )
                                                })
                                            ):null}

                                        </ul>

                                        <span className='line'> </span>

                                        <h3>The price includes shipping : {itemData5.price_en} Reyal</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                            :null}



                        </Swiper>

                        <div className='slider-btns'>
                            <button className='prev-btn'>
                                <i className='las la-angle-left'></i>
                            </button>
                            <button className='next-btn'>
                                <i className='las la-angle-right'></i>
                            </button>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AvailableDevices;