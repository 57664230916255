import '../css/navbar.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserIcon from '../images/icons/user.svg';
import SearchIcon from '../images/icons/search.svg';
import Logo from '../images/logo.svg';
import $ from 'jquery';
import { useEffect } from 'react';

function Navbar() {
    const { pathname } = useLocation();

    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
            });
        }

        const loadingElement = document.getElementById("loading");
        if (loadingElement) {
            loadingElement.remove();
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        $(document).on("click", ".change-to-darkmode", function() {
            $("section, nav, footer, body, div, h1, p, h2").addClass("dark-mode");
            $(this).removeClass("change-to-darkmode");
            $(this).addClass("change-to-lightmode");
        });

        $(document).on("click", ".change-to-lightmode", function() {
            $("section, nav, footer, body, div, h1, p, h2, h3").removeClass("dark-mode");
            $(this).removeClass("change-to-lightmode");
            $(this).addClass("change-to-darkmode");
        });
        if($("nav .left>a:nth-child(2)").hasClass("change-to-lightmode") || $("body").hasClass("dark-mode")){
            $("nav .left>a:nth-child(2)").removeClass("change-to-darkmode");
            $("nav .left>a:nth-child(2)").addClass("change-to-lightmode");

            $("section, nav, footer, body, div, h1, p, h2, h3").addClass("dark-mode");
        }
    }, [pathname]);

    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => {
        return currentPath === path ? 'active' : '';
    };

    return (
        <>
            <nav className='en'>
                <div className='container'>
                    <div className='nav-content'>
                        <div className='left'>
                            <a href="https://app.mobile.net.sa/login" target='_blank' aria-label='to login user'>
                                <img src={UserIcon} alt='user icon' />
                            </a>

                            <a href="https://mobile.net.sa/sms/Login.php" target='_blank' aria-label='to login user'>
                            <i class="las la-user"></i>
                            </a>

                            <RouterLink className='change-to-darkmode'>
                                <i className="las la-moon"></i>
                            </RouterLink>

                            <RouterLink to="/" aria-label='language'>
                                العربية
                            </RouterLink>
                        </div>
                        <div className='right'>
                            <button id="open-nav"> <i className="las la-bars"></i> </button>
                            <div className="nav-elements">
                                <button id="close-nav"> <i className="las la-times"></i> </button>
                                <ul>
                                    <li>
                                        <RouterLink className={isActive('/en/prices-and-banks')} to='/en/prices-and-banks'>
                                            Prices
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink className={isActive('/en/how-to-use')} to='/en/how-to-use'>
                                            How to Use
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink className={isActive('/en/our-services')} to='/en/our-services'>
                                            Services
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink className={isActive('/en/about-us')} to='/en/about-us'>
                                            About Us
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink className={isActive('/en/')} to='/en/'>
                                            Home
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>

                            <div className='logo'>
                                <RouterLink to='/en/' aria-label='to main page'>
                                    <img src={Logo} alt='logo' />
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
