import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function ManageSmsServicesText() {


    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const isEditMode = location.pathname.includes("edit-sms-services-text");
    const isAllBlogsPath = location.pathname.includes("all-sms-services-text");

    const [formInputs, setFormInputs] = useState({
        header: "",
        para: "",
        header_en: "",
        para_en: "",
        images: null,
    });

    const [formChanged, setFormChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [AllItems, setAllItems] = useState([]);

    useEffect(() => {
        if (isEditMode) {
            getIfLogin();
        }
        if (isAllBlogsPath) {
            getAllItems();
        }
    }, []);

    useEffect(() => {
        if (isEditMode && id) {
            getItemData(id);
        }
    }, [id, isEditMode]);

    const handleInputChange = (e) => {
        setFormChanged(true);
        const { name, value, type } = e.target;
        const inputValue = type === "file" ? e.target.files[0] : value;

        setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = new FormData();
        if (isEditMode) {
            data.append("id", id);
        }
        Object.keys(formInputs).forEach((key) => {
            if (formInputs[key] !== null) {
                data.append(key, formInputs[key]);
            }
        });

        data.append("tableName", "sms_services_text");

        try {
            const response = await axios.post(isEditMode ? "/edit-item" : "/add-item", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

          setLoading(false);
            setSuccessMessage(isEditMode ? "تم التعديل بنجاح" : "تمت الإضافة بنجاح");
            setTimeout(() => setSuccessMessage(""), 3000);
            window.location.reload()
        } catch (error) {
            setLoading(false);
            setErrorMessage("حدث خطأ في العملية");
            setTimeout(() => setErrorMessage(""), 3000);
        }
    };

    const getIfLogin = () => {
        axios.get('/admin-session')
            .then((res) => {
                if (!res.data.validadmin || !id) {
                    navigate('/admin-login');
                } else {
                    getItemData(id);
                }
            })
            .catch((err) => console.log(err));
    };

    const getItemData = (itemId) => {
        axios.post('get-item', { itemId, table: "sms_services_text" })
            .then((res) => {
                if (res.data) {
                    setFormInputs(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getAllItems = () => {
        axios.post('AllItems', { table: 'sms_services_text' })
            .then((res) => {
                if (res.data) {
                    setAllItems(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    };

    const deleteItem = (id) => {
        axios.delete(`delete-item/${id}`, { data: { table: 'sms_services_text' } })
            .then((response) => {
                if (response.data.message === 'Item deleted successfully') {
                    getAllItems();
                } else {
                    console.error('Failed to delete result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error deleting result:', error);
            });
    };

    return (
        <>
            <div className='loading-screen' style={{ display: loading ? 'block' : 'none' }}>
                <div className="loader">
                    <div className="outer"></div>
                    <div className="middle"></div>
                    <div className="inner"></div>
                </div>
            </div>
            <section className='dashboard'>
                {isAllBlogsPath ? (
                    <div className='results-table'>
                        <table>
                            <thead>
                                <tr>
                                    <th> # </th>
                                    <th> العنوان </th>
                                    <th> الإختيارات </th>
                                </tr>
                            </thead>
                            <tbody>
                                {AllItems.length ? (
                                    AllItems.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td><h6>{item.id}</h6></td>
                                                <td><h6>{item.header}</h6></td>

                                                <td><Link className='edit' to={`/edit-sms-services-text?id=${item.id}`}> <i className="las la-edit"></i> </Link></td>
                                                
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={5}>Loading...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className='edit-form'>
                        <div className='alert-success' style={{ display: successMessage ? 'block' : 'none' }}>
                            <h1>{successMessage} <i className="las la-check-circle"></i></h1>
                        </div>
                        <div className='alert-danger' style={{ display: errorMessage ? 'block' : 'none' }}>
                            <h1>{errorMessage} <i className="las la-exclamation-triangle"></i></h1>
                        </div>
                        <form onSubmit={handleSubmit}>
                        

                            <div className='input'>
                                <label> العنوان </label>
                                <input value={formInputs.header} onChange={handleInputChange} type='text' required maxLength="200" name='header' />
                            </div>

                            <div className='input'>
                                <label> النص </label>
                                <textarea value={formInputs.para} onChange={handleInputChange} required name='para'> 

                                </textarea>
                            </div>



                            <div className='input'>
                                <label> العنوان بالانجليزية</label>
                                <input value={formInputs.header_en} onChange={handleInputChange} type='text' required maxLength="200" name='header_en' />
                            </div>

                            <div className='input'>
                                <label> النص بالانجليزية</label>
                                <textarea value={formInputs.para_en} onChange={handleInputChange} required name='para_en'> 

                                </textarea>
                            </div>


                            <div className='input'>
                                <label> الصورة </label>
                                <input onChange={handleInputChange} type='file' name="images" />
                            </div>


                            <button type='submit' disabled={isEditMode && !formChanged}>{isEditMode ? "تحديث" : "إضافة"}</button>
                        </form>
                    </div>
                )}
            </section>
        </>
    );
}

export default ManageSmsServicesText;
