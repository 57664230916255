
function PricesInvoicePopup() {
   
    

    return (
        <>
           


        </>
    );
}

export default PricesInvoicePopup;
