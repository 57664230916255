import './css/devicepopup.css';
import React, { useEffect,useState, useRef } from 'react';
import './css/popup.css';
import $ from 'jquery';

import downLayImg from './images/popup/downlayimage.png';
import SaudiArabiaFlag from './images/popup/flag.png';

import TagImage from './images/tag.gif';

import RightMark from './images/rightmark.svg';
import WrongMark from './images/wrongmark.svg';
import PlanImg1 from './images/prices/memberships-1.svg';
import PlanImg2 from './images/prices/memberships-2.svg';
import PlanImg3 from './images/prices/memberships-3.svg';

import { Link as RouterLink } from 'react-router-dom';


import axios from '../config/index';

function DevicePopup(props) {

    
    
    const [itemData2, setitemData2] = useState([]);

    const getItemData2 = () => {
        const table = "prices_devices";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData2();
    }, []);



    const popupRef = useRef(null);
    const originalContainerRef = useRef(null);

    useEffect(() => {
        // Store the original container
        originalContainerRef.current = $(popupRef.current).parent();

        // Function to close popup
        const closePopup = () => {
            $(popupRef.current).fadeOut(500); // Adjust the animation duration as needed
        };

        // Append popup to body when it becomes visible
        const appendPopupToBody = () => {
            if (popupRef.current && $(popupRef.current).is(':visible')) {
                $('body').append(popupRef.current);
            } else if (popupRef.current && $(popupRef.current).is(':hidden')) {
                originalContainerRef.current.append(popupRef.current);
            }
        };

        // Monitor the visibility of the popup
        const observer = new MutationObserver(() => {
            appendPopupToBody();
        });

        if (popupRef.current) {
            observer.observe(popupRef.current, { attributes: true, attributeFilter: ['style'] });
        }

        // Event listeners
        $(document).on('click', (event) => {
            if (!$(event.target).closest('.pop-content').length && !$(event.target).closest('.show-price').length) {
                closePopup();
            }
        });

        $('.close-pop').on('click', () => {
            closePopup();
        });

        $('.copy-next-text').on('click', function() {
            const textToCopy = $(this).siblings('span').text();
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        });

        // Cleanup event listeners on component unmount
        return () => {
            $(document).off('click');
            $('.close-pop').off('click');
            $('.copy-next-text').off('click');
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <section className='device-pop-up' ref={popupRef}>
                <div className='pop-content'>
                    <div className='header'>
                        <button className='close-pop'>
                            <i className="las la-times"></i>
                        </button>
                        <h1 className='section-headline'>  {props.name}   </h1>
                    </div>

                    <div className='device-info'>
                        <h2> <span> سعر الجهاز </span> {props.price} ريال </h2>
                        <p>   {props.beforeprice}  شاملة الشحن والضريبة والبرمجة  </p>

                        <div className='info'>
                            <img src={TagImage} alt='tag image' />

                            <div>
                                <ul>
                                    <li>
                                        {props.check1 == 1 ? <><img src={RightMark} alt='right mark' /></> :
                                            <><img src={WrongMark} alt='wrong mark' /></>}
                                        بصمة الاصبع, بصمة الوجه, بطاقة, رقم سري
                                    </li>

                                    <li>
                                        {props.check2 == 1 ? <><img src={RightMark} alt='right mark' /></> :
                                            <><img src={WrongMark} alt='wrong mark' /></>}
                                        يوجد WiFi
                                    </li>

                                    <li>
                                        {props.check3 == 1 ? <><img src={RightMark} alt='right mark' /></> :
                                            <><img src={WrongMark} alt='wrong mark' /></>}
                                        يوجد بطارية
                                    </li>

                                    <li>
                                        {props.check4 == 1 ? <><img src={RightMark} alt='right mark' /></> :
                                            <><img src={WrongMark} alt='wrong mark' /></>}
                                        220 ريال عند إضافة بصمة المعلمين
                                    </li>
                                </ul>
                                <RouterLink className='link-btn-color' to="/contact-us"> تواصل معنا لشراء الجهاز </RouterLink>
                            </div>
                        </div>
                    </div>

                    <div className='pop-plans'>
                        <h3 className='section-headline'>  عروض اجهزة البصمة  </h3>
                        <div className='row'>
                        {itemData2.length > 0 ? (
                            <>
                                {itemData2.map((plan) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                        <div className='plan'>
                                            <div className='top'>
                                                <img src={`./uploads/${plan.img_1}`} alt='plan image' />
                                                <h1> {plan.header} <span> باقة </span> </h1>
                                                <h2> <span> / للفصل الدراسي </span> {plan.price_season} ريال </h2>
                                                <h2> <span> / للسنة </span> {plan.price_year} ريال </h2>
                                                <h3> {plan.devices_num} </h3>
                                            </div>
                                            
                                        </div>
                                    </div>
                                ))}
                            </>
                    ) : null}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DevicePopup;
