import '../css/career.css';
import CareerImg from '../images/career/1.png';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import axios from '../../config/index';
import ReCAPTCHA from 'react-google-recaptcha';


function Career() {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length) {
            const file = files[0];
            setFile(file);
            setFileName(file.name);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            setFileName(file.name);
        }
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };



    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSelectedCountry({
                value: 'SA',
                label: 'Saudi Arabia (+966)',
                countryCode: 'SA'
            });
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const [phoneNumber, setPhoneNumber] = useState('');

    const countries = getCountries().map((countryCode) => ({
        value: countryCode,
        label: `${en[countryCode]} (+${getCountryCallingCode(countryCode)})`,
        countryCode: countryCode,
    }));

    const customSingleValue = ({ data }) => (
        <div className="custom-single-value">
            <ReactCountryFlag countryCode={data.countryCode} svg style={{ marginRight: 10 }} />
            {data.label}
        </div>
    );

     const handleSubmit = (event) => {
        event.preventDefault();
        if (!captchaToken) {
            alert('Please complete the CAPTCHA');
            return;
        }

        const formData = new FormData();
        formData.append('firstName', event.target['first-name'].value);
        formData.append('secName', event.target['sec-name'].value);
        formData.append('email', event.target.email.value);
        formData.append('phoneNumber', phoneNumber);
        formData.append('city', event.target.city.value);
        formData.append('gender', event.target.gender.value);
        formData.append('field', event.target.field.value);
        formData.append('nationality', event.target.nationality.value);
        formData.append('cv', file);
        formData.append('captchaToken', captchaToken);

        axios.post('/send-application', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            console.log(response.data.message);
        })
        .catch((error) => {
            console.error('Error sending application:', error);
        });
    };

    return (
        <>
            <section className='en career'>
                <div className='container'>
                    <div className='image'>
                        <img src={CareerImg} alt='career image' />
                    </div>

                    <div className='form-element'>
                        <div className='header'>
                            <h1>Application form</h1>
                            <p>Fill in your details below, and we will get back to you.</p>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className='inputs'>
                                <div className='input'>
                                    <label>Middle name</label>
                                    <input type='text' placeholder='Ayman' required name='sec-name' />
                                </div>
                                <div className='input'>
                                    <label>First name</label>
                                    <input type='text' placeholder=' Eslam  ' required name='first-name' />
                                </div>
                            </div>

                            <div className='inputs'>
                                <div className='input'>
                                    <label>Mobile number</label>
                                    <div className='splitter'>
                                        <Select
                                            value={selectedCountry}
                                            onChange={setSelectedCountry}
                                            options={countries}
                                            getOptionLabel={(option) => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ReactCountryFlag countryCode={option.countryCode} svg style={{ marginRight: 10 }} />
                                                    {option.label}
                                                </div>
                                            )}
                                            getOptionValue={(option) => option.value}
                                            styles={{ container: (provided) => ({ ...provided, flex: 1 }) }}
                                            components={{ SingleValue: customSingleValue }}
                                        />
                                        <PhoneInput
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                            country={selectedCountry ? selectedCountry.value : undefined}
                                            placeholder='.... .... ...'
                                            required
                                            name='phoneNumber'
                                            style={{ flex: 2, marginLeft: '10px' }}
                                        />
                                    </div>
                                </div>
                                <div className='input'>
                                    <label>Email address</label>
                                    <input type='email' placeholder='example@gmail.com' required name='email' />
                                </div>
                            </div>

                            <div className='inputs'>
                                <div className='input'>
                                    <label>City</label>
                                    <input type='text' placeholder='Riyadh' required name='city' />
                                </div>
                                <div className='input'>
                                    <label>Gender</label>
                                    <select required name='gender'>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                            </div>

                            <div className='inputs'>
                                <div className='input'>
                                    <label>Specialization</label>
                                    <input type='text' placeholder='User Experience Designer' required name='field' />
                                </div>
                                <div className='input'>
                                    <label>Nationality</label>
                                    <input type='text' placeholder='Egyptian' required name='nationality' />
                                </div>
                            </div>

                            <div className='upload-input'>
                                <div
                                    className='drag-here'
                                    onClick={handleClick}
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                    style={{ border: '2px dashed #ccc', padding: '20px', cursor: 'pointer' }}
                                >
                                    <h1>{fileName ? fileName : 'Upload CV'}</h1>
                                    {!fileName && <h2>We accept these file formats - PDF, DOC, DOCX, JPG and PNG</h2>}
                                </div>
                                <input
                                    type='file'
                                    name='cv'
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    required
                                />
                            </div>

                            <div className='input'>
                                <ReCAPTCHA
                                    sitekey="6LcpkxYqAAAAAMYD41V-76-mSVA6ov3biaB87ILO"
                                    onChange={handleCaptchaChange}
                                />
                            </div>

                            <button type='submit' className='link-btn-color'>تقديم</button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Career;
