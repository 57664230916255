import './css/coursesexplain.css';


import AboutVideo from './videos/about.mp4';
import PlayIcon from './images/icons/Play.svg';
import { Link as RouterLink } from 'react-router-dom';

import { useEffect } from 'react';

import axios from '../config/index';
import { useState } from 'react';


function CoursesExplain(){

    
    const [itemData, setitemData] = useState([]);

    const getItemData = (itemId) => {
        const table = "courses_explain";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData(1);
    }, []);



    return(
        <>
            <section className='courses-explain'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='video'>
                                <video src={`./uploads/${itemData.video}`} muted ></video>

                                <div className='play-btn'>
                                    <button className='play-video'>
                                        <img src={PlayIcon} alt='play icon' />
                                    </button>
                                    <span></span>
                                    <span></span>
                                </div>


                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h2>  {itemData.sec_header}   </h2>
                                <h1 className='section-headline'>  {itemData.header} </h1>
                                <p className='section-text'>
                                {itemData.para}                                 
                                </p>

                                <RouterLink to='/how-to-use' className='link-btn-color'>
                                    عرض المزيد
                                </RouterLink>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default CoursesExplain;