import '../css/coursescategories.css';
import { Route, Link as RouterLink } from 'react-router-dom';


import CatImg1 from '../images/coursescategories/1.png';
import CatImg2 from '../images/coursescategories/2.png';
import CatImg3 from '../images/coursescategories/3.png';
import axios from '../../config/index';
import React, { useEffect ,useState} from 'react';



function CoursesCategories(){

    
    const [categories, setcategories] = useState([]);

    const getCategories = () => {
        const table = "explain_categories";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setcategories(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getCategories();
    }, []);


    return(
        <>
            <section className='en courses-categories'>
                <div className='container'>
                    <div className='header'>
                        <RouterLink to="/en/all-courses" className='link-btn-color'> Show all </RouterLink>

                        <h1 className='section-headline'> Explanations  </h1>
                    </div>

                    <div className='row'>

                    {categories.length > 0 ? (
                                categories.map((cat)=>{
                                    return(
                                        <>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <RouterLink to={`/en/all-courses?id=${cat.id}`}>
                                                <div className='category'>
                                                    <img className='section-image' src={`../uploads/${cat.img_1}`} alt='course category image'/>
                                                    <div className='overlay'></div>
                                                    <h1> {cat.header_en} </h1>
                                                </div>
                                            </RouterLink>
                                        </div>

                                    </>
                                    )
                                })
                            ):null}

                    

                    </div>
                </div>
            </section>
        </>
    )
}

export default CoursesCategories;