import '../css/mostservices.css';

import { Link as RouterLink } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper';

import Service1 from '../images/services1.png';
import Service2 from '../images/services2.png';
import Service3 from '../images/services3.png';

import axios from '../../config/index';

function MostServices() {

    const [services, setServices] = useState([]);

    const getServices = () => {
        const table = "services";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setServices(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getServices();
    }, []);

    return (
        <>
            <section className='en most-services'>
                <div className='container'>
                    <div className='header'>
                        <RouterLink to="/en/our-services" className='link-btn-color'>
                            Show More
                        </RouterLink>

                        <h1 className='section-headline'>Our Top Services</h1>
                    </div>

                    <div className='services-slider'>
                        <Swiper
                            grabCursor={true}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation={{
                                nextEl: '.services-slider .next-btn',
                                prevEl: '.services-slider .prev-btn',
                            }}
                            pagination={true}
                            modules={[Navigation, Pagination]}
                        >
                            {services.length > 0 ? (
                                <>
                                    {services.map((service, index) => {
                                        // Start a new slide for each set of three services
                                        if (index % 3 === 0) {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className='services-slide'>
                                                        {[0, 1, 2].map((offset) => {
                                                            const currentIndex = index + offset;
                                                            if (services[currentIndex]) {
                                                                return (
                                                                    <div key={currentIndex} className='service'>
                                                                        <h2 className='section-sec-headline'>
                                                                            {services[currentIndex].header_en}
                                                                        </h2>
                                                                        <p className='section-text'>
                                                                            {services[currentIndex].para_en}
                                                                        </p>
                                                                        <img
                                                                            src={`../uploads/${services[currentIndex].img_1}`}
                                                                            alt='service image'
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        }
                                        return null;
                                    })}
                                </>
                            ) : null}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MostServices;
