import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './css/loader.css';

const Loader = () => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);  // Start loading when the path changes

        const handleLoad = () => {
            setIsLoading(false);  // Stop loading when the new content is loaded
        };

        // Simulate a load event with a timeout (you can replace this with actual data fetching logic)
        const timeoutId = setTimeout(handleLoad, 500);  // Adjust the timeout duration as needed

        return () => {
            clearTimeout(timeoutId);  // Clean up timeout on unmount
        };
    }, [pathname]);

    return (
        <>
            {isLoading ? (
                <div className='loader-container'>
                    <div className="loader"></div>
                </div>
            ) : null}
        </>
    );
};

export default Loader;
