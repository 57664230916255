import './css/sidenav.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import {Link, useNavigate} from 'react-router-dom';
import { useLocation } from "react-router-dom";

import Logo from '../images/logo.svg';





function SideNav(props){

    useEffect(() => {
        axios
    .get('/admin-session')
    .then((res) => {
        if (res.data.validadmin) {

        } else {
            navigate('/admin-login');

        }
    })
    .catch((err) => console.log(err));
    },[]);

    
const navigate = useNavigate();
const [showUserOptions, setShowUserOptions] = useState(false);
const [user, setUser] = useState('');
const location = useLocation();

const [userDataLoaded, setUserDataLoaded] = useState(false); // New state variable




axios.defaults.withCredentials = true;



const logout = () => {
    axios
    .get('logout')
    .then((res) => {
        if (res.data.logout) {
            props.setSessionExists(false);
        navigate('/admin-login');

        } else {
        navigate('/admin-login');
        }
    })
    .catch((err) => console.log(err));
};


const handleUserOptionsClick = () => {
    setShowUserOptions(!showUserOptions);
};

const getUser = (userId) => {
    console.log(userId);
    axios
    .post('user', { userId })
    .then((res) => {
        if (res.data) {
            console.log(res.data);

        } else {
            setUser(res.data);
            setUserDataLoaded(true); 

        }
    })
    .catch((err) => console.log(err));

};


const openCloseNav = ()=>{
    let SideNav = document.querySelector(".sidenav");
    let searchBar = document.querySelector(".search-bar");
    let dashboard = document.querySelector(".dashboard");

    if(SideNav.classList.contains("active")){
        SideNav.classList.remove("active");
        searchBar.classList.remove("active");
        dashboard.classList.remove("active");
    }else{
        SideNav.classList.add("active");
        searchBar.classList.add("active");
        dashboard.classList.add("active");
    }
    

}


useEffect(() => {
    axios
    .get('/admin-session')
    .then((res) => {
        if (res.data.validadmin) {
            props.setSessionExists(true);
            getUser(res.data.userId);
            setUserDataLoaded(true); 

            console.log(userDataLoaded);

        } else {
            props.setSessionExists(false);
            getUser(0);
            console.log(userDataLoaded);

        }
    })
    .catch((err) => console.log(err));
}, [props.sessionExists]);













    return(
        <>
            
            <div  className={`${props.className} sidenav`}>
                <button onClick={openCloseNav} className='open-close-nav'> <i class="las la-bars"></i> </button>
                <div className="sidenav-content">
                    <div className="user-info">
                    {userDataLoaded && props.sessionExists ? (
                        <>
                            <img src={Logo} alt="logo" />
                        </>
                            ) : <></>}
                    </div>
                


                <div className='sidenav-elements'>
                    <ul>
                    

                        <li className={location.pathname === "/dashboard"? 'active':null}>
                            <Link to="/dashboard">
                            <i class="las la-home"></i>
                                لوحة التحكم
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-website"? 'active':null}>
                            <Link to="/all-website">
                            <i class="las la-home"></i>
                                فتح وغلق الموقع
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-users"? 'active':null}>
                            <Link to="/all-users">
                                <i class="las la-poll-h"></i>
                                المستخدمين
                            </Link>
                        </li>
                        
                        
                        <li className={location.pathname === "/all-landing"? 'active':null}>
                            <Link to="/all-landing">
                                <i class="las la-poll-h"></i>
                                الواجهة الرئيسية
                            </Link>
                        </li>
                        
                        <li className={location.pathname === "/all-about"? 'active':null}>
                            <Link to="/all-about">
                                <i class="las la-poll-h"></i>
                                عن الشركة 
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-services"? 'active':null}>
                            <Link to="/all-services">
                                <i class="las la-poll-h"></i>
                                الخدمات 
                            </Link>
                        </li>
                        
                        <li className={location.pathname === "/all-numbers"? 'active':null}>
                            <Link to="/all-numbers">
                                <i class="las la-poll-h"></i>
                                الأرقام 
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-courses-explain"? 'active':null}>
                            <Link to="/all-courses-explain">
                                <i class="las la-poll-h"></i>
                                شرح الكورسات بالرئيسية
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-prices-sms"? 'active':null}>
                            <Link to="/all-prices-sms">
                                <i class="las la-poll-h"></i>
                                اسعار الرسائل بالرئيسية
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-prices-devices"? 'active':null}>
                            <Link to="/all-prices-devices">
                                <i class="las la-poll-h"></i>
                                اسعار الاجهزة 
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-rates"? 'active':null}>
                            <Link to="/all-rates">
                                <i class="las la-poll-h"></i>
                                التقييمات
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-ad-contact"? 'active':null}>
                            <Link to="/all-ad-contact">
                                <i class="las la-poll-h"></i>
                                جزء التواصل
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-about-section-header"? 'active':null}>
                            <Link to="/all-about-section-header">
                                <i class="las la-poll-h"></i>
                                عنوان صفحة عن الشركة
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-about-text"? 'active':null}>
                            <Link to="/all-about-text">
                                <i class="las la-poll-h"></i>
                                نص عن الشركة
                            </Link>
                        </li>


                            
                        <li className={location.pathname === "/all-expreince-header"? 'active':null}>
                            <Link to="/all-expreince-header">
                                <i class="las la-poll-h"></i>
                                عنوان جزء الخبرة
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-expreince"? 'active':null}>
                            <Link to="/all-expreince">
                                <i class="las la-poll-h"></i>
                                الخبرة
                            </Link>
                        </li>
                        

                        <li className={location.pathname === "/all-team"? 'active':null}>
                            <Link to="/all-team">
                                <i class="las la-poll-h"></i>
                                الفريق
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-clients"? 'active':null}>
                            <Link to="/all-clients">
                                <i class="las la-poll-h"></i>
                                العملاء
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-services-section-header"? 'active':null}>
                            <Link to="/all-services-section-header">
                                <i class="las la-poll-h"></i>
                                عنوان صفحة الخدمات
                            </Link>
                        </li>
                        

                        <li className={location.pathname === "/all-services-text"? 'active':null}>
                            <Link to="/all-services-text">
                                <i class="las la-poll-h"></i>
                                نص صفحة الخدمات
                            </Link>
                        </li>
                        

                        <li className={location.pathname === "/all-services-page"? 'active':null}>
                            <Link to="/all-services-page">
                                <i class="las la-poll-h"></i>
                                خدمات صفحة الخدمات
                            </Link>
                        </li>
                        

                        
                        <li className={location.pathname === "/all-faqs"? 'active':null}>
                            <Link to="/all-faqs">
                                <i class="las la-poll-h"></i>
                                الأسالة العامة
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-how-to-use-section-header"? 'active':null}>
                            <Link to="/all-how-to-use-section-header">
                                <i class="las la-poll-h"></i>
                                عنوان صفحة كيفية الاستخدام
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-how-to-use-text"? 'active':null}>
                            <Link to="/all-how-to-use-text">
                                <i class="las la-poll-h"></i>
                                نص صفحة كيفية الاستخدام
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-explain-category"? 'active':null}>
                            <Link to="/all-explain-category">
                                <i class="las la-poll-h"></i>
                                اقسام الشروحات
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-all-courses-section-header"? 'active':null}>
                            <Link to="/all-all-courses-section-header">
                                <i class="las la-poll-h"></i>
                                عنوان صفحة الكورسات
                            </Link>
                        </li>
                        
                        <li className={location.pathname === "/all-all-courses"? 'active':null}>
                            <Link to="/all-all-courses">
                                <i class="las la-poll-h"></i>
                                    الكورسات
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-sms-services-section-header"? 'active':null}>
                            <Link to="/all-sms-services-section-header">
                                <i class="las la-poll-h"></i>
                                    عنوان صفحة خدمات  الرسائل
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-sms-services-text"? 'active':null}>
                            <Link to="/all-sms-services-text">
                                <i class="las la-poll-h"></i>
                                    نص صفحة خدمات الرسائل
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-who-benefits"? 'active':null}>
                            <Link to="/all-who-benefits">
                                <i class="las la-poll-h"></i>
                                    تعديل قسم من المستفيد
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-sms-settings"? 'active':null}>
                            <Link to="/all-sms-settings">
                                <i class="las la-poll-h"></i>
                                    اعدادات الرسائل
                            </Link>
                        </li>

                                    
                        <li className={location.pathname === "/all-api-files"? 'active':null}>
                            <Link to="/all-api-files">
                                <i class="las la-poll-h"></i>
                                    ملفات الاي بي اي
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-finger-print-section-header"? 'active':null}>
                            <Link to="/all-finger-print-section-header">
                                <i class="las la-poll-h"></i>
                                    عنوان صفحة اجهزة البصمة
                            </Link>
                        </li>


                        
                        <li className={location.pathname === "/all-finger-print-text"? 'active':null}>
                            <Link to="/all-finger-print-text">
                                <i class="las la-poll-h"></i>
                                    نص صفحة اجهزة البصمة
                            </Link>
                        </li>



                                
                        <li className={location.pathname === "/all-finger-print-devices"? 'active':null}>
                            <Link to="/all-finger-print-devices">
                                <i class="las la-poll-h"></i>
                                    اجهزة البصمة
                            </Link>
                        </li>


                        
                                
                        <li className={location.pathname === "/all-finger-print-benefits"? 'active':null}>
                            <Link to="/all-finger-print-benefits">
                                <i class="las la-poll-h"></i>
                                    مميزات اجهزة البصمة
                            </Link>
                        </li>

                                    
                        <li className={location.pathname === "/all-prices-section-header"? 'active':null}>
                            <Link to="/all-prices-section-header">
                                <i class="las la-poll-h"></i>
                                    عنوان صفحة الاسعار
                            </Link>
                        </li>



                                    
                        <li className={location.pathname === "/all-prices-text"? 'active':null}>
                            <Link to="/all-prices-text">
                                <i class="las la-poll-h"></i>
                                    نص صفحة الاسعار
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-prices-packages"? 'active':null}>
                            <Link to="/all-prices-packages">
                                <i class="las la-poll-h"></i>
                                    أسعار باقات الرسائل
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-careers-section-header"? 'active':null}>
                            <Link to="/all-careers-section-header">
                                <i class="las la-poll-h"></i>
                                    عنوان صفحة العمل
                            </Link>
                        </li>



                        <li className={location.pathname === "/all-faqs-page-categories"? 'active':null}>
                            <Link to="/all-faqs-page-categories">
                                <i class="las la-poll-h"></i>
                                    عنوان الاسالة
                            </Link>
                        </li>

                        <li className={location.pathname === "/all-questions"? 'active':null}>
                            <Link to="/all-questions">
                                <i class="las la-poll-h"></i>
                                    اسالة العنوان
                            </Link>
                        </li>


                        <li className={location.pathname === "/all-privacy"? 'active':null}>
                            <Link to="/all-privacy">
                                <i class="las la-poll-h"></i>
                                    الخصوصية
                            </Link>
                        </li>

                        
                        <li className={location.pathname === "/all-terms"? 'active':null}>
                            <Link to="/all-terms">
                                <i class="las la-poll-h"></i>
                                    الشروط
                            </Link>
                        </li>




                        <li className={location.pathname === "/all-social"? 'active':null}>
                            <Link to="/all-social">
                                <i class="las la-poll-h"></i>
                                السوشيال ميديا
                            </Link>
                        </li>

                        
                        
                        
                        
                        
                        
                        
                    
                    
                        
                    



                        <li>
                            <button onClick={logout}>
                                <i class="las la-sign-out-alt"></i>
                                تسجيل الخروج
                            </button>
                        </li>

                        <li>
                            <Link to="/">
                                <i class="las la-search"></i>
                                زيارة الموقع
                            </Link>
                        </li>


                    </ul>
                </div>
            </div>
            </div>
        
        </>
    )
}

export default SideNav;