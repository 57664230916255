import '../css/comingsoon.css';


import ObjectsImage from '../images/devices/objects.png';
import ComingSoonImage from '../images/comingsoon.png';
import { Link as RouterLink } from 'react-router-dom';

import landingImg from '../images/landing.png';


function ComingSoon(){
    return(
        <>
            <section className='en coming-soon'>
                <img src={ObjectsImage} alt='objects'/>
                <img src={landingImg} alt='ecp'/>
                
                <div className='container'>
                    <div className='content'>
                        <h1> It will be available soon   </h1>
                        <p>
                        Send us your email address, and we will notify you when it becomes available
                        </p>

                        <img src={ComingSoonImage} alt='not found image' />

                        <div>
                            <button className='link-btn-color' type='submit'>
                            Send
                            </button>

                            <input type='email' placeholder='Email' required name='email'  />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ComingSoon;