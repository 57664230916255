import React, { useState } from 'react';
import '../css/whogetsourbenefits.css';

import ConsIcon1 from '../images/consumers/1.svg';
import ConsIcon2 from '../images/consumers/2.svg';
import ConsIcon3 from '../images/consumers/3.svg';

import ConsumerImage1 from '../images/consumers/4.png';
import ConsumerImage2 from '../images/consumers/5.png';
import ConsumerImage3 from '../images/consumers/6.png';



import axios from '../../config/index';
import { useEffect } from 'react';


function WhoGetsOurBenefits() {
    const [activeConsumer, setActiveConsumer] = useState(2);

    const handleMouseEnter = (index) => {
        setActiveConsumer(index);
    };



    
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);
    const [itemData3, setitemData3] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "who_benefits";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData2 = (itemId) => {
        const table = "who_benefits";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData3 = (itemId) => {
        const table = "who_benefits";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData3(res.data);
            }
        }).catch((err) => console.log(err));
    };





    useEffect(() => {
        getItemData1(1);
        getItemData2(2);
        getItemData3(3);

    }, []);

    return (
        <>
            <section className='en who-gets-our-benefits'>
                <div className='container'>
                    <div className='section-header-ele'>
                        <h1 className='section-headline'>Who benefits from our services</h1>
                    </div>

                    <div className='consumers'>
                        <div 
                            className={`consumer ${activeConsumer === 0 ? 'active' : ''}`} 
                            onMouseEnter={() => handleMouseEnter(0)}
                        >
                            <img src={`../uploads/${itemData1.img_1}`} alt='consumer image' />
                            <div className='text'>
                                <img src={ConsIcon1} alt='icon' />
                                <h2> {itemData1.header_en} </h2>
                                <p className='section-text'>
                                    {itemData1.para_en}
                                </p>
                            </div>
                        </div>

                        <div 
                            className={`consumer ${activeConsumer === 1 ? 'active' : ''}`} 
                            onMouseEnter={() => handleMouseEnter(1)}
                        >
                            <img src={`../uploads/${itemData2.img_1}`} alt='consumer image' />
                            <div className='text'>
                                <img src={ConsIcon2} alt='icon' />
                                <h2> {itemData2.header_en} </h2>
                                <p className='section-text'>
                                    {itemData3.para_en}
                                </p>
                            </div>
                        </div>

                        <div 
                            className={`consumer ${activeConsumer === 2 ? 'active' : ''}`} 
                            onMouseEnter={() => handleMouseEnter(2)}
                        >
                            <img src={`../uploads/${itemData3.img_1}`} alt='consumer image' />
                            <div className='text'>
                                <img src={ConsIcon3} alt='icon' />
                                <h2> {itemData3.header_en} </h2>
                                <p className='section-text'>
                                    {itemData3.para_en}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default WhoGetsOurBenefits;
