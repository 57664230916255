import '../css/apifiles.css';
import DownloadIcon from '../images/downloadicon.svg';

import DownloadApiFilesImg from '../images/downloadapifiles.gif';

import axios from '../../config/index';
import React, { useEffect ,useState} from 'react';

function ApiFiles(){


    
    const [itemData2, setitemData2] = useState([]);
    const [firstHalf, setfirstHalf] = useState([]);
    const [sechalf, setsechalf] = useState([]);

    const getItemData2 = () => {

        const table = "api_files";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
                let middleIndex = Math.floor(res.data.length / 2);
                let firstHalf = res.data.slice(0, middleIndex);
                let secondHalf = res.data.slice(middleIndex);
                setfirstHalf(firstHalf);
                setsechalf(secondHalf);

            }else{
                console.log(res.data)

            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {

        getItemData2();
    }, []);



    return(
        <>
            <section className='en api-files'>
                <div className='container'>
                    <div className='section-header-ele'>
                    <h1 className='section-headline'>API Files</h1>
                        <p className='section-text'>
                        If you are a website owner and wish to provide messages for your site, we have a free system.
                        To communicate with the mobile phone owners registered in your database.
                        </p>
                    </div>


                    <div className='row'>
                        <div className='col-lg-5 col-md-5 cols-sm-12 col-12'>
                            <div className='files'>
                                <ul>

                                {
                                    firstHalf.length > 0 ? (
                                        <>
                                            {firstHalf.map((apifile, index) => {
                                                return (
                                                    <>
                                                    <li>
                                                        <a href={`../uploads/${apifile.file}`} target='_blank'>
                                                            Download
                                                            <img src={DownloadIcon}  alt='download icon'/>
                                                        </a>

                                                        <h4>  <span>  {apifile.size_en} -  </span>  {apifile.header_en} </h4>
                                                    </li>

                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : null
                                }
                                    

                                    

                                </ul>
                            </div>
                        </div>

                        <div className='col-lg-2 col-md-2 cols-sm-12 col-12'>
                            <div className='image'>
                                <img src={DownloadApiFilesImg} alt='download-api-image' />
                            </div>
                        </div>



                        <div className='col-lg-5 col-md-5 cols-sm-12 col-12'>
                            <div className='files'>
                                <ul>
                                {
                                    sechalf.length > 0 ? (
                                        <>
                                            {sechalf.map((apifile, index) => {
                                                return (
                                                    <>
                                                    <li>
                                                        <a href={`../uploads/${apifile.file}`} target='_blank'>
                                                            Download
                                                            <img src={DownloadIcon}  alt='download icon'/>
                                                        </a>

                                                        <h4>  <span>  {apifile.size_en} -  </span>  {apifile.header_en} </h4>
                                                    </li>

                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : null
                                }


                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default ApiFiles;