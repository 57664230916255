import './css/faqs.css';
import React, { useEffect ,useState} from 'react';

import { Link as RouterLink } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import axios from '../config/index';

function Faqs(){

    const [faqs, setfaqs] = useState([]);

    const getfaqs = () => {
        const table = "faqs";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setfaqs(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getfaqs();
    }, []);

    const [change, setChange] = useState(false);

    useEffect(() => {
        setChange(true);

        // jQuery script to handle toggle functionality for .question .header
        $('.question .header').on('click', function () {
            $(this).find('button').toggleClass('active');
            $(this).find('button').find('i').toggleClass('la-plus la-minus');
            $(this).next('.content').toggleClass('active');
        });

        // Cleanup event listeners on unmount
        return () => {
            $('.slide-toggle').off('click');
            $('.question .header').off('click');
        };
    }, [faqs]); // Empty dependency array to ensure it runs only once

    return(
        <>
            <section className='faqs'>
                <div className='container'>
                    <div className='row'>

                        
                        <div className='col-12'>
                            <div className='header'>
                                <h1 className='section-headline'>
                                الأسئلة الشائعة
                                </h1>
                            </div>
                        </div>



                        <div className='col-12'>
                            {faqs.length > 0 ? (
                                faqs.slice().reverse().map((faq)=>{
                                    return(
                                        <>
                                    <div className='question'>
                                        <div className='header'>
                                            <button className={`slide-toggle ${faq.id == 1 ? "active" : null}`}>
                                                <i className={`las ${faq.id == 1 ? "la-minus" : "la-plus"}`}></i>
                                            </button>

                                            <h2 className='section-sec-headline'> {faq.header} </h2>
                                        </div>

                                        <div className={`content ${faq.id == 1 ? "active" : null}`}>
                                            <p className='section-text'>
                                                {faq.para}                                            
                                            </p>
                                        </div>
                                    </div>
                                    </>
                                    )
                                })
                            ):null}

                        </div>




                    </div>
                </div>
            </section>
        </>
    )
}

export default Faqs;