import '../css/userloginpages.css';
import ObjectsImage from '../images/devices/objects.png';
import Logo from '../images/logosm.svg';

import { Router, Link as RouterLink } from 'react-router-dom';

import EyeIcon from '../images/eye.svg';

import { useState  } from 'react';
import { useEffect } from 'react';

import GoogleIcon from '../images/icons/google.svg';

import $ from 'jquery';

function Login(){

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    useEffect(() => {
        $(document).on("click", ".change-to-darkmode", function() {
            $("section, nav, footer,body,div,h1,p,h2").addClass("dark-mode");
            $(this).removeClass("change-to-darkmode");
            $(this).addClass("change-to-lightmode");
        });

        $(document).on("click", ".change-to-lightmode", function() {
            $("section, nav, footer,body,div,h1,p,h2,h3").removeClass("dark-mode");
            $(this).removeClass("change-to-lightmode");
            $(this).addClass("change-to-darkmode");
        });
        if($("nav .left>a:nth-child(2)").hasClass("change-to-lightmode") || $("body").hasClass("dark-mode")){
            $("nav .left>a:nth-child(2)").removeClass("change-to-darkmode");
            $("nav .left>a:nth-child(2)").addClass("change-to-lightmode");

            $("section, nav, footer,body,div,h1,p,h2,h3").addClass("dark-mode");
        }
    }, []);



    return(
        <>
            <section className='en user-login-pages'>
                <div className='left'>
                    <img src={ObjectsImage} alt='objects'/>
                    <div className='container'>
                        <div className='user-side-page'>
                            <div className='content'>
                                <img src={Logo} alt='logo'/>
                                <h2 className='section-sec-headline'> Welcome to Al-Madar Technical System  </h2>

                                <h1 className='section-headline'>  Technology is faster than light </h1>


                                 <div>
                                    <RouterLink target='_blank' to="/en/contact-us">
                                    If you encounter any issues, contact us
                                    </RouterLink>

                                    <RouterLink to="/en/" className='link-btn-midcolor'>
                                    Back to Home
                                    </RouterLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='right login'>
                    <div className='container'>
                        <div className='content'>
                            <div className='form-element'>
                                <div className='header'>
                                    <RouterLink to="/en/sign-up"> New Account? </RouterLink>
                                    <div>
                                        <h1> Log In </h1>
                                        <p>
                                            Enter your credentials to access your account
                                        </p>
                                    </div>
                                </div>

                                <div className='login-google'>
                                    <button className='login-by-google'> Log in with Google <img src={GoogleIcon} alt='google icon' /> </button>
                                </div>

                                <div className='or'>
                                    <span> </span>
                                    <h4> أو  </h4>
                                    <span> </span>
                                </div>


                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                            Email
                                            </label>

                                        
                                            <input placeholder='example@gmail.com' type="email" required name='email' />
                                        </div>
                                    </div>

                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                            Password
                                            </label>

                                            <button type='button' className='show-password' onClick={togglePassword2}>
                                                <img src={EyeIcon} alt='eye icon' />
                                            </button>
                                            
                                            <input placeholder='gdfa65HDVu??**dvsw' type={showPassword2 ? 'text' : 'password'} required name='password' />
                                        </div>
                                    </div>

                                    <div className='submit-sec'>
                                        <RouterLink to="/en/reset-password-email"> Forgot Password?</RouterLink>
                                    <button className='link-btn-color'>  Save and Log In </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login;