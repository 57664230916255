import './css/contactad.css';
import { Link as RouterLink } from 'react-router-dom';


import Contactdots from './images/contactdots.png';


import axios from '../config/index';
import { useState, useEffect } from 'react';


function ContactAd(){

    const [landing, setLanding] = useState([]);

    const getLanding = (itemId) => {
        const table = "ad_contact";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setLanding(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getLanding(1);
    }, []);


    return(
        <>
            <section className='contactad'>
                <img src={Contactdots} alt="contact dots" />
                <div className='container'>
                    <div className='contactad-content'>
                        <h1 className='section-headline'> 
                        {landing.header}
                        </h1>
                        <RouterLink to='/contact-us' className='link-btn-color'> تواصل معنا</RouterLink>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactAd;