import './css/servicessteps.css';

import Step1Img from './images/servicessteps/1.svg';
import Step2Img from './images/servicessteps/2.svg';
import Step3Img from './images/servicessteps/3.svg';


import Arrow from './images/servicessteps/arrow.svg';



function ServicesSteps(){
    return(
        <>
            <section className='services-steps'>
                <div className='container'>
                    <div className='header'>
                        <h1 className='section-headline'> 
                            خدماتُنا مررره سهلة
                        </h1>
                    </div>


                    <div className='steps'>
                        <div className='step'>
                            <img src={Step3Img}  alt='step image'/>
                            <h2 className='section-sec-headline'> 
                                3.ابدء عملك فوراً
                            </h2>
                        </div>
                        <div className='arrow'>
                            <img src={Arrow}  alt='arrows image'/>
                        </div>
                        <div className='step'>
                            <img src={Step2Img}  alt='step image'/>
                            <h2 className='section-sec-headline'> 
                                2.ادفع مقابل الخدمة
                            </h2>
                        </div>

                        <div className='arrow'>
                            <img src={Arrow}  alt='arrows image'/>
                        </div>
                        <div className='step'>
                            <img src={Step1Img}  alt='step image'/>
                            <h2 className='section-sec-headline'> 
                                1.حدد الخدمة 
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesSteps;