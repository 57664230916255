import './css/ourservicespagetext.css';
import PageImg from './images/servicespagetext.png';

import axios from '../config/index';
import { useEffect, useState } from 'react';


function OurServicesPageText(){

    
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "services_text";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);



    return(
        <> 
            <section className='our-services-page-text'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'> 
                                {itemData1.header}
                                </h1>

                                <p className='section-text'>
                                {itemData1.para}
                                </p>

                            
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img className='section-image' src={`./uploads/${itemData1.img_1}`} alt='services page image' />
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default OurServicesPageText;