import './css/comingsoon.css';


import ObjectsImage from './images/devices/objects.png';
import ComingSoonImage from './images/comingsoon.png';
import { Link as RouterLink } from 'react-router-dom';

import landingImg from './images/landing.png';


function ComingSoon(){
    return(
        <>
            <section className='coming-soon'>
                <img src={ObjectsImage} alt='objects'/>
                <img src={landingImg} alt='ecp'/>
                
                <div className='container'>
                    <div className='content'>
                        <h1> سوف يكون متاح قريباً   </h1>
                        <p>
                        ارسل لنا إيميلك الالكتروني وسوف نعلمك عندما يكون متاح إن شاء الله
                        </p>

                        <img src={ComingSoonImage} alt='not found image' />

                        <div>
                            <button className='link-btn-color' type='submit'>
                                ارسال
                            </button>

                            <input type='email' placeholder='البريد الالكتروني' required name='email'  />
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default ComingSoon;