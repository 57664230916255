import './css/services.css';
import CheckMark from './images/check.svg';
import { Link as RouterLink } from 'react-router-dom';

import GooglePlayImg from './images/googleplay.svg';
import AppleStoreImg from './images/appstore.svg';


import ServiceImg1 from './images/services/1.svg';
import ServiceImg2 from './images/services/2.svg';
import ServiceImg3 from './images/services/3.svg';
import ServiceImg4 from './images/services/4.svg';
import ServiceImg5 from './images/services/5.svg';
import ServiceImg6 from './images/services/6.svg';
import axios from '../config/index';
import { useEffect, useState } from 'react';

function Services(){



    

    const [itemData2, setitemData2] = useState([]);




    const getItemData2 = () => {

        const table = "services_page";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }else{
                console.log(res.data)

            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {

        getItemData2();
    }, []);



    return(
        itemData2.length > 0 ? (
            <>
            <section className='services'>
            <div className='container'>
                <div className='row'>


                {itemData2[5].status == "active" ? 
                    <>
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                            <h1 className='section-headline'> 
                                {itemData2[5].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[5].para}

                            </p>

                            <ul>
                                <li>
                                <img src={CheckMark}  alt='check mark'/>
                                <h2 className='section-sec-headline'> 
                                    للمدارس و المؤسسات التعليمية
                                    </h2>
                                </li>

                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    للافراد و الشركات
                                    </h2>
                                </li>


                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    للمبرمجين و اصحاب المواقع
                                    </h2>
                                </li>

                            </ul>

                            <div>
                                <RouterLink to='/all-courses' className='normal-link'>
                                    الذهاب الي الشروحات لمعرفة كيفية الاستخدام 
                                </RouterLink>
                                
                                <RouterLink className='link-btn-color' to='/sms-services' aria-label='show more'>
                                عرض المزيد
                                </RouterLink>
                            </div>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                            <img className='section-image' src={`./uploads/${itemData2[5].img_1}`}  alt='service image'/>
                        </div>
                    </div>
                    </>
                :null}
                    {/* Sec */}

                    
                    {itemData2[4].status == "active" ? 
                    <>
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`./uploads/${itemData2[4].img_1}`}  alt='service image'/>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[4].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[4].para}
                            </p>

                            <ul>
                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    اجهزة البصمة للطلاب
                                    </h2>
                                </li>

                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    اجهزة البصمة للمعلمين
                                    </h2>
                                </li>


                            </ul>

                            <div>
                                <RouterLink to='/all-courses' className='normal-link'>
                                الذهاب الي الشروحات لمعرفة كيفية الاستخدام 
                                </RouterLink>
                                
                                <RouterLink className='link-btn-color' to='/fingerprint-devices' aria-label='show more'>
                                عرض المزيد
                                </RouterLink>
                            </div>
                        </div>
                    </div>
                    </>
                :null}

                    {/* Third */}







                    {itemData2[3].status == "active" ? 
                    <>
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[3].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[3].para}
                            </p>



                            <h3> حمل التطبيق الأن  </h3>


                            <div>
                                <a target='_blank' href='https://apps.apple.com/us/app/ttbyq-rsayl-aljwal-almdar/id1031601867?ls=1'  className='apple-store'>
                                    <img src={AppleStoreImg} alt='apple store logo'/>

                                </a>
                                
                                <a target='_blank' href='https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1' className='google-play'>
                                    <img src={GooglePlayImg} alt='goolge play logo'/>
                                    
                                </a>
                            </div>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`./uploads/${itemData2[3].img_1}`}  alt='service image'/>
                        </div>
                    </div>

                    </>
                :null}


                    {/* Fourth */}


                    
                    {itemData2[2].status == "active" ? 
                    <>
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`./uploads/${itemData2[2].img_1}`}  alt='service image'/>
                        </div>
                    </div>





                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[2].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[2].para}
                            </p>

                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    كـ مشرف للنظام
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> 
                                            اليوزر :admin 
                                            </h5>
                                        </li>
                                        <li>
                                            <h5> 
                                            الباسورد : admin123 
                                            </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    كـ مستخدم عادي 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5>اليوزر : user  </h5>
                                        </li>
                                        <li>
                                            <h5> الباسورد : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/contact-us'>
                                تواصل معنا لطلب نسخة
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                تجربة النظام
                                </a>
                            </div>
                        </div>
                    </div>
                    </>
                :null}


                    {/* Fifth */}




                    {itemData2[1].status == "active" ? 
                    <>

                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[1].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[1].para}
                            </p>


                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    كـ مشرف للنظام:
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> اليوزر :admin  </h5>
                                        </li>
                                        <li>
                                            <h5> والباسورد : admin123  </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    كـ مستخدم عادي: 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> اليوزر : demo  </h5>
                                        </li>
                                        <li>
                                            <h5> الباسورد : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/contact-us'>
                                تواصل معنا لطلب نسخة
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                تجربة النظام
                                </a>
                            </div>
                        </div>
                    </div>



                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`./uploads/${itemData2[1].img_1}`}  alt='service image'/>
                        </div>
                    </div>

                    </>
                :null}


                    {/* Sixth */}



                    {itemData2[0].status == "active" ? 
                    <>

                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`./uploads/${itemData2[0].img_1}`}  alt='service image'/>
                        </div>
                    </div>







                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[0].header}
                            </h1>

                            <p className='section-text'>
                            {itemData2[0].para}
                            </p>
                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    كـ مشرف للنظام:
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5>اليوزر :admin  </h5>
                                        </li>
                                        <li>
                                            <h5> والباسورد : admin123  </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    كـ مستخدم عادي: 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> اليوزر : demo  </h5>
                                        </li>
                                        <li>
                                            <h5> الباسورد : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/contact-us'>
                                تواصل معنا لطلب نسخة
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                تجربة النظام
                                </a>
                            </div>
                        </div>
                    </div>
                    </>
                :null}


                </div>
            </div>
        </section>
            </>
        ):null
    )
}

export default Services;