import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(props){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [servicesCount, setservicesCount] = useState(0);
    const [ratesCount, setratesCount] = useState(0);
    const [coursesCount, setcoursesCount] = useState(0);
    const [userCount, setUsersCount] = useState(0);

    const [users, setUsers] = useState(0);

    

    const getAllUsers = ()=>{
        axios.post('AllUsers', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                setUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    



    const getServicesCount = () => {
        axios.post('get-item-count', {
            table: 'services',
        })
        .then((res) => {
            if (res.data) {
                setservicesCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getCoursesCount = () => {
        axios.post('get-item-count', {
            table: 'courses',
        })
        .then((res) => {
            if (res.data) {
                setcoursesCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getRatesCount = () => {
        axios.post('get-item-count', {
            table: 'rates',
        })
        .then((res) => {
            if (res.data) {
                setratesCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    
    
    const getUsersCount = () => {
        axios.post('get-item-count', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                setUsersCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    
useEffect(() => {
    getAllUsers();


    getCoursesCount();
    getRatesCount();
    getServicesCount();
    getUsersCount();


}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-subscribers">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{ratesCount}</h1>
                                <h2>  التقييمات  </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-courses">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{coursesCount}</h1>
                                <h2> الكورسات  </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-categories">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{servicesCount}</h1>
                                <h2> الخدمات </h2>
                            </div>
                        </div>
                    </Link>

                    <Link to="/all-users">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{userCount}</h1>
                                <h2> المستخدمين </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                        <th> # </th>
                            <th> الصورة </th>
                            <th> الأسم </th>
                            <th> البريد الإلكتروني </th>
                            <th> الإختيارات </th>

                        </tr>
                    </thead>
                    <tbody>

                    {users.length ? (
                    users.map((user) => {
                        return (
                        <tr key={user.user_id}>
                            
                            <td>
                            <h6>{user.user_id}</h6>
                            </td>

                            <td><img src={`./uploads/${user.profile_img}`} alt="item Image"/></td>

                            <td>
                            <h6>{user.fullname}</h6>
                            </td>

                            <td>
                            <h6>{user.email}</h6>
                            </td>


                            <td><Link className='edit' to={`/edit-user?id=${user.user_id}`}> <i class="las la-edit"></i> </Link></td>

                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>تحميل...</td>
                    </tr>
                    )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;