import './css/vesion.css';
import VesionImg from './images/vesion.gif';

import axios from '../config/index';
import { useEffect, useState } from 'react';


function Vesion(){
    
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "about";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData1(1);
    }, []);


    return(
        <>
            <section className='vesion'>
                <div className='container'>
                    <div className='row'>
                        


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img src={VesionImg} alt='section image' />
                            </div>
                        </div>


                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'> 
                                {itemData1.vision}
                                </h1>
                                <p className='section-text'>
                                {itemData1.vision_para}

                                </p>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </section>
        </>
    )
}

export default Vesion;