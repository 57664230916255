import '../css/devicesbenefitstext.css';
import CheckMark from '../images/check.svg';

import BenefitImg1 from '../images/benefits/1.png';
import BenefitImg2 from '../images/benefits/2.png';

import axios from '../../config/index';
import React, { useEffect ,useState} from 'react';

function DevicesBenefitsText(){


        
    const [itemData2, setitemData2] = useState([]);


    const getItemData2 = () => {

        const table = "finger_print_benefits";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }else{
                console.log(res.data)

            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {

        getItemData2();
    }, []);



    return(
        <>
            <section className='en devices-benefits-text'>
                <div className='container'>
                    <div className='row'>

                    {
                        itemData2.length > 0 ? (
                            <>
                                {
                                itemData2.length > 0 ? (
                                    <>
                                        {itemData2.reverse().map((benefit, index) => {
                                            let itemData2info = benefit.description_en.split(",");

                                            return (
                                                <div key={index} className='row'>
                                                    {index % 2 === 0 ? (
                                                        <>
                                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                                <div className='text'>
                                                                    <h1 className='section-headline'>
                                                                        {benefit.header_en}
                                                                    </h1>

                                                                    <ul>
                                                                        {itemData2info.map((info, idx) => (
                                                                            <li key={idx}>
                                                                                <img src={CheckMark} alt='check mark' />
                                                                                {info}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                                <div className='image'>
                                                                    <img src={`../uploads/${benefit.img_1}`} className='section-image' alt='benefit image' />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                                <div className='image'>
                                                                    <img src={`../uploads/${benefit.img_1}`} className='section-image' alt='benefit image' />
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                                <div className='text'>
                                                                    <h1 className='section-headline'>
                                                                        {benefit.header_en}
                                                                    </h1>

                                                                    <ul>
                                                                        {itemData2info.map((info, idx) => (
                                                                            <li key={idx}>
                                                                                <img src={CheckMark} alt='check mark' />
                                                                                {info}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : null
                            }
                            </>
                        ) : null
                    }
                        


                    </div>
                </div>
            </section>
        </>
    )
}

export default DevicesBenefitsText;