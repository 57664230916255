import './css/ourclients.css';

import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';

import Client1 from './images/clients/1.svg';
import Client2 from './images/clients/2.svg';
import Client3 from './images/clients/3.svg';
import Client4 from './images/clients/4.svg';
import Client5 from './images/clients/5.svg';
import Client6 from './images/clients/6.svg';
import Client7 from './images/clients/7.svg';
import Client8 from './images/clients/8.svg';
import Client9 from './images/clients/9.svg';
import Client10 from './images/clients/10.svg';
import Client11 from './images/clients/11.svg';
import Client12 from './images/clients/12.svg';
import Client13 from './images/clients/13.svg';
import Client14 from './images/clients/14.svg';
import Client15 from './images/clients/15.svg';
import Client16 from './images/clients/16.svg';
import Client17 from './images/clients/17.svg';
import Client18 from './images/clients/18.svg';
import Client19 from './images/clients/19.svg';
import Client20 from './images/clients/20.svg';
import Client21 from './images/clients/10.svg';
import Client22 from './images/clients/11.svg';
import Client23 from './images/clients/12.svg';
import Client24 from './images/clients/13.svg';
import Client25 from './images/clients/14.svg';
import Client26 from './images/clients/15.svg';
import Client27 from './images/clients/16.svg';
import Client28 from './images/clients/17.svg';


const clients = [Client1, Client2, Client3, Client4, Client5, Client6, Client7,Client8,Client9,
    Client10,Client11,Client12,Client13,Client14,Client15,Client16,Client17,Client18,Client19,Client20,
    Client21,Client22,Client23,Client24,Client25,Client26,Client27,Client28
];

function OurClients(){
    return(
        <>
            <section className='our-clients'>
                <div className='container'>
                    <div className='our-clients-slider'>

                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                    nextEl: '.our-clients .next-btn',
                    prevEl: '.our-clients .prev-btn',
                    }} pagination={true}
                    
                        modules={[Navigation,Pagination]}
                        >

                            <SwiperSlide>
                                <div className='clients'>
                                {clients.map((client, index) => (
                                    <div className='client'>
                                        <img key={index} src={client} alt='client image' />
                                    </div>
                                ))}
                                

                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='clients'>
                                {clients.map((client, index) => (
                                    <div className='client'>
                                        <img key={index} src={client} alt='client image' />
                                    </div>
                                ))}
                                

                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='clients'>
                                {clients.map((client, index) => (
                                    <div className='client'>
                                        <img key={index} src={client} alt='client image' />
                                    </div>
                                ))}
                                

                                </div>
                            </SwiperSlide>
                            


                        </Swiper>


                        <div className='slider-btns'>
                            <button className='prev-btn'>
                                <i className='las la-angle-left'></i>
                            </button>
                            <button className='next-btn'>
                                <i className='las la-angle-right'></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurClients;