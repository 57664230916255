import '../css/userloginpages.css';
import ObjectsImage from '../images/devices/objects.png';
import Logo from '../images/logosm.svg';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import EyeIcon from '../images/eye.svg';
import GoogleIcon from '../images/icons/google.svg';
import $ from 'jquery';

function PasswordResetCode() {

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    useEffect(() => {
        $(document).on("click", ".change-to-darkmode", function () {
            $("section, nav, footer, body, div, h1, p, h2").addClass("dark-mode");
            $(this).removeClass("change-to-darkmode");
            $(this).addClass("change-to-lightmode");
        });

        $(document).on("click", ".change-to-lightmode", function () {
            $("section, nav, footer, body, div, h1, p, h2, h3").removeClass("dark-mode");
            $(this).removeClass("change-to-lightmode");
            $(this).addClass("change-to-darkmode");
        });

        if ($("nav .left>a:nth-child(2)").hasClass("change-to-lightmode") || $("body").hasClass("dark-mode")) {
            $("nav .left>a:nth-child(2)").removeClass("change-to-darkmode");
            $("nav .left>a:nth-child(2)").addClass("change-to-lightmode");

            $("section, nav, footer, body, div, h1, p, h2, h3").addClass("dark-mode");
        }
    }, []);

    return (
        <>
            <section className='en user-login-pages'>
                <div className='left'>
                    <img src={ObjectsImage} alt='objects' />
                    <div className='container'>
                        <div className='user-side-page'>
                            <div className='content'>
                                <img src={Logo} alt='logo' />
                                <h2 className='section-sec-headline'>Welcome to the Al-Madar Technical System</h2>
                                <h1 className='section-headline'>Technology Faster Than Light</h1>
                                <div>
                                    <RouterLink target='_blank' to="/en/contact-us">
                                        If you encounter any issues, contact us
                                    </RouterLink>
                                    <RouterLink to="/en/" className='link-btn-midcolor'>
                                        Back to Home
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='right'>
                    <div className='container'>
                        <div className='content'>
                            <div className='form-element'>
                                <div className='header'>
                                    <div>
                                        <h1>Password Reset</h1>
                                        <p>
                                            We have sent a code to your email example@gmail.com. 
                                            Please copy this code and paste it here to verify your account.
                                        </p>
                                    </div>
                                </div>
                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                Verification Code
                                            </label>
                                            <input placeholder='Example: 12345' type="number" required name='confirm-code' />
                                        </div>
                                    </div>
                                    <div className='submit-sec'>
                                        <RouterLink to="/login" className='link-btn-midcolor'>Back</RouterLink>
                                        <button className='link-btn-color'>Confirm</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PasswordResetCode;
