import '../css/temppopup.css';
import axios from '../../config/index';

import { useState, useEffect } from 'react';

function TempPopUp(){

    
    const [landing, setLanding] = useState([]);

    const getLanding = (itemId) => {
        const table = "website";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setLanding(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getLanding(1);
    }, []);


    return(
        <>
            <section className={`temp-pop-up ${landing.show_website == "نعم" ? "" : "hide"}`}>
            <div className='temp-pop-up-content'>
                    <h1> Some updates are being added, please check back soon </h1>
                    <h2>  Thank you </h2>
                </div>
            </section>
        </>
    )
}

export default TempPopUp;