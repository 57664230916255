import './css/prices.css';
import { Link as RouterLink } from 'react-router-dom';


import PriceTag1 from './images/prices/memberships.svg';
import PriceTag2 from './images/prices/memberships-1.svg';
import PriceTag3 from './images/prices/memberships-2.svg';
import PriceTag4 from './images/prices/memberships-3.svg';
import PricesEcp from './images/pricesecp.png';
import $ from 'jquery';

import { useEffect } from 'react';


import axios from '../config/index';
import { useState } from 'react';

function Prices(){

    
    useEffect(() => {

        // Close popup when clicking the close-pop button
        $('.open-pay-ways-popup').on('click', function() {
            openPopUp();
        });

        function openPopUp() {
            $('.pay-ways-pop-up').fadeIn(500); // Adjust the animation duration as needed
        }


        // Cleanup event listeners on component unmount
        return () => {
            $('.open-pay-ways-popup').off('click');
        };
    }, []);



    
    
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);
    const [itemData3, setitemData3] = useState([]);
    const [itemData4, setitemData4] = useState([]);
    const [itemData5, setitemData5] = useState([]);



    const getItemData1 = (itemId) => {
        const table = "prices_packages";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData2 = (itemId) => {
        const table = "prices_packages";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData3 = (itemId) => {
        const table = "prices_packages";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData3(res.data);
            }
        }).catch((err) => console.log(err));
    };


    const getItemData4 = (itemId) => {
        const table = "prices_packages";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData4(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData5 = (itemId) => {
        const table = "prices_packages";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData5(res.data);
            }
        }).catch((err) => console.log(err));
    };





    useEffect(() => {
        getItemData1(1);
        getItemData2(2);
        getItemData3(3);
        getItemData4(4);
        getItemData5(5);

    }, []);





    return(
        <>
            <section className='prices'>
                <img src={PricesEcp} alt='prices eclepse' />
                <div className='container'>
                    <div className='row'>
                        <div className='section-header-ele'>
                            <h1 className='section-headline'> باقات خدمة SMS </h1>
                        </div>
                        <div className='prices-content'>


                            <div className='price'>
                                <div className='top'>
                                    <img src={`./uploads/${itemData1.img_1}`} alt='price tag' />
                                    <h2 className='section-sec-headline'> 
                                        {itemData1.header}
                                    </h2>
                                    <button className='link-btn-midcolor open-pay-ways-popup'>
                                    اشترك الان
                                    </button>
                                </div>

                                <ul>
                                    
                                    <li>
                                    {itemData1.gift}
                                    </li>
                                    <li>
                                        {itemData1.tax == "نعم" ? <i class="las la-check"></i> : <i class="las la-times"></i> }
                                        
                                    </li>
                                    <li>
                                    % {itemData1.free_prcentage}
                                    </li>
                                    <li>
                                        <span> {itemData1.price} </span>
                                        ريال
                                    </li>
                                </ul>
                            </div>




                            <div className='price'>
                                <div className='top'>
                                    <img src={`./uploads/${itemData2.img_1}`} alt='price tag' />
                                    <h2 className='section-sec-headline'> 
                                        {itemData2.header}
                                    </h2>
                                    <button className='link-btn-midcolor open-pay-ways-popup'>
                                    اشترك الان
                                    </button>
                                </div>

                                <ul>
                                    
                                    <li>
                                    {itemData2.gift}
                                    </li>
                                    <li>
                                        {itemData2.tax == "نعم" ? <i class="las la-check"></i> : <i class="las la-times"></i> }
                                        
                                    </li>
                                    <li>
                                    % {itemData2.free_prcentage}
                                    </li>
                                    <li>
                                        <span> {itemData2.price} </span>
                                        ريال
                                    </li>
                                </ul>
                            </div>



                            <div className='price'>
                                <div className='top'>
                                    <img src={`./uploads/${itemData3.img_1}`} alt='price tag' />
                                    <h2 className='section-sec-headline'> 
                                        {itemData3.header}
                                    </h2>
                                    <button className='link-btn-midcolor open-pay-ways-popup'>
                                    اشترك الان
                                    </button>
                                </div>

                                <ul>
                                
                                    <li>
                                    {itemData3.gift}
                                    </li>
                                    <li>
                                        {itemData3.tax == "نعم" ? <i class="las la-check"></i> : <i class="las la-times"></i> }
                                        
                                    </li>
                                    <li>
                                    % {itemData3.free_prcentage}
                                    </li>
                                    <li>
                                        <span> {itemData3.price} </span>
                                        ريال
                                    </li>
                                </ul>
                            </div>


                            <div className='price'>
                                <div className='top'>
                                    <img src={`./uploads/${itemData4.img_1}`} alt='price tag' />
                                    <h2 className='section-sec-headline'> 
                                        {itemData4.header}
                                    </h2>
                                    <button className='link-btn-midcolor open-pay-ways-popup'>
                                    اشترك الان
                                    </button>
                                </div>

                                <ul>
                                    
                                    <li>
                                    {itemData4.gift}
                                    </li>
                                    <li>
                                        {itemData4.tax == "نعم" ? <i class="las la-check"></i> : <i class="las la-times"></i> }
                                        
                                    </li>
                                    <li>
                                    % {itemData4.free_prcentage}
                                    </li>
                                    <li>
                                        <span> {itemData4.price} </span>
                                        ريال
                                    </li>
                                </ul>
                            </div>







                            <div className='price info-header'>
                                <div className='top'>
                                    <h1> قائمة المميزات </h1>
                                </div>

                                <ul>
                                    
                                    <li>
                                    <i class="las la-info-circle"></i>
                                    رسالة هدية
                                    </li>
                                    <li>
                                        <i class="las la-info-circle"></i>
                                        الضريبة
                                    </li>
                                    <li>
                                        <i class="las la-info-circle"></i>
                                        زيادة مجانية عند الشحن
                                    </li>
                                    <li>
                                        <i class="las la-info-circle"></i>
                                        اجمالي الشحن للوصول للعضوية                                    
                                        </li>
                                </ul>
                            </div>




                        </div>

                        <div className='note'>
                            <h3> ملحوظة : الأسعار المقدمة شاملة القيمة المضافة </h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Prices;