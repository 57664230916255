import '../css/rates.css';
import { Link as RouterLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper';
import RateIcon from '../images/icons/rateicon.svg';

import axios from '../../config/index';
import { useEffect,useState } from 'react';

function Rates() {

    const [itemData, setitemData] = useState([]);

    const getItemData = () => {
        const table = "rates";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData();
    }, []);



    const slides = [
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
        {
            content: 'Lorem apsuel lorem apsoul lorem lorem lorem lorem ...',
            clientName: 'name of client',
            clientCompany: 'SEO Company',
        },
    ];
    const middleSlideIndex = Math.floor(slides.length / 2);

    return (
        <>
            <section className='en rates'>
                <div className='container'>
                    <div className='rates-header'>
                        <RouterLink to='/en/about-us' className='link-btn-color'>
                        View all
                        </RouterLink>
                        <h1 className='section-headline'>What they said about us</h1>
                    </div>
                </div>
                <div className='rates-slider'>
                    <Swiper
                        initialSlide={middleSlideIndex}
                        grabCursor={true}
                        spaceBetween={50}
                        slidesPerView={'auto'}
                        centeredSlides={true}
                        navigation={{
                            nextEl: '.rates .next-btn',
                            prevEl: '.rates .prev-btn',
                        }}
                        pagination={true}
                        modules={[Navigation, Pagination]}
                    >
                        {itemData.length > 0 ? (
                                <>
                                    {itemData.map((rate,index) => (
                                        <SwiperSlide key={index}>
                                        <div className='rate'>
                                            <div className='rate-content'>
                                                <img src={RateIcon} alt='rate icon' />
                                                <p>{rate.para_en}</p>
                                            </div>
                                            <div className='client-info'>
                                                <h2>{rate.name_en}</h2>
                                                <h3>{rate.title_en}</h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    ))}
                                </>
                                ) : null}

                    </Swiper>
                    <div className='slider-btns'>
                        <button className='prev-btn'>
                            <i className='las la-angle-left'></i>
                        </button>
                        <button className='next-btn'>
                            <i className='las la-angle-right'></i>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Rates;
