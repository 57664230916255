import './css/explesson.css';
import CheckMarkImg from './images/check.svg';
import $ from 'jquery'; // Import jQuery

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../config/index';

function ExpLesson() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [videoIframe, setVideoIframe] = useState("");
    const [partsInfoData, setPartsInfoData] = useState([]);
    const [course, setCourse] = useState({});

    useEffect(() => {
        const getCourse = async () => {
            try {
                const table = "courses";
                const itemId = id;
                const res = await axios.post('/get-item', { table, itemId });
                convertToEmbed(res.data.youtube);
                processParts(res.data.parts_header, res.data.parts_lessons_num, res.data.parts_lessons_durations, res.data.lessons);
                setCourse(res.data);
            } catch (err) {
                console.log(err);
            }
        };

        getCourse();

      
    }, [id]);

    useEffect(() => {
        $('.explain .header').on('click', function () {
            $(this).find('button').toggleClass('active');
            $(this).find('button i').toggleClass('la-plus la-minus');
            $(this).next('.content').toggleClass('active');

        });
    
        // Cleanup event listeners on unmount
        return () => {
            $('.explain .header').off('click');
        };
    

    }, [id,videoIframe]);
    
    function convertToEmbed(url) {
        const videoId = getYouTubeId(url);
        if (videoId) {
            setVideoIframe(videoId);
        }
    }

    function getYouTubeId(url) {
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\S+)?$/;
        const match = url.match(regExp);
        return match ? match[1] : null;
    }
    
    

    function processParts(parts_headers, parts_lessons_num, parts_lessons_durations, lessons) {
        const partsHeadersArray = parts_headers.split(',');
        const lessonsNumArray = parts_lessons_num.split(',');
        const lessonsDurationsArray = parts_lessons_durations.split(',');
        const lessonsArray = lessons.split(',');

        const partsInfo = partsHeadersArray.map((header, index) => {
            const lessonsSplit = lessonsArray[index].split('-').map(item => item.trim());

            return {
                part_header: header.trim(),
                part_lessons_num: lessonsNumArray[index].trim(),
                part_lessons_durations: lessonsDurationsArray[index].trim(),
                lessons: lessonsSplit
            };
        });

        setPartsInfoData(partsInfo);
    }

    return (
        <section className='exp-lesson'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                        <div className='explain-content'>
                            <h2>{course.header}</h2>

                            {partsInfoData.length > 0 ? partsInfoData.map((part, index) => (
                                <div className='explain' key={index}>
                                    <div className='header'>
                                        <button className='toggle-explain'>
                                            <i className="las la-plus"></i>
                                        </button>
                                        <div>
                                            <h3 className='section-sec-headline'>{part.part_header}</h3>
                                            <p className='section-text'><span>{part.part_lessons_num}</span> <span>{part.part_lessons_durations}</span></p>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <ul>
                                            {part.lessons.map((lesson, lessonIndex) => (
                                                <li key={lessonIndex}>
                                                    <h4>{lesson}</h4>
                                                    <img src={CheckMarkImg} alt='check mark' />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                        <div className='video'>
                            <iframe width="100%" height="550" src={`https://www.youtube.com/embed/${videoIframe}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ExpLesson;
