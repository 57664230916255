import '../css/services.css';
import CheckMark from '../images/check.svg';
import { Link as RouterLink } from 'react-router-dom';

import GooglePlayImg from '../images/googleplay.svg';
import AppleStoreImg from '../images/appstore.svg';


import ServiceImg1 from '../images/services/1.svg';
import ServiceImg2 from '../images/services/2.svg';
import ServiceImg3 from '../images/services/3.svg';
import ServiceImg4 from '../images/services/4.svg';
import ServiceImg5 from '../images/services/5.svg';
import ServiceImg6 from '../images/services/6.svg';
import axios from '../../config/index';
import { useEffect, useState } from 'react';

function Services(){



    

    const [itemData2, setitemData2] = useState([]);




    const getItemData2 = () => {

        const table = "services_page";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }else{
                console.log(res.data)

            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {

        getItemData2();
    }, []);



    return(
        itemData2.length > 0 ? (
            <>
               <section className='en services'>
            <div className='container'>
                <div className='row'>


                {itemData2[5].status == "active" ? 
                    <>
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                            <h1 className='section-headline'> 
                                {itemData2[5].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[5].para_en}

                            </p>

                            <ul>
                                <li>
                                <img src={CheckMark}  alt='check mark'/>
                                <h2 className='section-sec-headline'> 
                                For schools and educational institutions
                                    </h2>
                                </li>

                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    For individuals and companies
                                    </h2>
                                </li>


                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    For programmers and website owners
                                    </h2>
                                </li>

                            </ul>

                            <div>
                                <RouterLink to='/en/all-courses' className='normal-link'>
                                Go to the tutorials to learn how to use it 
                                </RouterLink>
                                
                                <RouterLink className='link-btn-color' to='/en/sms-services' aria-label='show more'>
                                Show more
                                </RouterLink>
                            </div>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                            <img className='section-image' src={`../uploads/${itemData2[5].img_1}`}  alt='service image'/>
                        </div>
                    </div>

                    </>
                :null}
                    {/* Sec */}

                    {itemData2[4].status == "active" ? 
                    <>
                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`../uploads/${itemData2[4].img_1}`}  alt='service image'/>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[4].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[4].para_en}
                            </p>

                            <ul>
                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    Fingerprint devices for students
                                    </h2>
                                </li>

                                <li>
                                    <img src={CheckMark}  alt='check mark'/>
                                    <h2 className='section-sec-headline'> 
                                    Fingerprint devices for teachers
                                    </h2>
                                </li>


                            </ul>

                            <div>
                                <RouterLink to='/en/all-courses' className='normal-link'>
                                Go to the tutorials to learn how to use it 
                                </RouterLink>
                                
                                <RouterLink className='link-btn-color' to='/en/fingerprint-devices' aria-label='show more'>
                                Show more
                                </RouterLink>
                            </div>
                        </div>
                    </div>

                    </>
                :null}
                    {/* Third */}







                    {itemData2[3].status == "active" ? 
                    <>

                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[3].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[3].para_en}
                            </p>



                            <h3> Download the app now  </h3>


                            <div>
                                <a target='_blank' href='https://apps.apple.com/us/app/ttbyq-rsayl-aljwal-almdar/id1031601867?ls=1'  className='apple-store'>
                                    <img src={AppleStoreImg} alt='apple store logo'/>

                                </a>
                                
                                <a target='_blank' href='https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1' className='google-play'>
                                    <img src={GooglePlayImg} alt='goolge play logo'/>
                                    
                                </a>
                            </div>
                        </div>
                    </div>



                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`../uploads/${itemData2[3].img_1}`}  alt='service image'/>
                        </div>
                    </div>


</>
:null}

                    {/* Fourth */}


                    {itemData2[2].status == "active" ? 
                    <>
                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`../uploads/${itemData2[2].img_1}`}  alt='service image'/>
                        </div>
                    </div>





                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[2].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[2].para_en}
                            </p>

                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    As a system administrator
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> 
                                            Username :admin 
                                            </h5>
                                        </li>
                                        <li>
                                            <h5> 
                                            Password : admin123 
                                            </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    As a regular user 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5>Username : user  </h5>
                                        </li>
                                        <li>
                                            <h5> Password : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/en/contact-us'>
                                Contact us to request a copy
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                System trial
                                </a>
                            </div>
                        </div>
                    </div>

                    </>
                :null}

                    {/* Fifth */}




                    {itemData2[1].status == "active" ? 
                    <>

                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[1].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[1].para_en}
                            </p>


                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    As a system administrator:
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> Username :admin  </h5>
                                        </li>
                                        <li>
                                            <h5> Password : admin123  </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    As a regular user: 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> Username : demo  </h5>
                                        </li>
                                        <li>
                                            <h5> Password : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/en/contact-us'>
                                Contact us to request a copy
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                System trial
                                </a>
                            </div>
                        </div>
                    </div>



                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`../uploads/${itemData2[1].img_1}`}  alt='service image'/>
                        </div>
                    </div>

</>
:null}

                    {/* Sixth */}


                    
                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='image'>
                        <img className='section-image' src={`../uploads/${itemData2[0].img_1}`}  alt='service image'/>
                        </div>
                    </div>







                    <div className='col-lg-6 co-md-6 col-sm-12 col-12'>
                        <div className='text'>
                        <h1 className='section-headline'> 
                                {itemData2[0].header_en}
                            </h1>

                            <p className='section-text'>
                            {itemData2[0].para_en}
                            </p>
                            <ul className='main-list'>
                                <li>
                                    <h4>
                                    As a system administrator:
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5>Username :admin  </h5>
                                        </li>
                                        <li>
                                            <h5> Password : admin123  </h5>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <h4>
                                    As a regular user: 
                                    </h4>
                                    <ul className='sub-list'>
                                        <li>
                                            <h5> Username : demo  </h5>
                                        </li>
                                        <li>
                                            <h5> Password : user123456  </h5>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <div>
                                <RouterLink  className='link-btn-color' to='/en/contact-us'>
                                Contact us to request a copy
                                </RouterLink>
                                
                                <a href="https://play.google.com/store/apps/details?id=ot.com.sa.ersaal&hl=ar&pli=1" target='_blank' className='link-btn-midcolor' aria-label='show more'>
                                System trial
                                </a>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </section>
            </>
        ):null
    )
}

export default Services;