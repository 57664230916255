import '../css/howtousetext.css';
import HowToUseImgText1 from '../images/howtouse/1.png';
import HowToUseImgText2 from '../images/howtouse/2.png';

import axios from '../../config/index';
import { useEffect, useState } from 'react';

function HowToUseText(){
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "how_to_use_text";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);


    return(
        <>
            <section className='en how-to-use-text'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img className='section-image' src={`../uploads/${itemData1.img_1}`}  alt='how to use image'/>
                                <img src={HowToUseImgText2}  alt='how to use image'/>

                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'> 
                                {itemData1.header_en}
                                </h1>

                                <p className='section-text'>
                                {itemData1.para_en}
                                </p>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default HowToUseText;