import './css/devicesbenefits.css';
import DevicesBenefitsImg from './images/devicesbenefits.png';



function DevicesBenefits(){

    


    return(
        <>
            <section className='devices-benefits'>
                <div className='container'>
                    <div className='section-header-ele'>
                        <h1 className='section-headline'>
                            مميزات الأجهزة
                        </h1>
                    </div>

                    <div className='image'>
                        <img src={DevicesBenefitsImg} className="section-image" alt='devices benefits'/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DevicesBenefits;