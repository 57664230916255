import './css/allcourses.css';
import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';


import CourseImg1 from './images/coursescategories/1.png';
import CourseImg2 from './images/coursescategories/2.png';
import CourseImg3 from './images/coursescategories/3.png';

import { Link as RouterLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

import React, { useEffect ,useState} from 'react';
import axios from '../config/index';

function AllCourses(){

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    
    const [courses, setCourses] = useState([]);

    const getCourses = () => {
        const table = "courses";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data && id) {
                const courses = res.data;
                const FilterdCourses = courses.filter(course => course.exp_cat_id == id)
                setCourses(FilterdCourses);
            }else{
                setCourses(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getCourses();
    }, [id]);

    return(
        <>
            <section className='all-courses'>
                <div className='container'>
                    <div className='courses-slider'>

                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                    nextEl: '.all-courses .next-btn',
                    prevEl: '.all-courses .prev-btn',
                    }} pagination={true}
                    
                        modules={[Navigation,Pagination]}
                        >

{courses.length > 0 ? (
                                <>
                                    {courses.map((client, index) => {
                                        // Start a new slide for each set of three courses
                                        if (index % 6 === 0) {
                                            return (
                                                <SwiperSlide key={index}>

                                            <div className='row'>

                                                {[0, 1, 2,3,4,5].map((offset) => {
                                                            const currentIndex = index + offset;
                                                            if (courses[currentIndex]) {
                                                                return (
                                                                    <div  key={currentIndex}  className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                                                        <div className='course'>
                                                                            <img className='section-image' src={`./uploads/${courses[currentIndex].img_1}`} alt='course image'/>
                                                                            <div className='text'>
                                                                                <h1>  
                                                                                    {courses[currentIndex].header}
                                                                                </h1>
                                                                                <RouterLink to={`/explain-lessons?id=${courses[currentIndex].id}`}> <i class="las la-angle-left"></i> الذهاب الي الشروحات </RouterLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                );
                                                            }
                                                            return null;
                                        })}

                                    

                                </div>
                                </SwiperSlide>
                            );
                        }
                        return null;
                    })}
                </>
                                ) : null}
                        

                        </Swiper>

                        <div className='slider-btns'>
                            <button className='prev-btn'>
                                <i className='las la-angle-left'></i>
                            </button>
                            <button className='next-btn'>
                                <i className='las la-angle-right'></i>
                            </button>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AllCourses;