import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';
import axios from '../config/index';
import { useEffect, useState } from 'react';


import './css/ourwork.css';
import { Link as RouterLink } from 'react-router-dom';

import Client1 from './images/clients/1.svg';
import Client2 from './images/clients/2.svg';
import Client3 from './images/clients/3.svg';
import Client4 from './images/clients/4.svg';
import Client5 from './images/clients/5.svg';
import Client6 from './images/clients/6.svg';
import Client7 from './images/clients/7.svg';
import Client8 from './images/clients/8.svg';
import Client9 from './images/clients/9.svg';
import Client10 from './images/clients/10.svg';
import Client11 from './images/clients/11.svg';
import Client12 from './images/clients/12.svg';
import Client13 from './images/clients/13.svg';
import Client14 from './images/clients/14.svg';
import Client15 from './images/clients/15.svg';
import Client16 from './images/clients/16.svg';
import Client17 from './images/clients/17.svg';
import Client18 from './images/clients/18.svg';
import Client19 from './images/clients/19.svg';
import Client20 from './images/clients/20.svg';
import Client21 from './images/clients/10.svg';
import Client22 from './images/clients/11.svg';
import Client23 from './images/clients/12.svg';
import Client24 from './images/clients/13.svg';
import Client25 from './images/clients/14.svg';
import Client26 from './images/clients/15.svg';
import Client27 from './images/clients/16.svg';
import Client28 from './images/clients/17.svg';
import Client29 from './images/clients/18.svg';
import Client30 from './images/clients/19.svg';

const clients = [Client1, Client2, Client3, Client4, Client5, Client6, Client7,Client8,Client9,
    Client10,Client11,Client12,Client13,Client14,Client15,Client16,Client17,Client18,Client19,Client20,
    Client21,Client22,Client23,Client24,Client25,Client26,Client27,Client28,Client29,Client30
];



function OurWork(){


    const [itemData2, setitemData2] = useState([]);




    const getItemData2 = (itemId) => {
        const table = "clients";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };


    useEffect(() => {
        getItemData2(2);
    }, []);




    return(
        <>
            <section className='our-work'>
                <div className='container'>
                    <div className='header'>
                        <RouterLink to="/our-clients" className='link-btn-color'>
                            عرض الكل
                        </RouterLink>

                        <h1 className='section-headline'>  أبرز عملائنا  </h1>
                    </div>

                    <div className='slider'>
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                    nextEl: '.our-work .next-btn',
                    prevEl: '.our-work .prev-btn',
                    }} pagination={true}
                    
                        modules={[Navigation,Pagination]}
                        >

                        {itemData2.length > 0 ? (
                                <>
                                    {itemData2.map((client, index) => {
                                        // Start a new slide for each set of three itemData2
                                        if (index % 30 === 0) {
                                            return (
                                                <SwiperSlide key={index}>

                                                <div className='clients'>

                                                {[0, 1, 2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29].map((offset) => {
                                                            const currentIndex = index + offset;
                                                            if (itemData2[currentIndex]) {
                                                                return (


                                                                    <img key={currentIndex} src={`./uploads/${itemData2[currentIndex].img_1}`} alt='client image' />

                                                                );
                                                            }
                                                            return null;
                                        })}

                                    

                                </div>
                                </SwiperSlide>
                            );
                        }
                        return null;
                    })}
                </>
                                ) : null}
                                
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurWork;