import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllUsersPage(){


        
    useEffect(() => {
        axios
    .get('admin-session')
    .then((res) => {
        if (res.data.validadmin) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [users, setAllUsers] = useState([]);

    axios.defaults.withCredentials = true;


    const GetAllUsers = ()=>{
        axios.post('AllUsers', {
            table: 'users',
        })
        .then((res) => {
            if (res.data) {
                setAllUsers(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'users',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
            GetAllUsers();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };
    

  
    const ActivateUser = (id) => {
        axios.put(`activate-user/${id}`, { data: { table: 'users' } })
            .then((response) => {
                if (response.data.message === 'Item activated successfully') {
                    GetAllUsers();
                } else {
                    console.error('Failed to activate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error activating result:', error);
            });
    };

    const DiActivateUser = (id) => {
        axios.put(`diactivate-user/${id}`, { data: { table: 'users' } })
            .then((response) => {
                if (response.data.message === 'Item diactivated successfully') {
                    GetAllUsers();
                } else {
                    console.error('Failed to diactivate result:', response.data.message);
                }
            })
            .catch((error) => {
                console.error('Error diactivating result:', error);
            });
    };


useEffect(() => {

    GetAllUsers();
    

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-user'> أضف مستخدم جديد </Link>
                <table>

                    <thead>
                        <tr>
                            <th>#</th>
                            <th> الصورة </th>
                            <th> الأسم </th>
                            <th> البريد الإلكتروني </th>
                            <th> النوع </th>
                            <th> الإختيارات </th>

                        </tr>
                    </thead>
                    <tbody>
                    {users.length ? (
                    users.map((user) => {
                        return (
                        <tr key={user.user_id}>
                            
                            <td>
                            <h6>{user.user_id}</h6>
                            </td>

                            <td><img src={`./uploads/${user.profile_img}`} alt="item Image"/></td>

                            <td>
                            <h6>{user.fullname}</h6>
                            </td>

                            <td>
                            <h6>{user.email}</h6>
                            </td>

                            <td>
                            <h6>{user.type}</h6>
                            </td>


                            <td><Link className='edit' to={`/edit-user?id=${user.user_id}`}> <i class="las la-edit"></i> </Link></td>

                            <td>
                                <button className='delete' onClick={() => deleteItem(user.user_id)}>
                                    <i className="las la-trash-alt"></i>
                                </button>
                                {user.confirmed == "0" ? (
                                    <button className='activate' onClick={() => ActivateUser(user.user_id)}>
                                        التنشيط
                                    </button>
                                ) : (
                                    <button className='diactivate' onClick={() => DiActivateUser(user.user_id)}>
                                        إلغاء التنشيط
                                    </button>
                                )}
                            </td>
                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>تحميل...</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllUsersPage;