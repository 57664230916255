import React, { useState, useEffect } from 'react';
import '../css/plans.css';
import { Link as RouterLink } from 'react-router-dom';

import PlanImg1 from '../images/plans/1.svg';
import PlanImg2 from '../images/plans/2.svg';
import PlanImg3 from '../images/plans/3.svg';
import PlanImg4 from '../images/plans/4.svg';

import axios from '../../config/index';

function Plans() {
    const [activeTab, setActiveTab] = useState('sms'); // 'sms' or 'fingerprint'

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [itemData, setItemData] = useState([]);

    const getItemData = () => {
        const table = "prices_sms";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setItemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData();
    }, []);

    const [itemData2, setItemData2] = useState([]);

    const getItemData2 = () => {
        const table = "prices_devices";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setItemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData2();
    }, []);

    return (
        <>
            <section className='en plans'>
                <div className='container'>
                    <div className='plans-header'>
                        <div>
                            <button
                                className={activeTab === 'fingerprint' ? 'link-btn-color' : 'link-btn-midcolor'}
                                onClick={() => handleTabClick('fingerprint')}
                            >
                                Fingerprint Devices
                            </button>
                            <button
                                className={activeTab === 'sms' ? 'link-btn-color' : 'link-btn-midcolor'}
                                onClick={() => handleTabClick('sms')}
                            >
                                SMS Service
                            </button>
                        </div>
                        <h1 className={activeTab === 'sms' ? 'section-headline' : 'hide section-headline'}>
                            SMS Service Memberships
                        </h1>
                        <h1 className={activeTab === 'fingerprint' ? 'section-headline' : 'hide section-headline'}>
                            Fingerprint Device Packages
                        </h1>
                    </div>

                    <div className={activeTab === 'sms' ? 'all-plans' : 'hide all-plans'}>
                        {itemData.length > 0 ? (
                            <>
                                {itemData.map((plan) => (
                                    <div className='plan' key={plan.id}>
                                        <img src={`../uploads/${plan.img_1}`} alt={`Image of ${plan.header_en}`} />
                                        <h2>{plan.header_en}</h2>
                                        <h1><span>Message</span> {plan.number_en}</h1>
                                        <p>{plan.para_en}</p>
                                    </div>
                                ))}
                            </>
                        ) : null}
                    </div>

                    <div className={`finger-print ${activeTab === 'fingerprint' ? '' : 'hide'}`}>
                        <div className='row'>
                            {itemData2.length > 0 ? (
                                <>
                                    {itemData2.map((plan) => (
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12' key={plan.id}>
                                            <div className='plan'>
                                                <div className='top'>
                                                    <img src={`../uploads/${plan.img_1}`} alt='plan image' />
                                                    <h1> {plan.header_en} <span> Package </span> </h1>
                                                    <h2> <span> / Per Semester </span> {plan.price_season} SAR </h2>
                                                    <h2> <span> / Per Year </span> {plan.price_year} SAR </h2>
                                                    <h3> {plan.devices_num} </h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
                <RouterLink to="/en/prices-and-banks" className='link-btn-color'> View All Prices </RouterLink>
            </section>
        </>
    );
}

export default Plans;
