import './css/paywayspopup.css';
import React, { useEffect } from 'react';
import './css/popup.css';
import $ from 'jquery';

import downLayImg from './images/popup/downlayimage.png';
import SaudiArabiaFlag from './images/popup/flag.png';

import { Link as RouterLink } from 'react-router-dom';

import PayWayImg1 from './images/popup/2.gif';
import PayWayImg2 from './images/popup/1.gif';


function PayWaysPopup() {
    useEffect(() => {
        // Close popup when clicking outside of pop-content
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.pop-content').length && !$(event.target).closest('.open-pay-ways-popup').length) {
                closePopup();
            }
        });

        // Close popup when clicking the close-pop button
        $('.close-pop').on('click', function() {
            closePopup();
        });

        // Copy text functionality
        $('.copy-next-text').on('click', function() {
            const textToCopy = $(this).siblings('span').text();
            navigator.clipboard.writeText(textToCopy).then(() => {
                alert('Text copied to clipboard!');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        });

        // Close popup function with animation
        function closePopup() {
            $('.pay-ways-pop-up').fadeOut(500); // Adjust the animation duration as needed
        }

        // Cleanup event listeners on component unmount
        return () => {
            $(document).off('click');
            $('.close-pop').off('click');
            $('.copy-next-text').off('click');
        };
    }, []);

    return (
        <>
            <section className='pay-ways-pop-up'>
                <div className='pop-content'>
                    <div className='header'>
                        <button className='close-pop'> 
                            <i className="las la-times"></i>
                        </button>
                        <div>
                        <h1 className='section-headline'> شراء عضوية</h1>
                        <p>
                        اختر احد الطرق التالية للاشتراك في العضوية، 
                        </p>
                        <p>
                        إما عن طريق الدفع مباشرة إلى حساباتنا البنكية او تتواصل معنا بشكل مباشر
                        </p>
                        </div>
                    </div>

                

                <div className='pay-ways'>
                    <div className='way'>
                        <img src={PayWayImg1}  alt='pay way image'/>
                        <h2> 
                        الدعم الفني
                        </h2>
                        <p>
                        لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                        </p>

                        <RouterLink to="/contact-us" className='link-btn-color' > تواصل معنا </RouterLink>
                    </div>

                    <div className='way'>
                        <img src={PayWayImg2}  alt='pay way image'/>
                        <h2> 
                        الحسابات البنكية
                        </h2>
                        <p>
                            لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ...
                        </p>

                        <button  className='open-bank-popup link-btn-color'>عرض الحسابات البنكية</button>
                    </div>



                </div>
                </div>
            </section>
        </>
    );
}

export default PayWaysPopup;
