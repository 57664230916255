import './css/teammembers.css';

import {Swiper,SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Autoplay,Navigation,Pagination} from 'swiper';

import Mem1 from './images/members/1.png';
import Mem2 from './images/members/2.png';
import Mem3 from './images/members/3.png';
import Mem4 from './images/members/4.png';

import axios from '../config/index';
import { useEffect, useState } from 'react';

function TeamMembers(){

    const [teams, setTeams] = useState([]);

    const getTeams = (itemId) => {
        const table = "team";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setTeams(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getTeams();
    }, []);




    return(
        <>
            <section className='team-members'>
                <div className='container'>
                    <div className='header'>
                        <h1 className='section-headline'>فريق العمل  </h1>
                    </div>


                    <div className='team-slider'>
                    <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                    nextEl: '.team-members .next-btn',
                    prevEl: '.team-members .prev-btn',
                    }} pagination={true}
                    
                        modules={[Navigation,Pagination]}
                        >


                            {teams.length > 0 ? (
                                <>
                                    {teams.map((team, index) => {
                                        // Start a new slide for each set of three teams
                                        if (index % 4 === 0) {
                                            return (
                                                <SwiperSlide key={index}>

                                                <div className='members'>

                                                {[0, 1, 2,3].map((offset) => {
                                                            const currentIndex = index + offset;
                                                            if (teams[currentIndex]) {
                                                                return (

                                                                    <div key={currentIndex} className='mem'>
                                                                    <img src={`./uploads/${teams[currentIndex].img_1}`} alt='team member image' />
                            
                                                                    <div>
                                                                        <h3> {teams[currentIndex].name} </h3>
                                                                        <h4>  {teams[currentIndex].title}</h4>
                                                                    </div>
                                                                </div>

                                                                );
                                                            }
                                                            return null;
                                        })}

                                    

                                </div>
                                </SwiperSlide>
                            );
                        }
                        return null;
                    })}
                </>
                                ) : null}

                            
                        </Swiper>

                        <div className='slider-btns'>
                            <button className='prev-btn'>
                                <i className='las la-angle-left'></i>
                            </button>
                            <button className='next-btn'>
                                <i className='las la-angle-right'></i>
                            </button>
                        </div>


                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamMembers;