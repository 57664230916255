import './css/faqspage.css';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SearchIcon from './images/icons/search.svg';
import $ from 'jquery'; // Import jQuery
import axios from '../config/index';

function FaqsPage() {
    const [itemData1, setitemData1] = useState([]);
    const [itemData2, setitemData2] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const getItemData1 = () => {
        const table = "questions";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
                console.log(res.data);

            } else {
                console.log(res.data);
            }
        }).catch((err) => console.log(err));
    };

    const getItemData2 = () => {
        const table = "faqs_page_categories";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            } else {
                console.log(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData1();
        getItemData2();
    }, []);

    useEffect(() => {
        // jQuery script to handle toggle functionality for .question .header
        $(document).on('click', '.question .header', function () {
            $(this).find('button').toggleClass('active');
            $(this).find('button').find('i').toggleClass('la-plus la-minus');
            $(this).next('.content').toggleClass('active');
        });

        // Cleanup event listeners on unmount
        return () => {
            $(document).off('click', '.question .header');
        };
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredQuestions = itemData1.filter(question =>
        question.header && question.header.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const groupedQuestions = itemData2.map(category => {
        
        return {
            ...category,
            questions: filteredQuestions.filter(question => question.cat_id === category.id)
        };
    });

    return (
        <>
            <section className='faqs-page'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                            <div className='page-side-nav'>
                                <ul>
                                    <li>
                                        <RouterLink className='active' to="/faqs">
                                            الأسئلة الشائعة
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/policy-privacy">
                                            السياسات والخصوصية
                                        </RouterLink>
                                    </li>
                                    <li>
                                        <RouterLink to="/terms-conditions">
                                            الأحكام والشروط
                                        </RouterLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                            <div className='search'>
                                <button type='button' className='link-btn-color'>
                                    بحث
                                </button>
                                <input
                                    type='text'
                                    placeholder='بحث ... '
                                    required
                                    name='searchtext'
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <img src={SearchIcon} alt='search icon' />
                            </div>
                            {groupedQuestions.slice().reverse().map(category => (
                                <div className='faqs' key={category.id}>
                                    <div className='col-12'>
                                        <div className='header'>
                                            <h1 className='section-headline'>{category.header}</h1>
                                        </div>
                                    </div>
                                    {category.questions.map(question => (
                                        <div className='col-12' key={question.id}>
                                            <div className='question'>
                                                <div className='header'>
                                                    <button className='slide-toggle'>
                                                        <i className="las la-plus"></i>
                                                    </button>
                                                    <h2 className='section-sec-headline'>{question.header}</h2>
                                                </div>
                                                <div className='content'>
                                                    <p className='section-text'>{question.para}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqsPage;
