import './css/ourteam.css';
import TeamImg from './images/team.png';
import AtomImg from './images/atom.svg';

import { Link as RouterLink } from 'react-router-dom';

function Team(){
    return(
        <>
        <section className='our-team'>
            <div className='container'>
                <div className='our-team-content'>
                    <img src={TeamImg} alt='team image'/>

                    <div className='text'>
                        <h2 className='section-sec-headline'> 
                        فريقنا المتميز
                        </h2>
                        <p className='section-text'>
                        لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور 
                        </p>

                        <RouterLink to='' className='link-btn-midcolor'>
                        تواصل معنا
                        </RouterLink>
                    </div>
                    
                    <img src={AtomImg} alt='atom image' />
                </div>
            </div>
        </section>
    </>
    )
}

export default Team;