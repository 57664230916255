import '../css/fingerprintprices.css';
import { Link as RouterLink } from 'react-router-dom';

import PlanImg1 from '../images/prices/memberships-1.svg';
import PlanImg2 from '../images/prices/memberships-2.svg';
import PlanImg3 from '../images/prices/memberships-3.svg';

import axios from '../../config/index';
import { useEffect,useState } from 'react';



function FingerPrintPrices(){

        
    const [itemData2, setitemData2] = useState([]);

    const getItemData2 = () => {
        const table = "prices_devices";
        axios.post('/AllItems', { table }).then((res) => {
            if (res.data) {
                setitemData2(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData2();
    }, []);



    return(
        <>
            <section className='en finger-print-prices'>

                <div className='container'>
                    <div className='header'>
                        <RouterLink to='/en/fingerprint-devices' className='link-btn-color'>
                        Show More
                        </RouterLink>
                        <div>
                            <h1 className='section-headline'>
                            Fingerprint Device Offers
                            </h1>
                            <p className='section-text'> 
                            To learn more about the devices, their features, and who uses them, go to the Fingerprint Devices page from Show More
                            </p>
                        </div>
                    </div>


                    <div className='row'>

                    {itemData2.length > 0 ? (
                            <>
                                {itemData2.map((plan) => (
                                    <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                        <div className='plan'>
                                            <div className='top'>
                                                <img src={`../uploads/${plan.img_1}`} alt='plan image' />
                                                <h1> {plan.header_en} <span> Package </span> </h1>
                                                <h2> <span> / For the semester </span> {plan.price_season} Riyal </h2>
                                                <h2> <span> / For the year </span> {plan.price_year} Riyal </h2>
                                                <h3> {plan.devices_num_en} </h3>
                                            </div>
                                            
                                        </div>
                                    </div>
                                ))}
                            </>
                    ) : null}



                    </div>
                </div>
            </section>
        </>
    )
}

export default FingerPrintPrices;