import './css/fingerprintdevtext.css';
import { Link as RouterLink } from 'react-router-dom';
import SecImg from './images/Union.png';
import Dots from './images/dots.svg';


import axios from '../config/index';
import { useEffect, useState } from 'react';



function FingerPrintDevText(){

    
    const [itemData1, setitemData1] = useState([]);

    const getItemData1 = (itemId) => {
        const table = "finger_print_text";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData1(res.data);
            }
        }).catch((err) => console.log(err));
    };



    useEffect(() => {
        getItemData1(1);
    }, []);



    return(
        <>
            <section className='finger-print-dev-text'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'>  
                                {itemData1.header}
                                </h1>
                                <p className='section-text'> 
                                    {itemData1.para}
                                </p>


                                <RouterLink to="/prices-and-banks" className='link-btn-color'>
                                    صفحة الاسعار
                                </RouterLink>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='image'>
                                <img src={`./uploads/${itemData1.img_1}`} className='section-image' alt="finger print image"/>
                                <img src={Dots}  alt="dots image"/>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default FingerPrintDevText;