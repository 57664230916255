import './css/userloginpages.css';
import ObjectsImage from './images/devices/objects.png';
import Logo from './images/logosm.svg';

import { Router, Link as RouterLink } from 'react-router-dom';

import EyeIcon from './images/eye.svg';

import GoogleIcon from './images/icons/google.svg';



import React, { useState,useEffect,useRef   } from 'react';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input/input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en.json';
import $ from 'jquery';



function Signup(){

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const togglePassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };





    //*-*-*-*-*-*

    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSelectedCountry({
                "value": "SA",
                "label": "Saudi Arabia (+966)",
                "countryCode": "SA"
            })
        }, 1000);

        return () => clearTimeout(timer);
        }, []); 

        console.log(selectedCountry)
    

    const [phoneNumber, setPhoneNumber] = useState('');

    const countries = getCountries().map((countryCode) => ({
        value: countryCode,
        label: `${en[countryCode]} (+${getCountryCallingCode(countryCode)})`,
        countryCode: countryCode,
    }));

    const customSingleValue = ({ data }) => (
        <div className="custom-single-value">
        <ReactCountryFlag countryCode={data.countryCode} svg style={{ marginRight: 10 }} />
        {data.label}
        </div>
    );

    useEffect(() => {
        $(document).on("click", ".change-to-darkmode", function() {
            $("section, nav, footer,body,div,h1,p,h2").addClass("dark-mode");
            $(this).removeClass("change-to-darkmode");
            $(this).addClass("change-to-lightmode");
        });

        $(document).on("click", ".change-to-lightmode", function() {
            $("section, nav, footer,body,div,h1,p,h2,h3").removeClass("dark-mode");
            $(this).removeClass("change-to-lightmode");
            $(this).addClass("change-to-darkmode");
        });
        if($("nav .left>a:nth-child(2)").hasClass("change-to-lightmode") || $("body").hasClass("dark-mode")){
            $("nav .left>a:nth-child(2)").removeClass("change-to-darkmode");
            $("nav .left>a:nth-child(2)").addClass("change-to-lightmode");

            $("section, nav, footer,body,div,h1,p,h2,h3").addClass("dark-mode");
        }
    }, []);




    return(
        <>
            <section className='user-login-pages'>
                <div className='left'>
                    <img src={ObjectsImage} alt='objects'/>
                    <div className='container'>
                        <div className='user-side-page'>
                            <div className='content'>
                                <img src={Logo} alt='logo'/>
                                <h2 className='section-sec-headline'> مرحباً بكم في نظام المــــدار التقني  </h2>

                                <h1 className='section-headline'>  التقنية أسرع من الضوء </h1>


                                 <div>
                                    <RouterLink target='_blank' to="/contact-us">
                                    إذا واجهت اي مشكلة تواصل معنا
                                    </RouterLink>

                                    <RouterLink to="/" className='link-btn-midcolor'>
                                    الرجوع إلى الرئيسية
                                    </RouterLink>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='right'>
                    <div className='container'>
                        <div className='content'>
                            <div className='form-element'>
                                <div className='header'>
                                    <RouterLink to="/login"> تسجيل دخول؟ </RouterLink>
                                    <div>
                                        <h1> إنشاء حساب جديد </h1>
                                        <p>
                                        اضف معلوماتك بشكل صحيح من فضلك
                                        </p>
                                    </div>
                                </div>

                                <div className='login-google'>
                                    <button className='login-by-google'> دخول بإستخدام جوجل <img src={GoogleIcon} alt='google icon' /> </button>
                                </div>

                                <div className='or'>
                                    <span> </span>
                                    <h4> أو  </h4>
                                    <span> </span>
                                </div>


                                <form>
                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                                الاسم الثاني
                                            </label>

                                            <input type='text' placeholder='  أيمن ' required name='sec-name' />
                                        </div>

                                        <div className='input'>
                                            <label>
                                                الاسم الاول
                                            </label>

                                            <input type='text' placeholder='  إسلا م ' required name='first-name' />
                                        </div>

                                    </div>



                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                            البريد الالكتروني
                                            </label>

                                        
                                            <input placeholder='example@gmail.com' type="email" required name='email' />
                                        </div>
                                    </div>


                                    <div className='inputs'>
                                <div className='input'>
                                    <label>
                                        رقم الجوال
                                    </label>
                                    <div className='splitter'>
                                            <Select
                                            
                                            value={selectedCountry}
                                            onChange={setSelectedCountry}
                                            options={countries}
                                            getOptionLabel={(option) => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ReactCountryFlag countryCode={option.countryCode} svg style={{ marginRight: 10 }} />
                                                {option.label}
                                                </div>
                                            )}
                                            getOptionValue={(option) => option.value}
                                            styles={{ container: (provided) => ({ ...provided, flex: 1 }) }}
                                            components={{ SingleValue: customSingleValue }}
                                            />
                                            <PhoneInput
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                            country={selectedCountry ? selectedCountry.value : undefined}
                                            placeholder='.... .... ...'
                                            required
                                            name='phonenumber'
                                            style={{ flex: 2, marginLeft: '10px' }}
                                            />
                                    </div>
                                </div>
                            </div>


                                    <div className='inputs'>
                                        <div className='input'>
                                            <label>
                                            ادخل كلمة المرور
                                            </label>

                                            <button type='button' className='show-password' onClick={togglePassword2}>
                                                <img src={EyeIcon} alt='eye icon' />
                                            </button>
                                            
                                            <input placeholder='gdfa65HDVu??**dvsw' type={showPassword2 ? 'text' : 'password'} required name='password' />
                                        </div>
                                    </div>


                                    <button className='link-btn-color'>    تسجيل  </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Signup;