import '../css/sectionheader.css';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '../images/icons/home.svg';
import landingImg from '../images/landing.png';
import landingNetImg from '../images/landingnet.png';
import AboutImg from '../images/pageheader/about.png';

function SectionHeader(props){
    return(
        <>
            <section className='en section-header'>
            <img src={landingImg} alt='background image' />
            <img src={landingNetImg} alt='background image' />

                <div className='container'>
                    <div className='section-header-content'>
                
                        {       
                            props.img ? (
                                <>
                                    <div className={`left ${props.className}`}>
                                    <img src={props.img} alt='background image' />

                                    </div>
                                </>
                            ) : (
                                <>
                                <div className='left no-image'>

                                    </div>
                                    </>
                            )
                        }
                    


                        <div className='right'>
                            <ul>
                                <li>
                                    <RouterLink className='active' to="/en/">
                                        <img src={HomeIcon} alt='home icon' />
                                        Home
                                    </RouterLink>
                                </li>

                                <li>
                                    <span>
                                        <i class="las la-angle-right"></i>
                                    </span>
                                </li>

                                <li>
                                    <RouterLink to={`/${props.link1}`}>
                                        {props.link1text}
                                    </RouterLink>
                                </li>

                                {
                                    props.link2 ? (
                                        <>

                                        <li>
                                            <span>
                                                <i class="las la-angle-right"></i>
                                            </span>
                                        </li>

                                        <RouterLink to={`/${props.link2}`}>
                                            {props.link2text}
                                        </RouterLink>
                                        </>
                                    ) : null
                                    

                                }


                            </ul>

                            <h1> 
                                {props.header}
                            </h1>

                            <p className='section-text'>

                            {props.para}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionHeader;