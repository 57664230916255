import './css/notfound.css';


import ObjectsImage from './images/devices/objects.png';
import NotFoundImage from './images/404.png';
import { Link as RouterLink } from 'react-router-dom';

import landingImg from './images/landing.png';


function NotFound(){
    return(
        <>
            <section className='not-found'>
                <img src={ObjectsImage} alt='objects'/>
                <img src={landingImg} alt='ecp'/>
                
                <div className='container'>
                    <div className='content'>
                        <h1> اووبس هناك خطأ </h1>
                        <p>
                        يبدو ان النتائج البحث لم تعثر على تبحث عنه
                        </p>

                        <img src={NotFoundImage} alt='not found image' />

                        <RouterLink to='/' className='link-btn-color'>
                        الذهاب الى الرئيسية
                        </RouterLink>

                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound;