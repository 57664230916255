import React, { useEffect } from 'react';
import './css/about.css';
import AboutVideo from './videos/about.mp4';
import Dots from './images/dots.svg';
import PlayIcon from './images/icons/Play.svg';
import { Link as RouterLink } from 'react-router-dom';
import $ from 'jquery';

import axios from '../config/index';
import { useState } from 'react';

function About() {
    useEffect(() => {
        // Play video on play button click
        $('.about .play-btn').hide();
        $(document).on('click', '.play-btn', function(event) {
            const video = $(this).closest('.video').find('video')[0];
            $(this).hide();
            video.play();
        });

        // Stop video on video click
        $(document).on('click', '.video video', function() {
            const video = this;
            $(video).closest('.video').find('.play-btn').show();
            video.pause();
        });

        // Stop video when clicking outside of video element
        $(document).on('click', function(event) {
            if (!$(event.target).closest('.video video, .play-btn').length) {
                $('.video video').each(function() {
                    const video = this;
                    if (!video.paused) {
                        $(video).closest('.video').find('.play-btn').show();
                        video.pause();
                    }
                });
            }
        });

        // Cleanup event listeners on component unmount
        return () => {
            $(document).off('click', '.play-btn');
            $(document).off('click', '.video video');
            $(document).off('click');
        };
    }, []);



    
    const [itemData, setitemData] = useState([]);

    const getItemData = (itemId) => {
        const table = "about";
        axios.post('/get-item', { itemId, table }).then((res) => {
            if (res.data) {
                setitemData(res.data);
            }
        }).catch((err) => console.log(err));
    };

    useEffect(() => {
        getItemData(1);
    }, []);



    return (
        <>
            <section className='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='video'>
                                <video autoPlay playsInline src={`./uploads/${itemData.video}`} muted ></video>
                                <img src={Dots} alt='dots'/>
                                <div className='play-btn'>
                                    <button className='play-video'>
                                        <img src={PlayIcon} alt='play icon' />
                                    </button>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                            <div className='text'>
                                <h1 className='section-headline'> 
                                {itemData.header}
                                </h1>
                                <p className='section-text'>
                                {itemData.para}
                                </p>
                                <div className='read-more'>
                                    <div>
                                        <h2 className='section-sec-headline'>  {itemData.message}  </h2>
                                        <p className='section-text'>
                                        {itemData.message_para}
                                        </p>
                                    </div>
                                    <div>
                                        <h2 className='section-sec-headline'>     {itemData.vision}
                                        </h2>
                                        <p className='section-text'>
                                        {itemData.vision_para}
                                        </p>
                                    </div>
                                </div>
                                <RouterLink to="/about-us" className='link-btn-color'> عرض المزيد </RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default About;
